// import React, { useState, useEffect } from 'react';
// import CloseIcon from '@mui/icons-material/Close';
// import '../style.css';
// import { calculateTimeAgo } from '../Utils/timeutils';
// import PostComment from './postcomment';
// import SubmitComment from './submitcomment';
// import PostBottomSection from './postbottomsection';
// import PostModalImage from './postmodalimage';
// import { PROFILEPHOTO_URL } from '../../../../../Config/config';

// const PostModal = ({ post, onClose }) => {
//     const [formattedTimeAgo, setFormattedTimeAgo] = useState('');

//     useEffect(() => {
//         if (post && post.createdAt) {
//             const formattedTime = calculateTimeAgo(post.createdAt);
//             setFormattedTimeAgo(formattedTime);
//         }
//     }, [post]);

//     useEffect(() => {
//         document.body.classList.add('no-scroll');
//         return () => {
//             document.body.classList.remove('no-scroll');
//         };
//     }, []);

//     return (
//         <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
//             <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
//             <CloseIcon className="absolute top-0 right-4 text-white csuror-pointer" onClick={onClose} />
//             <div className="relative z-10 flex">
//                 <PostModalImage post={post} />
//                 <div className="flex bg-black/90 2xl:w-[450px] xl:w-[450px] lg:w-[400px] md:w-[380px] flex-col mr-0 rounded-tr-lg rounded-br-lg">
//                     <div className="flex items-center">
//                         <div className="w-10 h-10 bg-white rounded-full overflow-hidden ml-4 mt-4">
//                             <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                         </div>
//                         <div>
//                             <span className="text-white k blocml-3 text-md mt-3">{post.user.firstName} {post.user.lastName}</span>
//                             <span className="block text-gray-300 ml-3 truncate text-xs">
//                                 {post.location.length > 20 ? `${post.location.slice(0, 20)}...` : post.location}
//                             </span>
//                         </div>
                        
//                         {post.user.showFollowBtn && (
//                             <div className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1 mb-2 mt-4 ml-12">Follow</div>
//                         )}
//                     </div>
//                     <div className="border-b border-white/50 w-full mt-2"></div>
//                     <div className="h-[450px]">
                       
//                         <PostComment post={post} />
//                     </div>
//                     <div className="border-b border-white/50 w-full h-2 mt-[54px] z-10"></div>
//                     <PostBottomSection post={post} />
//                     <div>
//                         {/* <div className="border-b border-white/30 w-full mt-2"></div> */}
//                         {/* <SubmitComment /> */}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PostModal;


import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import '../style.css';
import { calculateTimeAgo } from '../Utils/timeutils';
import PostComment from './postcomment';
import SubmitComment from './submitcomment';
import PostBottomSection from './postbottomsection';
import PostModalImage from './postmodalimage';
import { API_URL, PROFILEPHOTO_URL } from '../../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";


const PostModal = ({ postId, onClose, postIds, incrementCommentCount ,decrementCommentCount }) => {
    const [loading, setLoading] = useState(true); 
    const [post, setPost] = useState(null);
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const actualPostId = postId || localStorage.getItem('postId');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No access token found');
                    return;
                }

                const config = {
                    method: 'get',
                    url: `${API_URL}/posts/v3/details/${actualPostId}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await axios.request(config);

                const postData = Array.isArray(response.data.data) ? response.data.data[0] : response.data.data;
                setPost(postData);
                setLoading(false); 
            } catch (error) {
                console.error("Error fetching post details:", error);
                setLoading(false); 
            }
        };

        if (actualPostId) {
            fetchPostDetails();
        } else {
            console.error('No postId found');
            setLoading(false); 
        }
    }, [actualPostId]);

    useEffect(() => {
        if (post && post.createdAt) {
            const formattedTime = calculateTimeAgo(post.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [post]);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    if (loading) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white"><CircularProgress /></div>
            </div>
        );
    }

    if (!post) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white">Error fetching post details.</div>
            </div>
        );
    }

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
      };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
            <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
            <CloseIcon className="absolute top-0 right-4 text-white cursor-pointer" onClick={onClose} />
            <div className="relative z-10 flex">
                {post.contents?.length > 0 && <PostModalImage post={post} />}
                <div className="flex bg-black/90 2xl:w-[450px] xl:w-[450px] lg:w-[400px] md:w-[380px] flex-col mr-0 rounded-tr-lg rounded-br-lg">
                    <div className="flex items-center">
                        {post.user.profilePhoto && (
                            <div className="w-10 h-10 bg-white rounded-full overflow-hidden ml-4 mt-4 cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                                <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
                            </div>
                        )}
                        <div>
                            <span className="text-white block ml-3 text-md mt-3 cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                                {post.user.firstName} {post.user.lastName}
                                </span>
                            <span className="block text-gray-300 ml-3 truncate text-xs">
                                {post.location.length > 20 ? `${post.location.slice(0, 20)}...` : post.location}
                            </span>
                        </div>
                        
                        {/* {post.user.showFollowBtn && (
                            <div className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1 mb-2 mt-4 ml-12">Follow</div>
                        )} */}
                    </div>
                    <div className="border-b border-white/50 w-full mt-2"></div>
                    <div className="h-[450px]">
                        <PostComment post={post}  postIds={postIds} incrementCommentCount={incrementCommentCount} decrementCommentCount={decrementCommentCount} />
                    </div>
                    <div className="border-b border-white/50 w-full h-2 mt-[54px] z-10"></div>
                    <PostBottomSection post={post} />
                    <div>
                        {/* <SubmitComment /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostModal;
