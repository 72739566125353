import React, { useState } from "react";
import Sort from "./Sort";



const SubHeader = () => {
  

  return (
    <section className="flex flex-col mt-40">
        <Sort />
    </section>
       
      
  );
};

export default SubHeader;
