import React, { useState, useEffect } from 'react';
import '../style.css';
import { calculateTimeAgo } from '../Utils/timeutils';
import { PROFILEPHOTO_URL } from '../../../../../Config/config';
import { useNavigate } from "react-router-dom";

const PostCaption = ({ post }) => {
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (post && post.createdAt) {
            const formattedTime = calculateTimeAgo(post.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [post]);

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
      };

    return (
        
                        <div className="relative ">
                            <div className="flex items-start">
                                <div className="w-10 h-10 bg-white rounded-full overflow-hidden ml-4 mt-4 flex-shrink-0 cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                                    <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
                                </div>
                          <div className="flex flex-col justify-start mx-3 mt-4">
                           <span className=" text-white "  >
                              <span className=" cursor-pointer"  onClick={() => handleProfile(post.user.userId)}>
                                {post.user.firstName} {post.user.lastName}
                              </span>
                              <span className="  mx-3  text-md font-light text-white/90 break-words">
                               {post.caption}
                              </span>
                            </span>
                                    <div className="text-gray-400 text-xs ml-0 flex">
                                        {formattedTimeAgo}
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        
    );
};

export default PostCaption;
