import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { API_URL, PROFILEPHOTO_URL } from '../../../../../Config/config';
import Vids from './vids';
import VidsBottomsection from './vidsbottomsection';
import VideoComment from './vidscomment';
import CircularProgress from '@mui/material/CircularProgress';
import { calculateTimeAgo } from '../Utils/timeutils';
import { useNavigate } from "react-router-dom";

const VideoModal = ({ videoID, onClose }) => {
    const [loading, setLoading] = useState(true); 
    const [vids, setVids] = useState(null);
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const actualVideoId = videoID || localStorage.getItem('vidsId');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVidsDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No access token found');
                    return;
                }

                const config = {
                    method: 'get',
                    url: `${API_URL}/vids/v2/details/${actualVideoId}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await axios.request(config);

                const vidsData = Array.isArray(response.data.data) ? response.data.data[0] : response.data.data;
                setVids(vidsData);
                setLoading(false); 
            } catch (error) {
                console.error("Error fetching post details:", error);
                setLoading(false); 
            }
        };

        if (actualVideoId) {
            fetchVidsDetails();
        } else {
            console.error('No vidsId found');
            setLoading(false); 
        }
    }, [actualVideoId]);

    useEffect(() => {
        if (vids && vids.createdAt) {
            const formattedTime = calculateTimeAgo(vids.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [vids]);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    if (loading) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white"><CircularProgress /></div>
            </div>
        );
    }

    if (!vids) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-blck/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white">Error fetching post details.</div>
            </div>
        );
    }

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
      };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
            <div className="fixed inset-0 bg-black/60 backdrop-blur" onClick={onClose}></div>
            <CloseIcon className="absolute top-0 right-4 text-white cursor-pointer" onClick={onClose} />
            <div className="relative z-10 flex ml-12">
                <Vids video={vids} />
                <div className="flex bg-black/90 2xl:w-[500px] xl:w-[500px] lg:w-[450px] md:w-[320px] flex-col rounded-tr-lg rounded-br-lg">
                    <div className="flex items-center">
                        <div className="w-10 h-10 bg-white rounded-full overflow-hidden ml-4 mt-4 cursor-pointer"  onClick={() => handleProfile(vids.user.userId)}>
                            <img src={`${PROFILEPHOTO_URL}/${vids.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
                        </div>
                        <div>
                            <span className="text-white block ml-3 text-md mt-3 cursor-pointer"  onClick={() => handleProfile(vids.user.userId)}>{vids.user.firstName} {vids.user.lastName}</span>
                            <span className="block text-gray-300 ml-3 truncate text-xs">
                                {vids.location.length > 20 ? `${vids.location.slice(0, 20)}...` : vids.location}
                            </span>
                        </div>
                        {/* {vids.user.showFollowingStatus && (
                            <div className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1 mt-4 mb-2 ml-12">Follow</div>
                        )} */}
                    </div>
                    <div className="border-b border-white/50 w-full mt-2"></div>
                    <div className="h-[500px]">
                        <VideoComment video={vids} handleProfile={handleProfile} />
                    </div>
                    <div className="border-b border-white/50 w-full mt-14"></div>
                    <VidsBottomsection video={vids} />
                </div>
            </div>
        </div>
    );
};

export default VideoModal;
