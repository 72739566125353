import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom'; 
import {  likeProperty } from '../../../Action/propertyAction';
import { useDispatch, useSelector } from 'react-redux';


const MapView = ({ properties, loading, hoveredPropertyId, onMouseEnter, onMouseLeave }) => {
  const navigate = useNavigate(); 
  const dispatch = useDispatch();

  const {  likedProperties } = useSelector(state => state.properties);

  const handleLike = (property, e) => {
    e.stopPropagation();
   
    dispatch(likeProperty(property.property_id, property.category));
};


  
  const handleClick = (propertyId) => {
    
    navigate(`/homedetail/${propertyId}`); 
  };


  return (
    <div className="bg-white ">
      <div className="px-2 py-12">
      {properties.length ? ( 
        <div className="mt-0 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 xl:gap-x-2 text-white">
         {properties.map((property, index) => (
          <div
           key={index}
           onClick={() => handleClick(property.property_id)}
           onMouseEnter={() => onMouseEnter(property.property_id)}
           onMouseLeave={onMouseLeave}
            className={`${
            hoveredPropertyId === property.property_id ? 'hovered ' : ''
            }cursor-pointer`}
           >
        <div  className="group relative rounded-md overflow-hidden shadow-2xl">
            <div className="aspect-h-1 aspect-w-1 w-full bg-gray-200 lg:aspect-none lg:h-80  md:aspect-none md:h-80 aspect-none h-80  sm:aspect-none sm:h-80">
              <img
              loading="lazy"
              src={property.thumbnail}
              alt={"h" + index}
               className="h-full w-full object-cover object-center aspect-h-1 aspect-w-1 hover:scale-110 ease-in-out duration-500"
               />
    <div className="absolute top-4 right-4 p- flex">
<button
  onClick={(e) => handleLike(property, e)}
  className={`focus:outline-none flex items-center justify-center ${
    likedProperties.includes(property.property_id) ? 'text-red-500' : 'text-white'
  }`}
>
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 24 24" fill={likedProperties.includes(property.property_id) ? 'red' : 'currentColor'}>
    <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>
  </svg>
</button>
</div>
                <div className="absolute top-0 left-0 flex flex-col">
                  <div className="py-1 pl-2 pr-6 bg-green-600 bg-opacity-80 rounded-tl-md">{property.prop_status}</div>
                  <div className="py-1 pl-2 pr-6 bg-company-color bg-opacity-80">$ {property.price}</div>
                </div>
                <div className="absolute bottom-0 left-0 w-full">
                <div className="flex gap-4 self-stretch px-4 py-0.5 text-sm sm:text-xs text-center bg-company-color bg-opacity-80"
                >
                 <div className="flex-auto text-md">
                       {property.prop_type && `${property.prop_type}`} 
                       {property.beds > 0 && ` | ${property.beds} beds`} 
                       {property.baths > 0 && ` | ${property.baths} baths`} 
                       {property.lot_size !== "unavailable" && ` | ${property.lot_size}`}

                     </div>
         
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 fill-red-600 max-sm:absolute max-sm:bottom-[32px]">
                  <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
                </svg> */}
             </div>
             {/* <div className="flex gap-0 max-md:flex-wrap max-sm:hidden max-lg:hidden ">
                {[...Array(4)].map((_, index) => (
                  <img
                    key={index}
                    loading="lazy"
                    src={property.thumbnail}
                    className="object-cover aspect-[1.47] w-1/4 min-w-0"
                  />
                ))}
             </div> */}
             <div
              className="flex gap-4 text-base font-medium text-blue-800 whitespace-nowrap max-md:flex-wrap w-1/1"
             >
              <div
                className="grow justify-center py-1.5 pl-1 bg-zinc-300 bg-opacity-80 "
              >
              <div className="truncate text-xs ">
              {property.address.line}, {property.address.city}, {property.address.state}, {property.address.state_code}, {property.address.postal_code}
              </div>
              </div>
             </div>
             </div>
           </div>
             </div>
             </div>
               ))}
            </div>
) : (
  <div className="text-center text-gray-600">No properties found for this location.</div>
)}
</div>
</div>
);
};


export default MapView;
