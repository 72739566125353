// import React, { useState, useEffect } from 'react';
// import { useNavigate, Routes, Route, useLocation , Navigate } from 'react-router-dom';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Profile from "../../../../assets/images/profile.jpeg";
// import { PostIcon, VidsIcon, AudioIcon } from './icon';
// import PostsGrid from './Postgrid';
// import VidsGrid from './Vidsgrid';
// import Audio from './Audio';
// import { fetchUserData } from '../userapi';
// import  './style.css' 
// import CircularProgress from '@mui/material/CircularProgress';
// import { PROFILEPHOTO_URL } from '../../../../Config/config';

// const Mdlguser = ({user, loading, error}) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [activeTab, setActiveTab] = useState('post');

//   useEffect(() => {
//     const path = location.pathname;
  
//     const isProfileVidsPath = /^\/smartsocial\/profile\/vids(?:\/|$)/.test(path);
//     const isProfilePath = /^\/smartsocial\/profile\/post(?:\/|$)/.test(path);
//     const isProfileAudioPath = /^\/smartsocial\/profile\/audio(?:\/|$)/.test(path);
    
//     if ( isProfileVidsPath && !isProfilePath && !isProfileAudioPath) {
//       setActiveTab('vids');
//     } else {
//       const lastPart = path.split('/').filter(Boolean).pop(); 
//       setActiveTab(lastPart || 'vids');
//     }
//   }, [location.pathname]);

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
    
//     if (tab !== 'vids') {
//       navigate(tab);
//     } else {
 
//       navigate('./vids');
//     }
//   };

 
//   if (loading) {
//     return <div role="status" className='flex items-center justify-center min-h-screen ml-72'>
//     <CircularProgress />
//     </div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="flex flex-col items-center post-container 2xl:ml-80 xl:ml-80 lg:ml-56 md:ml-16  mt-4 mb-14 ">
//       {user && (
//         <div className="relative 2xl:w-[800px] xl:w-[800px] lg:w-[700px] md:w-[600px] mt-6">
//           <div className="flex absolute 2xl:w-44 2xl:h-44 xl:w-44 xl:h-44 lg:w-40 lg:h-40 md:w-36 md:h-36 bg-white rounded-full overflow-hidden ml-4">
//             <img src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//           </div>

//           <div className="flex items-center text-white text-2xl font-regular pt-2 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-44">
//   {user.firstName} {user.lastName}
//   {user.canEdit ? (
//     <button
//       type="button"
//       className="text-white bg-transparent border-2 border-white/80 hover:bg-blue-800 font-medium rounded-xl text-sm px-8 py-1 ml-12"
//     >
//       Edit
//     </button>
//   ) : (
//     <button
//       type="button"
//       className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1.5 ml-12"
//     >
//       Follow
//     </button>
//   )}
// </div>

//           <div className="flex text-md  text-white/90 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-44 mt-6 whitespace-nowrap">
           
//             <div className="  whitespace-nowrap">{user.followers} followers</div>
//             <div className="ml-6  whitespace-nowrap">{user.followings} following</div>
//             <div className='ml-6  whitespace-nowrap'>{user.posts.length} posts</div>
//             <div className='ml-6  whitespace-nowrap'>{user.totalLikes} likes</div>
//           </div>

//           <div className="flex text-white 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-44 mt-6">@{user.userName}</div>
//           <div className="flex text-white/80  2xl:ml-60 xl:ml-[243px] lg:ml-56 md:ml-44 mt-2"> User Bio</div>

//           <div className="w-full border-t border-b border-gray-400 mt-14">
//           <div className="flex justify-center text-l text-white flex-wrap -mb-px">
//           <div
//               className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'vids' ? 'active border-b-4 text-white' : ''}`}
//               onClick={() => handleTabChange('vids')}
//             >
//               <VidsIcon active={activeTab === 'vids'} />
//               <div className="mx-2">Vids</div>
//            </div>

//             <div
//               className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'post' ? 'active border-b-4 text-white' : ''}`}
//               onClick={() => handleTabChange('post')}
//             >
//                  <PostIcon active={activeTab === 'post'} />
//               <div className="mx-2">Post</div>
//             </div>
           
//             <div
//               className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'audio' ? 'active border-b-4 text-white' : ''}`}
//               onClick={() => handleTabChange('audio')}
//             >
//               <AudioIcon active={activeTab === 'audio'} />
//               <div className="mx-2">Audio</div>
//             </div>

//           </div>
//         </div>

//         <Routes>
//         <Route path="vids" element={<VidsGrid vids={user.vids} />} />
//           <Route path="post" element={<PostsGrid posts={user.posts} />} />
//           <Route path="audio" element={<Audio />} />
//           <Route path="/" element={<Navigate to="vids" replace />} />
//         </Routes>

        
//       </div>
//       )}
//       </div>
    
//   );
// };

// export default Mdlguser;


// import React, { useState, useEffect } from 'react';
// import { useNavigate, Routes, Route, useLocation, Navigate } from 'react-router-dom';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Profile from "../../../../assets/images/profile.jpeg";
// import { PostIcon, VidsIcon, AudioIcon } from './icon';
// import PostsGrid from './Postgrid';
// import VidsGrid from './Vidsgrid';
// import Audio from './Audio';
// import { fetchUserData } from '../userapi';
// import './style.css';
// import CircularProgress from '@mui/material/CircularProgress';
// import { PROFILEPHOTO_URL } from '../../../../Config/config';
// import Userfollowersfollowing from './userfollowers';


// const Mdlguser = ({ user, loading, error }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [activeTab, setActiveTab] = useState('post');
//   const [imageLoading, setImageLoading] = useState(true);
//   const [selectedUserFollower, setSelectedUserFollower] = useState(null);
//   const [activeModalTab, setActiveModalTab] = useState('1');

//   useEffect(() => {
//     const path = location.pathname;
  
//     const isProfileVidsPath = /^\/smartsocial\/profile\/vids(?:\/|$)/.test(path);
//     const isProfilePath = /^\/smartsocial\/profile\/post(?:\/|$)/.test(path);
//     const isProfileAudioPath = /^\/smartsocial\/profile\/audio(?:\/|$)/.test(path);
    
//     if (isProfileVidsPath && !isProfilePath && !isProfileAudioPath) {
//       setActiveTab('vids');
//     } else {
//       const lastPart = path.split('/').filter(Boolean).pop(); 
//       setActiveTab(lastPart || 'vids');
//     }
//   }, [location.pathname]);

//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
    
//     if (tab !== 'vids') {
//       navigate(tab);
//     } else {
//       navigate('./vids');
//     }
//   };

//   const handleImageLoad = () => {
//     setImageLoading(false);
//   };

//   const openModal = (tab) => {
//     setActiveModalTab(tab); 
//     setSelectedUserFollower(true); 
//   };

//   const closeModal = () => {
//     setSelectedUserFollower(null); 
//   };

//   if (loading) {
//     return (
//       <div role="status" className='flex items-center justify-center min-h-screen ml-72'>
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="flex flex-col items-center post-container 2xl:ml-80 xl:ml-80 lg:ml-56 md:ml-16 mt-4 mb-14 overflow-hidden">
//       {user && (
//         <div className="relative 2xl:w-[800px] xl:w-[800px] lg:w-[700px] md:w-[600px] mt-6">
//           <div className="flex absolute 2xl:w-44 2xl:h-44 xl:w-44 xl:h-44 lg:w-40 lg:h-40 md:w-36 md:h-36 bg-white rounded-full overflow-hidden ml-4">
//             {imageLoading && (
//               <div className="flex items-center justify-center w-full h-full">
//                 <CircularProgress />
//               </div>
//             )}
//             <img
//               src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`}
//               alt="Profile"
//               className={`object-cover w-full h-full ${imageLoading ? 'hidden' : 'block'}`}
//               onLoad={handleImageLoad}
//             />
//           </div>

//           <div className="flex items-center text-white text-2xl font-regular pt-2 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-48">
//             {user.firstName} {user.lastName}
//             {/* {user.canEdit ? (
//               <button
//                 type="button"
//                 className="text-white bg-transparent border-2 border-white/80 hover:bg-blue-800 font-medium rounded-xl text-sm px-8 py-1 ml-12"
//               >
//                 Edit
//               </button>
//             ) : (
//               user.isUserFollowing ? (
//                 <button
//                 type="button"
//                 className="text-white border border-gray-400 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-1 ml-28 sm:ml-40 mt-3"
//                 >
//                 Following
//               </button>
//                ) : (
//               <button
//                 type="button"
//                 className="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 py-1.5 ml-12"
//               >
//                 Follow
//               </button>
//             )
//             )} */}
//           </div>

//           <div className="flex text-md text-white/90 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-48 mt-6 whitespace-nowrap">
//               <div className="whitespace-nowrap cursor-pointer" onClick={() => openModal('1')}>{user.followers} followers</div>
//             <div className="ml-6 whitespace-nowrap cursor-pointer" onClick={() => openModal('2')}>{user.followings} following</div>
//             {/* <div className='ml-6 whitespace-nowrap'>{user.posts.length} posts</div> */}
//             <div className='ml-6 whitespace-nowrap'>{user.totalLikes} likes</div>
//           </div>

//           <div className="flex text-white 2xl:ml-60 xl:ml-60 lg:ml-52 md:ml-48 mt-6">@{user.userName}</div>
//           {/* <div className="flex text-white/80 2xl:ml-60 xl:ml-[243px] lg:ml-52 md:ml-48 mt-2"> User Bio</div> */}

//           <div className="w-full border-t border-b border-gray-400 mt-14">
//             <div className="flex justify-center items-center text-lg text-white flex-wrap -mb-px">
//               <div
//                 className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'vids' ? 'active border-b-4 text-white' : ''}`}
//                 onClick={() => handleTabChange('vids')}
//               >
//                 <VidsIcon active={activeTab === 'vids'} />
//                 <div className="mx-2">Vids</div>
//               </div>

//               <div
//                 className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'post' ? 'active border-b-4 text-white' : ''}`}
//                 onClick={() => handleTabChange('post')}
//               >
//                 <PostIcon active={activeTab === 'post'} />
//                 <div className="mx-2">Post</div>
//               </div>
           
//               {/* <div
//                 className={`flex mx-10 cursor-pointer p-3 text-gray-400 ${activeTab === 'audio' ? 'active border-b-4 text-white' : ''}`}
//                 onClick={() => handleTabChange('audio')}
//               >
//                 <AudioIcon active={activeTab === 'audio'} />
//                 <div className="mx-2">Audio</div>
//               </div> */}
//             </div>
//           </div>

//           <Routes>
//             <Route path="vids" element={<VidsGrid vids={user.vids} />} />
//             <Route path="post" element={<PostsGrid posts={user.posts} />} />
//             <Route path="audio" element={<Audio />} />
//             <Route path="/" element={<Navigate to="vids" replace />} />
//           </Routes>
//         </div>
//       )}

// {selectedUserFollower && (
//         <Userfollowersfollowing activeTab={activeModalTab} onClose={closeModal} /> 
//       )}
//     </div>
//   );
// };

// export default Mdlguser;


import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { PostIcon, VidsIcon, AudioIcon } from './icon';
import PostsGrid from './Postgrid';
import VidsGrid from './Vidsgrid';
import Audio from './Audio';
import './style.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Userfollowersfollowing from './userfollowers';
import { PROFILEPHOTO_URL } from '../../../../Config/config';

const Mdlguser = ({ user, loading, error }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [value, setValue] = useState('1'); 
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedUserFollower, setSelectedUserFollower] = useState(null);

  useEffect(() => {
    const path = location.pathname;

    if (path.includes('/vids')) {
      setValue('1');
    } else if (path.includes('/post')) {
      setValue('2');
    } else if (path.includes('/audio')) {
      setValue('3');
    }
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const path = newValue === '1' ? 'vids' : newValue === '2' ? 'post' : 'audio';
    navigate(path);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  const openModal = () => {
    setSelectedUserFollower(true);
  };

  const closeModal = () => {
    setSelectedUserFollower(null);
  };

  if (loading) {
    return (
      <div role="status" className="flex items-center justify-center min-h-screen ml-72">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="flex flex-col items-center post-container 2xl:ml-80 xl:ml-80 lg:ml-56 md:ml-16 mt-4 mb-14 overflow-hidden">
      {user && (
        <div className="relative 2xl:w-[800px] xl:w-[800px] lg:w-[700px] md:w-[600px] mt-6">
          <div className="flex absolute 2xl:w-52 2xl:h-52 xl:w-48 xl:h-48 lg:w-48 lg:h-48 md:w-36 md:h-36 bg-white rounded-full overflow-hidden ml-4">
            {imageLoading && (
              <div className="flex items-center justify-center w-full h-full">
                <CircularProgress />
              </div>
            )}
            <img
              src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`}
              alt="Profile"
              className={`object-cover w-full h-full ${imageLoading ? 'hidden' : 'block'}`}
              onLoad={handleImageLoad}
            />
          </div>

          <div className="flex items-center text-white text-3xl font-regular pt-2 2xl:ml-60 xl:ml-60 lg:ml-60 md:ml-48">
            {user.firstName} {user.lastName}
          </div>

          <div className="flex text-lg text-white/70 2xl:ml-60 xl:ml-[242px] lg:ml-[242px] md:ml-48 mt-4 whitespace-nowrap">
            <div className="whitespace-nowrap cursor-pointer" onClick={openModal}>
              {user.followers} followers
            </div>
            <div className="ml-6 whitespace-nowrap cursor-pointer" onClick={openModal}>
              {user.followings} following
            </div>
            <div className='ml-6 whitespace-nowrap'>{user.totalLikes} likes</div>
          </div>

          <div className="flex text-white text-md 2xl:ml-60 xl:ml-60 lg:ml-60 md:ml-48 mt-12">@{user.userName}</div>

          <Box sx={{ width: '100%', typography: 'body1' , marginTop:'62px'  }}>
            <TabContext value={value}>
              <Box sx={{
        borderBottom: 0.5,
        borderTop: 0.5,
        borderColor: 'white',
        color: 'white',
        padding: 0,
        margin: 0,
      }}>
                <TabList 
                  onChange={handleTabChange} 
                  aria-label="lab API tabs example"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    padding: 0, 
                    margin: 0,
                  }}
                  TabIndicatorProps={{
                    style: { height: '3px', backgroundColor: 'white' ,  }
                  }}
                  variant="fullWidth"
                >
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <VidsIcon active={value === '1'} />
                      </Box>
                    } 
                    label="Vids" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3', 
                      fontSize: value === "1" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'left',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="1" 
                  />
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <PostIcon active={value === '2'} />
                      </Box>
                    } 
                    label="Post" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "2" ? '1.4rem' : '1.0rem',
                      flexGrow: 1, 
                      textAlign: 'center',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="2" 
                  />
                  {/* <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <AudioIcon active={value === '3'} />
                      </Box>
                    } 
                    label="Audio" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "3" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'right',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="3" 
                  /> */}
                </TabList>
              </Box>
            </TabContext>
          </Box>

          <Routes>
            <Route path="vids" element={<VidsGrid vids={user.vids} />} />
            <Route path="post" element={<PostsGrid posts={user.posts} />} />
            <Route path="audio" element={<Audio />} />
            <Route path="/" element={<Navigate to="vids" replace />} />
          </Routes>
        </div>
      )}
      {selectedUserFollower && (
        <Userfollowersfollowing activeTab={value} onClose={closeModal} /> 
      )}
    </div>
  );
};

export default Mdlguser;

