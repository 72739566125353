import React, { useState,useEffect, useRef } from 'react';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

const PostComment = ({ postId, incrementCommentCount, activeEmojiPicker, setActiveEmojiPicker }) => {
    const [comment, setComment] = useState('');
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const emojiPickerRef = useRef(null);


  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      const config = {
        method: 'get',
        url: `${API_URL}/users/v2/info`,
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };

      try {
        const response = await axios.request(config);
        setUserProfile(response.data.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setActiveEmojiPicker(null);
  };

  const toggleEmojiPicker = () => {
    if (activeEmojiPicker === postId) {
      setActiveEmojiPicker(null);
    } else {
      setActiveEmojiPicker(postId);
    }
  };


  const onEmojiClick = (emojiObject) => {
    const newComment = (comment || '') + emojiObject.emoji;
    setComment(newComment);
    setChosenEmoji(emojiObject);
  };

  const postComment = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found in local storage');
      return;
    }

    const data = JSON.stringify({ comment });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/posts/v3/comment/${postId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    setLoading(true);
    setError('');

    try {
      const response = await axios.request(config);

      setComment('');
      setChosenEmoji(null);
      setLoading(false);
      incrementCommentCount(postId);
      setActiveEmojiPicker(null); 
    } catch (error) {
      setError('Failed to post comment. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setActiveEmojiPicker(null);
      }
    };

    if (activeEmojiPicker === postId) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeEmojiPicker, postId, setActiveEmojiPicker]);

  return (
    <>
    <div>
    {userProfile ? (
        <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
          <img
            src={`${PROFILEPHOTO_URL}/${userProfile.profilePhoto}`}
            alt="Profile"
            className="object-cover w-full h-full"
          />
        </div>
      ) : (
        <CircularProgress size={30} />
      )}
      </div>
    <div className='relative mt-1 ml-2  flex items-center  border-gray-400 rounded-sm'>
      <input
        className="bg-gray-200 text-black ml-1 w-[300px] p-[5px] focus:outline-none"
        style={{ caretColor: 'gray' }}
        placeholder={'Add a comment.....'}
        value={comment}
        onChange={handleCommentChange}
        onFocus={() => setActiveEmojiPicker(null)}      
         />
      {chosenEmoji ? (
        <span className="ml-0 cursor-pointer" onClick={toggleEmojiPicker}>{chosenEmoji.emoji}</span>
      ) : (
        <span className="ml-0 cursor-pointer" onClick={toggleEmojiPicker}>😊</span>
      )}
       {activeEmojiPicker === postId && (
        <div ref={emojiPickerRef} className="absolute bottom-full mb-2 z-10 emoji-picker-container">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      <div className="ml-0">
        <button
          className={`text-black py-1 px-2 mr-1 rounded-xl ${!comment.trim() && 'opacity-50 cursor-not-allowed'}`}
          onClick={postComment}
          disabled={!comment.trim() || loading}
        >
          {loading ? (
            <CircularProgress size={20} style={{ color: "black" }} />
          ) : 'Post'}
        </button>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
    </>
  );
};

export default PostComment;
