import React from "react";
import { Outlet, Route, Routes , Navigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Postlayout from "./Post/postlayout";
import Vidslayout from "./Vids/vidslayout";
import UserProfile from "./UserProfile";
// import './style.css'

const SmartSocial = () => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (!userData) {
    return <Navigate to="/login" />;
  }
  
  return (
    <div className=" w-full bg-custom-blue min-h-screen flex">
      <div className="fixed h-full z-20">
        <Sidebar />
      </div>
      <div className="flex-1 overflow-y-auto scroll-smooth">
        <Routes>
        <Route path="/" element={<Navigate to="post" />} />
          <Route path="post" element={<Postlayout />} />
          <Route path="vids" element={<Vidslayout />} />
          <Route path=":userid/*" element={<UserProfile />} />

          
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export default SmartSocial;


