import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatDate } from "../../Mdlgscreen/Utils/dateutil";
import { API_URL, PROFILEPHOTO_URL } from "../../../../../Config/config";
import CommentModal from './commentmodal';
import PostaComment from "./postacomment";
import axios from "axios";
import Postimage from "./postimage";
import PostBottomSection from "./postbottomsection";
import CircularProgress from '@mui/material/CircularProgress'; 
import { useNavigate } from "react-router-dom";

const PostModal = ({ postId, onClose }) => {
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [commentCount, setCommentCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [post, setPost] = useState(null);
    const actualPostId = postId || localStorage.getItem('postId');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPostDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No access token found');
                    return;
                }

                const config = {
                    method: 'get',
                    url: `${API_URL}/posts/v3/details/${actualPostId}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await axios.request(config);

                const postData = Array.isArray(response.data.data) ? response.data.data[0] : response.data.data;
                setPost(postData);
                setCommentCount(postData.commentCount);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching post details:", error);
                setLoading(false);
            }
        };

        if (actualPostId) {
            fetchPostDetails();
        } else {
            console.error('No postId found');
            setLoading(false);
        }
    }, [actualPostId]);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleViewComments = () => {
        setShowCommentModal(true);
    };

    const handleCloseCommentModal = () => {
        setShowCommentModal(false);
    };

    const incrementCommentCount = () => {
        setCommentCount(commentCount + 1);
    };

    const decrementCommentCount = () => {
        setCommentCount(commentCount - 1);
    };

    if (loading) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white"><CircularProgress /></div>
            </div>
        );
    }

    if (!post) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white">Error fetching post details.</div>
            </div>
        );
    }

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
      };

    return (
        <div className="fixed inset-0 z-50 flex flex-col bg-black/90  overflow-y-auto flex-grow">
            <div className="relative flex items-center justify-center mt-4 text-lg text-white w-full ">
                <div className="absolute left-4 cursor-pointer">
                    <ArrowBackIcon onClick={onClose} />
                </div>
                <div>
                    Post
                </div>
            </div>
            <div className="mt-4 border-b border-gray-500 w-full"></div>
            <div className="mt-4 ml-4 flex items-center ">
                <div className="w-10 h-10 ml-2 bg-white rounded-full overflow-hidden cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                    <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full rounded-full" />
                </div>
                <div className="flex flex-col text-gray-400 ml-4">
                    <span className="text-md text-white cursor-pointer" onClick={() => handleProfile(post.user.userId)}>{post.user.firstName} {post.user.lastName}</span>
                    <span className="text-xs text-gray-400">                               
                      {post.location.length > 20 ? `${post.location.slice(0, 20)}...` : post.location}
       </span>
                </div>
                {/* {post.user.showFollowBtn && (
                    <li className="text-white ml-4">
                        <button type="button" className="text-blue-500 text-md ml-0">Follow</button>
                    </li>
                )} */}
            </div>
            <div>
                <Postimage post={post} />
            </div>
            <div>
                <PostBottomSection post={post} handleViewComments={handleViewComments} handleProfile={handleProfile} />
            </div>
            <div className="mx-3.5 mb-1 mt-2.5 text-white/80">
                <div className="flex text-sm cursor-pointer" onClick={handleViewComments}>
                    View All {commentCount} Comments
                </div>
                <div className="flex items-center text-xs mx-1">
                    <div className="w-8 h-8 ml-0 mt-1 bg-white rounded-full overflow-hidden">
                        <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full rounded-full" />
                    </div>
                    <PostaComment incrementCommentCount={incrementCommentCount}  />
                </div>
            </div>
            <div className="mx-3.5 mb-4 mt-2 text-xs text-white/80">{formatDate(post.createdAt)}</div>
            {showCommentModal && (
                <CommentModal post={post} onClose={handleCloseCommentModal} incrementCommentCount={incrementCommentCount} decrementCommentCount={decrementCommentCount} handleProfile={handleProfile} />
            )}
        </div>
    );
};

export default PostModal;
