// import React, { useEffect, useState, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchSearchResults } from '../../Action/searchaction';
// import { fetchProperties, updatePageNumber } from '../../Action/propertyAction';

// const Search = () => {
//     const dispatch = useDispatch();
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [searchQuery, setSearchQuery] = useState('');
//     const { states, cities, streets, postal } = useSelector(state => state.search);

//     useEffect(() => {
//         window.addEventListener('click', handleClickOutside);
//         const storedSearchQuery = localStorage.getItem('searchQuery');
//         if (storedSearchQuery) {
//             setSearchQuery(storedSearchQuery);
//         }
//         return () => {
//             window.removeEventListener('click', handleClickOutside);
//         };
//     }, []);

//     const handleClickOutside = (event) => {
//         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//             setShowDropdown(false);
//         }
//     };

//     const handleSearchInputChange = (e) => {
//         const inputValue = e.target.value;
//         const nonSpaceChars = inputValue.replace(/\s/g, ''); 
//         setSearchQuery(inputValue); 
//         if (nonSpaceChars.length >= 4) { 
//             dispatch(fetchSearchResults(inputValue)); 
//             setShowDropdown(true);
//         } else {
//             setShowDropdown(false);
//             clearLocalStorage();
//         }
//     };

//     const clearLocalStorage = () => {
//         Object.keys(localStorage)
//             .filter(key => key.startsWith('properties_page_'))
//             .forEach(key => localStorage.removeItem(key));
    
//             // localStorage.removeItem('properties_page_0');
//         localStorage.removeItem('currentPageNumber')
//     };

//     const handleSearchSubmit = (e) => {
//         e.preventDefault();
//         if (searchQuery.trim() !== '') { 
//             console.log('Search Query:', searchQuery); 
//             dispatch(fetchSearchResults(searchQuery)); 
//         } else {
//             console.log('Search Query is empty'); 
//         }
//     };
    
//     const handleSearchSelection = (selectedItem) => {
//         console.log('Selected Item:', selectedItem);
//         let searchValue = '';
//         let displayValue = '';
//         let areaType = ''; 
    
//         if (selectedItem.area_type === 'state') {
//             searchValue = selectedItem.state_code;
//             displayValue = `${selectedItem.state}, ${selectedItem.state_code}`;
//             areaType = 'state'; 
//         } else if (selectedItem.area_type === 'city') {
//             searchValue = selectedItem.city;
//             displayValue = `${selectedItem.city}, ${selectedItem.state_code}`;
//             areaType = 'city';
//         } else if (selectedItem.area_type === 'street') {
//             searchValue = selectedItem.street;
//             displayValue = `${selectedItem.street}, ${selectedItem.state_code}`;
//             areaType = 'street';
//         } 
//             else if (selectedItem.area_type === 'postal_code') {
//                 searchValue = selectedItem.postal_code;
//                 displayValue = `${selectedItem.postal_code}, ${selectedItem.state_code}`;
//                 areaType = 'postal_code';
//         }
        
//         setSearchQuery(displayValue);
//         setShowDropdown(false);
//         dispatch(updatePageNumber(0));  
//         dispatch(fetchProperties(searchValue, areaType, 0)); 
        
//         localStorage.setItem('searchQuery', displayValue);
//         localStorage.setItem('search', searchValue);
//         localStorage.setItem('areaType', areaType); 
//         localStorage.setItem('currentPageNumber',0); 
//     };
    
//     // useEffect(() => {
//     //     const storedSearchQuery = localStorage.getItem('search');
//     //     dispatch(fetchProperties(storedSearchQuery));

//     //     const handleClickOutside = (event) => {
//     //         if (dropdownRef.current && !dropdownRef.current.contains(event.target))
//     //             setShowDropdown(false);
//     //     };
    
//     //     window.addEventListener('click', handleClickOutside);
//     //     return () => window.removeEventListener('click', handleClickOutside);
//     // }, [dispatch]);
    
//     return (
//         <div>
//             <label htmlFor="default-search" className="sr-only">
//                 Search
//             </label>
//             <div className="relative flex-grow" ref={dropdownRef}>
//                 <div className="absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden sm:flex">
//                     <svg
//                         className="w-4 h-4 text-gray-500"
//                         aria-hidden="true"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 20 20"
//                     >
//                         <path
//                             stroke="currentColor"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth="2"
//                             d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                         />
//                     </svg>
//                 </div>
//                 <input
//                     type="search"
//                     id="default-search"
//                     className="block w-full p-3.5 sm:pl-12 text-sm text-gray-900 border border-gray-600 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400 rounded-l-none"
//                     placeholder="Enter Text To Search...."
//                     required
//                     value={searchQuery}
//                     onChange={handleSearchInputChange}
//                 />
//                 {showDropdown && (
//                     <div className="absolute mt-1 rounded-tl-none rounded-tr-none rounded-bl-2xl rounded-br-2xl w-full min-h-72 bg-white border border-black shadow-2xl z-10">
//                         <ul
//                             role="listbox"
//                             aria-labelledby="downshift-2-label"
//                             id="downshift-2-menu"
//                             className="menu autocomplete-menu"
//                         >
//                             {states.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-4">
//                                         <span className="header text-xl font-semibold ml-6 justify-between ">States</span>
//                                     </div>
//                                     {states.map((state, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer  ">
//                                             <button className="ml-8 py-2 text-md justify-between" onClick={() => handleSearchSelection(state)}>{state.state}, {state.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
                           
//                             {cities.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Cities</span>
//                                     </div>
//                                     {cities.map((city, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer ">
//                                             <button className="ml-8  py-2 text-md justify-between " onClick={() => handleSearchSelection(city)}>{city.city}, {city.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
//                             {streets && streets.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Street</span>
//                                     </div>
//                                     {streets.map((street, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
//                                             <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(street)}> {street.street}, {street.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
//                             {postal && postal.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Postal Code</span>
//                                     </div>
//                                     {postal.map((postalCode, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
//                                             <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(postalCode)}> {postalCode.postal_code}, {postalCode.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
//                         </ul>
//                     </div>
//                 )}

//                 <button
//                     type="button"
//                     className="hidden lg:block text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-800 font-sm rounded-lg text-sm px-4 py-1"
//                     onClick={handleSearchSubmit}
//                 >
//                     Search
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default Search;



// import React, { useEffect, useState, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchSearchResults } from '../../Action/searchaction';
// import { fetchProperties, updatePageNumber } from '../../Action/propertyAction';
// import { useNavigate } from 'react-router-dom';
// import CloseIcon from '@mui/icons-material/Close';
// import './style.css';

// const Search = () => {
//     const dispatch = useDispatch();
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [searchQuery, setSearchQuery] = useState('');
//     const navigate = useNavigate();
//     const { states, cities, streets, postal, addresses } = useSelector(state => state.search); 


//     // useEffect(() => {
//     //     window.addEventListener('click', handleClickOutside);
//     //     const storedSearchQuery = localStorage.getItem('searchQuery');
//     //     if (storedSearchQuery) {
//     //         setSearchQuery(storedSearchQuery);
//     //     }
//     //     return () => {
//     //         window.removeEventListener('click', handleClickOutside);
//     //     };
//     // }, []);

//     useEffect(() => {
//         window.addEventListener('click', handleClickOutside);
//         const storedSearchQuery = localStorage.getItem('searchQuery');
//         if (storedSearchQuery) {
//             setSearchQuery(storedSearchQuery);
//         } else {
//             const defaultSearchQuery = 'New York, NY';
//             const defaultsearchValue = 'NY';
//             const defaultareaType = 'state'
//             setSearchQuery(defaultSearchQuery);
//             localStorage.setItem('searchQuery', defaultSearchQuery);
//             localStorage.setItem('search', defaultsearchValue);
//             localStorage.setItem('areaType', defaultareaType);

//             dispatch(fetchProperties(defaultsearchValue));
//         }
//         return () => {
//             window.removeEventListener('click', handleClickOutside);
//         };
//     }, [dispatch]);
    
//     const handleClickOutside = (event) => {
//         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//             setShowDropdown(false);
//         }
//     };

//     const handleSearchInputChange = (e) => {
//         const inputValue = e.target.value;
//         const nonSpaceChars = inputValue.replace(/\s/g, ''); 
//         setSearchQuery(inputValue); 
//         if (nonSpaceChars.length >= 4) { 
//             dispatch(fetchSearchResults(inputValue)); 
//             setShowDropdown(true);
//         } else {
//             setShowDropdown(false);
//             clearLocalStorage();
//         }
//     };

//     const clearLocalStorage = () => {
//         Object.keys(localStorage)
//             .filter(key => key.startsWith('properties_page_'))
//             .forEach(key => localStorage.removeItem(key));
    
//             // localStorage.removeItem('properties_page_0');
//         localStorage.removeItem('currentPageNumber')
//     };

//     const handleSearchSubmit = (e) => {
//         e.preventDefault();
//         if (searchQuery.trim() !== '') { 
//             dispatch(fetchSearchResults(searchQuery)); 
//         } else {
//         }
//     };
    
//     const handleSearchSelection = (selectedItem) => {
//         let searchValue = '';
//         let displayValue = '';
//         let areaType = ''; 
    
//         if (selectedItem.area_type === 'address') {
//             searchValue = selectedItem['property_id']; 
//             displayValue = selectedItem.area;
    
//             navigate(`/homedetail/${searchValue}`); 
    
//             setSearchQuery(displayValue);
//             setShowDropdown(false);
//             localStorage.setItem('searchQuery', displayValue);
//             localStorage.setItem('search', searchValue);
//         } else {
//             if (selectedItem.area_type === 'street') {
//                 searchValue = selectedItem.street;
//                 displayValue = `${selectedItem.street}, ${selectedItem.state_code}`;
//                 areaType = 'street';
//             } else if (selectedItem.area_type === 'city') {
//                 searchValue = selectedItem.city;
//                 displayValue = `${selectedItem.city}, ${selectedItem.state_code}`;
//                 areaType = 'city';
//             } else if (selectedItem.area_type === 'state') {
//                 searchValue = selectedItem.state_code;
//                 displayValue = `${selectedItem.state}, ${selectedItem.state_code}`;
//                 areaType = 'state'; 
//             } else if (selectedItem.area_type === 'postal_code') {
//                 searchValue = selectedItem.postal_code;
//                 displayValue = `${selectedItem.postal_code}, ${selectedItem.state_code}`;
//                 areaType = 'postal_code';
//             }
    
//             setSearchQuery(displayValue);
//             setShowDropdown(false);
//             dispatch(updatePageNumber(0));  
    
           
//             if (areaType !== 'address') {
//                 dispatch(fetchProperties(searchValue, areaType, 0)); 
//                 localStorage.setItem('areaType', areaType); 
//             }
    
//             localStorage.setItem('searchQuery', displayValue);
//             localStorage.setItem('search', searchValue);
//             localStorage.setItem('currentPageNumber', 0); 
//         }
//     };
    
    
//     // useEffect(() => {
//     //     const storedSearchQuery = localStorage.getItem('search');
//     //     dispatch(fetchProperties(storedSearchQuery));

//     //     const handleClickOutside = (event) => {
//     //         if (dropdownRef.current && !dropdownRef.current.contains(event.target))
//     //             setShowDropdown(false);
//     //     };
    
//     //     window.addEventListener('click', handleClickOutside);
//     //     return () => window.removeEventListener('click', handleClickOutside);
//     // }, [dispatch]);
    
//     const handleClearSearch = () => {
//         setSearchQuery(''); // Clear input box
//         setShowDropdown(false); // Hide dropdown
//     };

//     return (
//         <div>
//             <label htmlFor="default-search" className="sr-only">
//                 Search
//             </label>
//             <div className="relative flex-grow" ref={dropdownRef}>
//                 <div className="absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden sm:flex">
//                     <svg
//                         className="w-4 h-4 text-gray-500"
//                         aria-hidden="true"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 20 20"
//                     >
//                         <path
//                             stroke="currentColor"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth="2"
//                             d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
//                         />
//                     </svg>
//                 </div>
//                 <input
//                     type="search"
//                     id="default-search"
//                     className="block w-full p-3.5 sm:pl-12 text-sm text-gray-900 border border-gray-600 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400 rounded-l-none"
//                     placeholder="Enter Text To Search...."
//                     required
//                     value={searchQuery}
//                     onChange={handleSearchInputChange}
//                     style={{ WebkitAppearance: 'none' }} 
//                 />
//                 {searchQuery && (
//                     <div className='absolute 2xl:end-[10px] 2xl:bottom-3  xl:end-[10px] xl:bottom-3 lg:end-[10px] lg:bottom-3 end-2.5 bottom-3 cursor-pointer'>
//                         <CloseIcon onClick={handleClearSearch} />
//                     </div>
//                 )}
//                 {showDropdown && (
//                     <div className="absolute mt-1 rounded-tl-none rounded-tr-none rounded-bl-2xl rounded-br-2xl w-full min-h-72 bg-white border border-black shadow-2xl z-10">
//                         <ul
//                             role="listbox"
//                             aria-labelledby="downshift-2-label"
//                             id="downshift-2-menu"
//                             className="menu autocomplete-menu"
//                         >

//                            {addresses.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Addresses</span>
//                                     </div>
//                                     {addresses.map((address, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer truncate">
//                                             <button className="ml-8 text-md justify-between py-2 truncate" onClick={() => handleSearchSelection(address)}>{address.area}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
                            
//                             {streets && streets.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Street</span>
//                                     </div>
//                                     {streets.map((street, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
//                                             <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(street)}> {street.street}, {street.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
                           
                           
//                             {cities.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Cities</span>
//                                     </div>
//                                     {cities.map((city, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer ">
//                                             <button className="ml-8  py-2 text-md justify-between " onClick={() => handleSearchSelection(city)}>{city.city}, {city.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
//                              {states.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-4">
//                                         <span className="header text-xl font-semibold ml-6 justify-between ">States</span>
//                                     </div>
//                                     {states.map((state, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer  ">
//                                             <button className="ml-8 py-2 text-md justify-between" onClick={() => handleSearchSelection(state)}>{state.state}, {state.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
                           
//                             {postal && postal.length > 0 && (
//                                 <div>
//                                     <div className="header-wrapper mt-2">
//                                         <span className="header text-xl font-semibold ml-6 justify-between">Postal Code</span>
//                                     </div>
//                                     {postal.map((postalCode, index) => (
//                                         <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
//                                             <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(postalCode)}> {postalCode.postal_code}, {postalCode.state_code}</button>
//                                         </li>
//                                     ))}
//                                 </div>
//                             )}
//                         </ul>
//                     </div>
//                 )}

               
//                 {/* <button
//                     type="button"
//                     className="hidden lg:block text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-800 font-sm rounded-lg text-sm px-4 py-1"
//                     onClick={handleSearchSubmit}
//                 >
//                     Search
//                 </button> */}
//             </div>
//         </div>
//     );
// };

// export default Search;




import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchResults } from '../../Action/searchaction';
import { fetchProperties, updatePageNumber } from '../../Action/propertyAction';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';

const Search = () => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { states, cities, streets, postal, addresses } = useSelector(state => state.search); 


    // useEffect(() => {
    //     window.addEventListener('click', handleClickOutside);
    //     const storedSearchQuery = localStorage.getItem('searchQuery');
    //     if (storedSearchQuery) {
    //         setSearchQuery(storedSearchQuery);
    //     }
    //     return () => {
    //         window.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
    
        window.addEventListener('click', handleClickOutside);
    
        const storedSearchQuery = localStorage.getItem('searchQuery');
        if (storedSearchQuery) {
            setSearchQuery(storedSearchQuery);
        } else {
            const defaultSearchQuery = 'New York, NY';
            const defaultsearchValue = 'NY';
            const defaultareaType = 'state';
            const defaultsearchType = 'address';
    
            setSearchQuery(defaultSearchQuery);
            localStorage.setItem('searchQuery', defaultSearchQuery);
            localStorage.setItem('search', defaultsearchValue);
            localStorage.setItem('areaType', defaultareaType);
            localStorage.setItem('searchType', defaultsearchType);
    
            console.log('Default values set in local storage:');
            console.log('searchQuery:', localStorage.getItem('searchQuery'));
            console.log('search:', localStorage.getItem('search'));
            console.log('areaType:', localStorage.getItem('areaType'));
            console.log('searchType:', localStorage.getItem('searchType'));
    
            dispatch(fetchProperties(defaultsearchValue, defaultareaType, defaultsearchType, 0))
            .catch(error => {
                console.error("Error dispatching fetchProperties:", error);
            });
    }
    
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [dispatch]);
    
    
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    const handleSearchInputChange = (e) => {
        const inputValue = e.target.value;
        const nonSpaceChars = inputValue.replace(/\s/g, ''); 
        setSearchQuery(inputValue); 
        if (nonSpaceChars.length >= 4) { 
            dispatch(fetchSearchResults(inputValue)); 
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
            clearLocalStorage();
        }
    };

    const clearLocalStorage = () => {
        Object.keys(localStorage)
            .filter(key => key.startsWith('properties_page_'))
            .forEach(key => localStorage.removeItem(key));
    
            // localStorage.removeItem('properties_page_0');
        localStorage.removeItem('currentPageNumber')
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.trim() !== '') { 
            dispatch(fetchSearchResults(searchQuery)); 
        } else {
        }
    };
    
    const handleSearchSelection = (selectedItem) => {
        let searchValue = '';
        let displayValue = '';
        let areaType = ''; 
        let searchType = 'address';  
    
        if (selectedItem.area_type === 'street') {
            searchValue = selectedItem.street;
            displayValue = `${selectedItem.street}, ${selectedItem.state_code}`;
            areaType = 'street';
        } else if (selectedItem.area_type === 'city') {
            searchValue = selectedItem.city;
            displayValue = `${selectedItem.city}, ${selectedItem.state_code}`;
            areaType = 'city';
        } else if (selectedItem.area_type === 'state') {
            searchValue = selectedItem.state_code;
            displayValue = `${selectedItem.state}, ${selectedItem.state_code}`;
            areaType = 'state'; 
        } else if (selectedItem.area_type === 'postal_code') {
            searchValue = selectedItem.postal_code;
            displayValue = `${selectedItem.postal_code}, ${selectedItem.state_code}`;
            areaType = 'postal_code';
        }
    
        setSearchQuery(displayValue);
        setShowDropdown(false);
        dispatch(updatePageNumber(0));
    
        dispatch(fetchProperties(searchValue, areaType, searchType, 0));


        localStorage.setItem('areaType', areaType);
        localStorage.setItem('searchType', searchType);
        localStorage.setItem('searchQuery', displayValue);
        localStorage.setItem('search', searchValue);
        localStorage.setItem('currentPageNumber', 0);
        localStorage.removeItem('boundary')

    };
    
    
    
    
    // useEffect(() => {
    //     const storedSearchQuery = localStorage.getItem('search');
    //     dispatch(fetchProperties(storedSearchQuery));

    //     const handleClickOutside = (event) => {
    //         if (dropdownRef.current && !dropdownRef.current.contains(event.target))
    //             setShowDropdown(false);
    //     };
    
    //     window.addEventListener('click', handleClickOutside);
    //     return () => window.removeEventListener('click', handleClickOutside);
    // }, [dispatch]);
    
    const handleClearSearch = () => {
        setSearchQuery(''); // Clear input box
        setShowDropdown(false); // Hide dropdown
    };

    return (
        <div>
            <label htmlFor="default-search" className="sr-only">
                Search
            </label>
            <div className="relative flex-grow" ref={dropdownRef}>
                <div className="absolute inset-y-0 start-0 items-center ps-3 pointer-events-none hidden sm:flex">
                    <svg
                        className="w-4 h-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                    </svg>
                </div>
                <input
                    type="search"
                    id="default-search"
                    className="block w-full p-3.5 sm:pl-12 text-sm text-gray-900 border border-gray-600 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 placeholder-gray-400 rounded-l-none"
                    placeholder="Enter Text To Search...."
                    required
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    style={{ WebkitAppearance: 'none' }} 
                />
                {searchQuery && (
                    <div className='absolute 2xl:end-[10px] 2xl:bottom-3  xl:end-[10px] xl:bottom-3 lg:end-[10px] lg:bottom-3 end-2.5 bottom-3 cursor-pointer'>
                        <CloseIcon onClick={handleClearSearch} />
                    </div>
                )}
                {showDropdown && (
                    <div className="absolute mt-1 rounded-tl-none rounded-tr-none rounded-bl-2xl rounded-br-2xl w-full min-h-72 bg-white border border-black shadow-2xl z-10">
                        <ul
                            role="listbox"
                            aria-labelledby="downshift-2-label"
                            id="downshift-2-menu"
                            className="menu autocomplete-menu"
                        >

                           {addresses.length > 0 && (
                                <div>
                                    <div className="header-wrapper mt-2">
                                        <span className="header text-xl font-semibold ml-6 justify-between">Addresses</span>
                                    </div>
                                    {addresses.map((address, index) => (
                                        <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer truncate">
                                            <button className="ml-8 text-md justify-between py-2 truncate" onClick={() => handleSearchSelection(address)}>{address.area}</button>
                                        </li>
                                    ))}
                                </div>
                            )}
                            
                            {streets && streets.length > 0 && (
                                <div>
                                    <div className="header-wrapper mt-2">
                                        <span className="header text-xl font-semibold ml-6 justify-between">Street</span>
                                    </div>
                                    {streets.map((street, index) => (
                                        <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
                                            <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(street)}> {street.street}, {street.state_code}</button>
                                        </li>
                                    ))}
                                </div>
                            )}
                           
                           
                            {cities.length > 0 && (
                                <div>
                                    <div className="header-wrapper mt-2">
                                        <span className="header text-xl font-semibold ml-6 justify-between">Cities</span>
                                    </div>
                                    {cities.map((city, index) => (
                                        <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer ">
                                            <button className="ml-8  py-2 text-md justify-between " onClick={() => handleSearchSelection(city)}>{city.city}, {city.state_code}</button>
                                        </li>
                                    ))}
                                </div>
                            )}
                             {states.length > 0 && (
                                <div>
                                    <div className="header-wrapper mt-4">
                                        <span className="header text-xl font-semibold ml-6 justify-between ">States</span>
                                    </div>
                                    {states.map((state, index) => (
                                        <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer  ">
                                            <button className="ml-8 py-2 text-md justify-between" onClick={() => handleSearchSelection(state)}>{state.state}, {state.state_code}</button>
                                        </li>
                                    ))}
                                </div>
                            )}
                           
                            {postal && postal.length > 0 && (
                                <div>
                                    <div className="header-wrapper mt-2">
                                        <span className="header text-xl font-semibold ml-6 justify-between">Postal Code</span>
                                    </div>
                                    {postal.map((postalCode, index) => (
                                        <li key={index} role="option" aria-selected="false" className="item autocomplete-item hover:bg-gray-200 cursor-pointer">
                                            <button className="ml-8 text-md justify-between py-2" onClick={() => handleSearchSelection(postalCode)}> {postalCode.postal_code}, {postalCode.state_code}</button>
                                        </li>
                                    ))}
                                </div>
                            )}
                        </ul>
                    </div>
                )}

               
                {/* <button
                    type="button"
                    className="hidden lg:block text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-800 font-sm rounded-lg text-sm px-4 py-1"
                    onClick={handleSearchSubmit}
                >
                    Search
                </button> */}
            </div>
        </div>
    );
};

export default Search;

