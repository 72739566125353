// import axios from 'axios';

// export const SEARCH_QUERY = 'SEARCH_QUERY';

// export const setSearchResults = (searchValue, states, cities, streets, postal) => ({
//   type: SEARCH_QUERY,
//   payload: {
//     searchValue,
//     states,
//     cities,
//     streets,
//     postal,
//   }
// });

// export const fetchSearchResults = (value) => {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem('token');

//       if (!token) {
//         console.log('Token not found');
//         return;
//       }

//       const response = await axios.get(
//         `${API_URL}/property/v4/location/auto-complete?category=realtor&input=${value}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       const { states, cities, streets, postal_codes } = response.data.data;

//       dispatch(setSearchResults(value, states, cities, streets, postal_codes));
     
      
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };


import axios from 'axios';
import { API_URL } from '../../Config/config';

export const SEARCH_QUERY = 'SEARCH_QUERY';

export const setSearchResults = (searchValue, states, cities, streets, postal, addresses) => ({
  type: SEARCH_QUERY,
  payload: {
    searchValue,
    states,
    cities,
    streets,
    postal,
    addresses, 
  }
});

export const fetchSearchResults = (value) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        return;
      }

      const response = await axios.get(
        `${API_URL}/property/v4/location/auto-complete?category=realtor&input=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { states, cities, streets, postal_codes, addresses } = response.data.data;

      dispatch(setSearchResults(value, states, cities, streets, postal_codes, addresses)); 
     
    } catch (error) {
    }
  };
};