import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { API_URL, PROFILEPHOTO_URL } from "../../../../../Config/config";
import { CommentIcon, LikeIcon,LikedIcon} from "./icon";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { formatDate } from "../../Mdlgscreen/Utils/dateutil";
import Vids from './vids'
import VidsCommentModal from './vidscomment'
import VidsaComment from './vidsacomment';
import axios from 'axios';
import { calculateTimeAgo } from '../../Mdlgscreen/Utils/timeutils';
import CircularProgress from '@mui/material/CircularProgress';
import Vidsbottomsection from './vidsbottomsection';
import { useNavigate } from "react-router-dom";


const VidsModal = ({ videoID, onClose }) => {
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [vids, setVids] = useState(null);
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const actualVideoId = videoID || localStorage.getItem('vidsId');
    const [commentCount, setCommentCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVidsDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No access token found');
                    return;
                }

                const config = {
                    method: 'get',
                    url: `${API_URL}/vids/v2/details/${actualVideoId}`,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                };
                const response = await axios.request(config);

                const vidsData = Array.isArray(response.data.data) ? response.data.data[0] : response.data.data;
                setVids(vidsData);
                setCommentCount(vidsData.commentCount || 0);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching post details:", error);
                setLoading(false);
            }
        };

        if (actualVideoId) {
            fetchVidsDetails();
        } else {
            console.error('No vidsId found');
            setLoading(false);
        }
    }, [actualVideoId]);

    useEffect(() => {
        if (vids && vids.createdAt) {
            const formattedTime = calculateTimeAgo(vids.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [vids]);

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    const handleViewComments = () => {
        setShowCommentModal(true);
    };

    const handleCloseCommentModal = () => {
        setShowCommentModal(false);
    };

    const incrementCommentCount = () => {
        setCommentCount(commentCount + 1);
    };

    const decrementCommentCount = () => {
        setCommentCount(commentCount - 1);
    };
    if (loading) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white"><CircularProgress /></div>
            </div>
        );
    }

    if (!vids) {
        return (
            <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
                <div className="text-white">Error fetching post details.</div>
            </div>
        );
    }

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
      };

    return (
        <div className="fixed inset-0 z-50 flex flex-col bg-black/90  overflow-y-auto flex-grow">
                <div className="relative flex items-center justify-center mt-4 text-lg text-white w-full">
                    <div className="absolute left-4 cursor-pointer">
                        <ArrowBackIosIcon onClick={onClose} />
                    </div>
                    <div>Vids</div>
                </div>
                <div className="mt-4 border-b border-gray-500 w-full"></div>
        
                <div className="mt-4 ml-4 flex items-center flex-shrink-0">
                    <div className="w-10 h-10 ml-2 bg-white rounded-full overflow-hidden cursor-pointer" onClick={() => handleProfile(vids.user.userId)}>
                        <img src={`${PROFILEPHOTO_URL}/${vids.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full rounded-full" />
                    </div>
                    <div className="flex flex-col text-gray-400 ml-4">
                        <span className="text-md text-white cursor-pointer" onClick={() => handleProfile(vids.user.userId)} >{vids.user.firstName} {vids.user.lastName}</span>
                        <span className="text-xs text-gray-400">
                        {vids.location.length > 20 ? `${vids.location.slice(0, 20)}...` : vids.location}
                            </span>
                    </div>
                    {/* {vids.user.showFollowingStatus && (
                        <li className="text-white ml-4">
                            <button type="button" className="text-blue-500 text-md ml-0">Follow</button>
                        </li>
                    )} */}
                </div>

                <div>
                <Vids video={vids} />
             </div>
             <div>
            <Vidsbottomsection video={vids} handleViewComments={handleViewComments}  handleProfile={handleProfile} />
            </div>
                <div className="mx-3.5 mb-1 mt-2.5 text-white/80">
                    <div className="flex text-sm cursor-pointer" onClick={handleViewComments}>
                        View All {commentCount} Comments
                    </div>
                    <div className="flex items-center text-xs mx-1">
                        <div className="w-8 h-8 ml-0 mt-1 bg-white rounded-full overflow-hidden">
                            <img src={`${PROFILEPHOTO_URL}/${vids.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full rounded-full" />
                        </div>
                        <VidsaComment incrementCommentCount={incrementCommentCount} />
                    </div>
                </div>
                <div className="mx-3.5 mb-4 mt-2 text-xs text-white/80">{formatDate(vids.createdAt)}</div>
           
            {showCommentModal && (
                <VidsCommentModal video={vids} onClose={handleCloseCommentModal} incrementCommentCount={incrementCommentCount} decrementCommentCount={decrementCommentCount} handleProfile={handleProfile} />
            )}
        </div>
    );
};

export default VidsModal;
