// import { SEARCH_QUERY } from '../Action/searchaction';

// const initialState = {
//   searchQuery: '',
//   states: [],
//   cities: [],
//   streets: [],
//   postal: [],
// };

// const searchReducer = (state = initialState, action) => {
//   switch(action.type) {
//     case SEARCH_QUERY:
//       return {
//         ...state,
//         searchQuery: action.payload.searchValue,
//         states: action.payload.states,
//         cities: action.payload.cities,
//         streets: action.payload.streets, 
//         postal: action.payload.postal, 
//       };
//     default:
//       return state;
//   }
// };

// export default searchReducer;


import { SEARCH_QUERY } from '../Action/searchaction';

const initialState = {
  searchQuery: '',
  states: [],
  cities: [],
  streets: [],
  postal: [],
  addresses: [], 
};

const searchReducer = (state = initialState, action) => {
  switch(action.type) {
    case SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload.searchValue,
        states: action.payload.states,
        cities: action.payload.cities,
        streets: action.payload.streets, 
        postal: action.payload.postal, 
        addresses: action.payload.addresses, 
      };
    default:
      return state;
  }
};

export default searchReducer;
