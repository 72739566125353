import React from "react";
import smartGearImage from '../../../assets/images/SMARTGEARGREY1.png';
import insta from '../../../assets/images/insta.png'
import facebook from '../../../assets/images/facebook.png'
import linkedin from '../../../assets/images/linkedin.png'
import twitter from '../../../assets/images/twitter.png'

const smartIcon = require("../../../assets/images/SMARTGREY.png");
const appStore = require("../../../assets/images/applePlay.png");
const playStore = require("../../../assets/images/googleStore.png");

const Footer = () => {
  
  return (
   
<footer className=" bg-blue-900 ">
    <div className="mx-auto w-full  p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 mr-12 md:mb-0">
              <a href="#" className="flex items-center">
                  <img src={smartGearImage} className="h-9" alt="Smart Logo" />
                  <img src={smartIcon} className="h-10" alt="Smart Logo" />
              </a>
          </div>
          <div className="grid  gap-8 sm:gap-4 sm:grid-cols-2">
          
              <div>
                  <h2 className="mb-6 text-sm font-semibold  uppercase text-white">Legal</h2>
                  <ul className="text-gray-400  font-medium">
                      <li className="mb-4">
                          <a href="#" className="hover:underline">Privacy Policy</a>
                      </li>
                      <li>
                          <a href="#" className="hover:underline">Terms &amp; Conditions</a>
                      </li>
                  </ul>
              </div>
   
              <div>
                  <h2 className="mb-6 text-sm font-semibold   uppercase text-white">Get the app</h2>
                  <div className="flex">
                     
                      <a href="https://apps.apple.com/us/app/smart-app/id1521331431"  target="_blank" rel="noopener noreferrer">
          <img  src={appStore} alt="mobilePhone" height={130}  width={130}/>
          </a>
                   
                      <a href="https://play.google.com/store/apps/details?id=com.realityapp" target="_blank" rel="noopener noreferrer" className=" ms-2" >
          <img   src={playStore} alt="mobilePhone" height={130} width={130} />
          </a>
                    
                  </div>
              </div>
          </div>
      </div>
      <hr className="my-4 border-gray-500 sm:mx-auto " />
      <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-200 sm:text-center ">Copyright © 2022-2024 SMART™ | All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a href="#" className="text-gray-500 hover:text-gray-900">
              <img src={facebook} alt="facebook" width={25} height={25}/>
                  <span className="sr-only">Facebook page</span>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900  ms-2">
                <img src={insta} alt="instagram" width={25} height={25}/>
                  <span className="sr-only">Instagram page</span>
              </a>
              <a href="#" className="text-gray-500 hover:text-gray-900  ms-2">
              <img src={linkedin} alt="linkedin" width={25} height={25}/>
                  <span className="sr-only">Linkedin page</span>
              </a>
              
              <a href="#" className="text-gray-500 hover:text-gray-900 ms-2 ">
              <img src={twitter} alt="twitter" width={25} height={25}/>
                  <span className="sr-only">Twitter page</span>
              </a>
             
          </div>
      </div>
    </div>
</footer>

  );
};

export default Footer;



  