import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { API_URL } from '../../../../../Config/config';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const Vidsacomment = ({ incrementCommentCount }) => {

  const [content, setContent] = useState('');
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');



  const handleCommentChange = (e) => {
    setContent(e.target.value);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (emojiObject) => {
    const newContent = (content || '') + emojiObject.emoji;
    setContent(newContent);
    setChosenEmoji(emojiObject);
  };

  const postComment = async () => {
    const token = localStorage.getItem('token');
    const vidsId = localStorage.getItem('vidsId');
    
    if (!token || !vidsId) {
        return;
    }

    const data = JSON.stringify({ content });


    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/vids/v2/comment/${vidsId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data,
    };

    setLoading(true);
    setError('');

    try {
        const response = await axios.request(config);
     
        setContent('');
        setChosenEmoji(null);
        setLoading(false);
        incrementCommentCount();
        setShowEmojiPicker(false);
    } catch (error) {
        setError('Failed to post comment. Please try again.');

        setLoading(false);
    }
};

  return (
    <div className='   h-18'>
    <div className='  mt-1 flex items-center '>
      
      <input
        className="bg-black/80 text-white w-full p-2 focus:outline-none"
        style={{ caretColor: 'gray' }}
        placeholder={'Add a comment...'}
        value={content}
        onChange={handleCommentChange}
        onFocus={() => setShowEmojiPicker(false)}
      />
{chosenEmoji ? (
        <span className="ml-2 cursor-pointer" onClick={toggleEmojiPicker}>{chosenEmoji.emoji}</span>
      ) : (
        <span className="ml-2 cursor-pointer " onClick={toggleEmojiPicker}>😊</span>
      )}
      {showEmojiPicker && (
        <div className="absolute bottom-[118px] ml-0 mb-10 z-10">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      <div className="ml-0">
        
          <button
            className={` text-white py-1 px-3 mr-2 rounded-xl ${!content.trim() && 'opacity-50 cursor-not-allowed'}`}
            onClick={postComment}
            disabled={!content.trim()}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} className='text-white' />
            ) : 'Post'}
          </button>
       
      </div>
    </div>
    </div>
  );
};

export default Vidsacomment;
