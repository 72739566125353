import axios from 'axios';
import { API_URL } from '../../Config/config';

export const fetchData = async (propertyId) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      
      return null;
    }

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/property/v4/detail?property_id=${propertyId}&category=realtor`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    const response = await axios.request(config);
  
    return response.data;
  } catch (error) {
    throw error;
  }
};
