import axios from 'axios';
import { API_URL } from '../../../Config/config';


export const FETCH_USERPROFILE_REQUEST = 'FETCH_USERPROFILE_REQUEST';
export const FETCH_USERPROFILE_SUCCESS = 'FETCH_USERPROFILE_SUCCESS';
export const FETCH_USERPROFILE_FAILURE = 'FETCH_USERPROFILE_FAILURE';

export const fetchUserProfileRequest = () => ({
  type: FETCH_USERPROFILE_REQUEST,
});

export const fetchUserProfileSuccess = (userProfile) => ({
  type: FETCH_USERPROFILE_SUCCESS,
  payload: userProfile,
});

export const fetchUserProfileFailure = (error) => ({
  type: FETCH_USERPROFILE_FAILURE,
  payload: error,
});

export const fetchUserData = (userid) => {
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest());
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_URL}/profile/v2/view?userId=${userid}&page_number=0`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.request(config);
      if (response.data && response.data.status) {
        dispatch(fetchUserProfileSuccess(response.data.data));
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      dispatch(fetchUserProfileFailure(error.message));
    }
  };
};
