import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import MdxlSidebar from './mdxlsidebar';
import SmSidebar from './smsidebar';
import { fetchUser, fetchUserSuccess } from '../../Action/smartsocial/useraction';

const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user); 
  const loading = useSelector((state) => state.user.loading); 
  const error = useSelector((state) => state.user.error); 

  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });

  // useEffect(() => {
  //   dispatch(fetchUser());
  // }, [dispatch]);

  useEffect(() => {
    
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      dispatch(fetchUserSuccess(storedUser)); 
    } else {
      dispatch(fetchUser()); 
    }
  }, [dispatch]);

  // if (loading) return <div>Loading...</div>; 
  // if (error) return <div>Error: {error}</div>; 

  return (
    <div>
      {is2XL ? <MdxlSidebar user={user} loading={loading} error={error} /> : 
      (isXL ? <MdxlSidebar user={user} loading={loading} error={error}/> : 
      (isLG ? <MdxlSidebar user={user} loading={loading} error={error} /> : 
      (isMD ? <MdxlSidebar user={user} loading={loading} error={error} /> : 
      (isSM ? <SmSidebar user={user} loading={loading} error={error} /> : <SmSidebar user={user}  loading={loading} error={error}/>))))}
    </div>
  );
};

export default Sidebar;
