import React from 'react';


const Audio = () => {
  return (
    <div>
   <div className='text-white items-center justify-center flex text-2xl mt-14'>
    Create a room to chat 
   
   </div>
    <div className='text-white flex items-center justify-center mt-4'>
   {/* <button className='bg-blue-600 py-2 px-6 rounded-xl'>
    Start A Room 
   </button> */}
  </div>
  </div>
  );
};

export default Audio;
