
import React from "react";

export const CommentIcon = () => (
    <svg width="25" height="25" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 38C24.7578 38 28.4313 36.9443 31.5558 34.9665C34.6804 32.9886 37.1156 30.1774 38.5537 26.8883C39.9918 23.5992 40.368 19.98 39.6349 16.4884C38.9018 12.9967 37.0922 9.78943 34.435 7.27208C31.7778 4.75474 28.3923 3.04041 24.7067 2.34587C21.0211 1.65134 17.2008 2.0078 13.729 3.37018C10.2572 4.73255 7.28982 7.03966 5.20208 9.99974C3.11433 12.9598 2 16.4399 2 20C2 22.976 2.76 25.78 4.11111 28.254L2 38L12.2874 36C14.8968 37.278 17.8608 38 21 38Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
)


export const ShareIcon = () => (
    <svg width="25" height="25" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 2L2 13.6471L16.5294 20L32.1765 9.41177L21 24.2353L27.7059 38L40 2Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);


export const LikeIcon = () => (
    <svg width="26" height="26" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.6518 0C25.8721 0 22.5628 1.66539 20.5 4.48043C18.4372 1.66539 15.1279 0 11.3482 0C8.33952 0.0034747 5.45503 1.22965 3.32756 3.40952C1.20009 5.5894 0.00339117 8.54494 0 11.6277C0 24.7558 18.9973 35.3821 19.8063 35.8209C20.0195 35.9385 20.2579 36 20.5 36C20.7421 36 20.9805 35.9385 21.1937 35.8209C22.0027 35.3821 41 24.7558 41 11.6277C40.9966 8.54494 39.7999 5.5894 37.6724 3.40952C35.545 1.22965 32.6605 0.0034747 29.6518 0ZM20.5 32.7827C17.1578 30.7873 2.92857 21.697 2.92857 11.6277C2.93148 9.34062 3.81948 7.14803 5.39784 5.5308C6.9762 3.91356 9.11608 3.00368 11.3482 3.00071C14.9083 3.00071 17.8972 4.94367 19.1455 8.0644C19.2559 8.33958 19.4435 8.57495 19.6847 8.74059C19.9259 8.90623 20.2097 8.99467 20.5 8.99467C20.7903 8.99467 21.0741 8.90623 21.3153 8.74059C21.5565 8.57495 21.7441 8.33958 21.8545 8.0644C23.1028 4.93804 26.0917 3.00071 29.6518 3.00071C31.8839 3.00368 34.0238 3.91356 35.6022 5.5308C37.1805 7.14803 38.0685 9.34062 38.0714 11.6277C38.0714 21.682 23.8386 30.7854 20.5 32.7827Z" fill="white"/>
</svg>

);

export const LikedIcon = () => (
    <svg width="26" height="26" viewBox="0 0 252 193" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M235.694 23.8453C208.998 -6.24247 161.771 -7.66394 133.136 19.5335L133.037 19.6283C129.06 23.4188 122.845 23.4188 118.918 19.6283L118.819 19.5335C91.6754 -6.432 47.58 -6.52676 20.3372 19.344C-2.82902 41.282 -6.65692 76.7239 11.19 102.832C16.7579 111.029 23.7674 117.331 31.5227 122.116L121.006 191.152C124.188 193.616 128.761 193.616 131.993 191.152L220.879 122.021C228.336 117.283 235.097 111.123 240.516 103.305C257.468 78.9982 255.48 46.1149 235.694 23.8453Z" fill="#FF473E"/>
    </svg>
    )

