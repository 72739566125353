import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProperties, updatePageNumber } from '../../Action/propertyAction';
import { fetchDropdownData } from './Filter/Fliterapi';
import ArrowIcon from './ArrowIcon';

const Listing = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        localStorage.getItem('prop_status') === 'for_rent' ? 'Rental' : 'Listing'
    );
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
  
    const handleOptionClick = async (option) => {
        if (selectedOption !== option) {
            setSelectedOption(option);
            clearLocalStorage();
            localStorage.setItem('prop_status', option === 'Rental' ? 'for_rent' : 'for_sale');
            dispatch(updatePageNumber(0));  
            dispatch(fetchProperties('', '', 0, ''));
            localStorage.setItem('currentPageNumber',0); 
   
        }
        setIsOpen(false);
    };
    const clearLocalStorage = () => {
        Object.keys(localStorage)
            .filter(key => key.startsWith('properties_page_'))
            .forEach(key => localStorage.removeItem(key));
    
            localStorage.removeItem('filter_by_price');
    // localStorage.removeItem('filter_by_livingarea');
    // localStorage.removeItem('filter_by_lotsize');
    // localStorage.removeItem('filter_by_bedroom');
    // localStorage.removeItem('filter_by_bathroom');
    // localStorage.removeItem('filter_proptype');
    

        // localStorage.removeItem('properties_page_0');
        // localStorage.removeItem('currentPageNumber')
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
      handleOptionClick(selectedOption);
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
           
            <button id="dropdownDefaultButton" onClick={toggleDropdown} type="button"
  className="relative  h-[50px] isolation-auto z-10 border border-gray-600 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full hover:text-white before:-right-full before:hover:right-0 before:rounded-full before:bg-[#233aa1] before:-z-10 before:aspect-square before:hover:scale-150 overflow-hidden before:hover:duration-700 inline-flex items-center justify-center px-4 py-3 text-md font-semibold text-black bg-white  rounded-lg shadow-sm  hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none rounded-r-none"
>
{selectedOption}
                <ArrowIcon isOpen={isOpen} /> 
</button>
            {isOpen && (
                <div className="absolute left-0 mt-2 bg-white border-2 h-36 border-gray-600 shadow-xl rounded-xl w-40 z-10">
                    <ul className="py-4 text-lg text-gray-700 " aria-labelledby="dropdown-button">
                        <li>
                            <button type="button" className={`inline-flex w-full px-2 py-2 justify-center hover:bg-gray-100 hover:font-bold text-black text-lg font-regular ${selectedOption === 'Listing' ? 'bg-gray-100' : ''}`} onClick={() => handleOptionClick('Listing')}>Listing</button>
                        </li>
                        <li>
                            <button type="button" className={`inline-flex w-full px-2 py-2 mt-4 justify-center hover:bg-gray-100 hover:font-bold text-black  text-lg font-regular ${selectedOption === 'Rental' ? 'bg-gray-100' : ''}`} onClick={() => handleOptionClick('Rental')}>Rental</button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Listing;
