import React from "react";
import { connect } from "react-redux";
import { changeView } from "../../Action/viewaction";

const View = ({ real_estate_view, changeView }) => {

  const handleViewChange = (view) => {
    changeView(view);
  };

  return (
    <div className="mt-4 bg-gray-300 h-12 rounded-full flex items-center justify-center">
      <button
        type="button"
        className={`text-gray-900   ${real_estate_view === "list" ? "bg-white border border-gray-900" : ""
          }  font-medium rounded-full  text-lg px-6  py-2 ml-1 me-1`}
        onClick={() => handleViewChange("list")}
      >
        List
      </button>

      <button
        type="button"
        className={`text-gray-900 ${real_estate_view === "map" ? "bg-white border border-gray-900" : ""
          }  font-medium rounded-full  text-lg px-6  py-2 mr-1`}
        onClick={() => handleViewChange("map")}
      >
        Map
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  real_estate_view: state.view.real_estate_view,
});


const mapDispatchToProps = {
  changeView,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
