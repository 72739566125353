import React, { useEffect, useState } from "react";
import LABELS from "../../Label/Login.json";
import { useDispatch } from 'react-redux';
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Apis from "../../Services/Apis/api";
import PhoneInput from "react-phone-number-input";
import OtpVerify from "../OtpVerify";
import { useNavigate } from "react-router-dom";
import "./index.style.css";
import smartGearImage from '../../assets/images/SMARTGEARGREY1.png';
import BottomNavBar from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { setUser } from '../Action/user';
import smartIcon from '../../assets/images/SMART-registered-blue.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const smartIcon = require("../../assets/icons/SMART.png");
  const smartIcons = require("../../assets/images/SMARTGREY.png");
  const [phoneNumber, setPhoneNumber] = useState();
  const [Otp, setOtpInput] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [encryptedOtp, setencryptedOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(30);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true);
    const params = {
      country_code: phoneNumber.split("").slice(0, 3).join(""),
      mobile_number: phoneNumber.split("").slice(3).join(""),
      hashcode: "rFRYl0X8LT+",
      os: "web",
    };
    try {
      const response = await Apis.auth.loginWithPhone(params);
      setencryptedOtp(response.data?.otp);
      setShowOtp(true);
      toast.success(
        `OTP has been sent to your mobile number ${phoneNumber
          .split("")
          .slice(3)
          .join("")}`
      );
      setResendDisabled(false);
      startResendTimer();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const startResendTimer = () => {
    setResendDisabled(true);
    const interval = setInterval(() => {
      setResendTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setResendDisabled(false);
        }
        return prevTimer === 0 ? 0 : prevTimer - 1;
      });
    },1000);
  }

  const verifyOtp = async (otpValue) => {
    try {
      const newParam = {
        country_code: phoneNumber.split("").slice(0, 3).join(""),
        mobile_number: phoneNumber?.split("")?.slice(3)?.join(""),
        otp: otpValue,
        encrypted_otp: encryptedOtp,
        device_token: encryptedOtp,
      };
      const response = await Apis.auth.verifyOtp(newParam);
      if (response?.data?.status) {
        dispatch(setUser(response.data.data));
        localStorage.setItem("userData", JSON.stringify(response.data?.data));
        localStorage.setItem("token", response?.data?.token);
        navigate("/", {
          state: {
            userData: response?.data?.data,
            token: response?.data?.token,
          },
          
        });
        // const redirectTo = location.state?.from?.pathname || "/realestate";
        // navigate(redirectTo);
      } else {
        throw new Error("Invalid OTP");
      }
    } catch (error) {
      toast.error("Invalid OTP. Please try again.");
    }
  };

  const handleResendClick = () => {
    setResendDisabled(true);
    setResendTimer(30);
    startResendTimer();
    handleButtonClick();
  }
  useEffect(() => {
    if (resendTimer === 0) {
      setResendDisabled(false);
    }
  },[resendTimer])

  return (
    <div className="flex flex-col bg-home-background mt-36 bg-cover">
    <div className="bg-company-blue/[.25]">
        <div className="">
   
          <div className="flex-grow flex flex-col justify-center items-center">
        <div className="max-w-screen-xl flex flex-wrap items-end justify-between mx-auto p-4 relative z-10">
      <div className="bg-white p-8 shadow-md rounded-lg ">
        <img
          src={smartIcon}
          alt="Logo"
          style={{ width: "350px", height: "55px" }}
        />
        <div className="items-center justify-center mt-6 ">
          <p className="text-base  items-center justify-center">
            {!showOtp ? LABELS.LOGIN : LABELS.ENTER_OTP_VERIFY}
          </p>
        </div>
        <div>
          {!showOtp && (
            <div className="flex flex-row items-center border-2 my-5">
              <PhoneInput
                className="p-2 phoneInput"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                defaultCountry="US" 
                      international
              />
            </div>
          )}

          {showOtp && (
            <div className="flex flex-row items-center my-5 ">
              <OtpVerify
                setOtp={(otp) => {
                  setOtpInput(otp);
                  if (otp.length === 4) {
                    verifyOtp(otp);
                  }
                }}
                otp={Otp}
              />
            </div>
          )}
          <p>{LABELS.ERROR_VALID_PHONE_NUMBER}</p>
        </div>
        {!showOtp && (
          // <div className="flex justify-center">
          <button
            disabled={!phoneNumber || isLoading}
            type="button"
            onClick={handleButtonClick}
            className="bg-blue-900  hover:bg-company-blue  text-white font-bold py-2 px-4 rounded my-5 cursor-pointer"
          >
            {isLoading ? 'Loading...' : LABELS.LOGINBTN}
          </button>
          // </div>
        )}
        {showOtp && (
          <button
          disabled={resendDisabled}
          onClick={handleResendClick}
          className={ `text-company-blue text-sm cursor-pointer ${
            !resendDisabled && "hover:text-blue-700"
          }`}
          >
           {resendTimer !== 0 ? `Resend code  (${resendTimer}s)` : "Resend Code"}

          </button>
        )}
      </div>

      <ToastContainer   
      autoClose={1000}
        position="bottom-center" 
        closeButton={false} 
        closeOnClick
        toastStyle={{
          background: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          zIndex: "9999",
        }}/>
    </div>
    
    </div>
            
  
         
          </div>
      </div>
    </div>
  );
};

export default Login;

