import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageNumber, fetchProperties, fetchFavorites } from '../../Action/propertyAction';

const Pagination = () => {
  const dispatch = useDispatch();
  const totalProperties = useSelector(state => state.properties.totalProperties);
  const countProperties = useSelector(state => state.properties.countProperties);
  const pageNumber = useSelector(state => state.properties.pageNumber);
  const totalPages = Math.ceil(totalProperties / 24);

  const handlePreviousClick = () => {
    if (pageNumber > 0) {
      const prevPageNumber = pageNumber - 1;
      dispatch(updatePageNumber(prevPageNumber));
      dispatch(fetchProperties('', '','', prevPageNumber));
      // dispatch(fetchFavorites(prevPageNumber)); 
    }
  };
  
  const handleNextClick = () => {
    if (pageNumber + 1 < totalPages) {
      const nextPageNumber = pageNumber + 1;
      dispatch(updatePageNumber(nextPageNumber));
      dispatch(fetchProperties('', '','', nextPageNumber));
      // dispatch(fetchFavorites(nextPageNumber)); 
    }
  };
  

  useEffect(() => {
    const storedPageNumber = localStorage.getItem('currentPageNumber');
    if (storedPageNumber) {
      dispatch(updatePageNumber(parseInt(storedPageNumber)));
      dispatch(fetchProperties('', '','', parseInt(storedPageNumber)));
    }
  }, [dispatch, fetchProperties, updatePageNumber]);
  

  useEffect(() => {
    localStorage.setItem('currentPageNumber', pageNumber);
    window.scrollTo(0, 0);
  }, [pageNumber]);

  return (
    <div>
      <nav aria-label="Page navigation example" className="mt-auto flex justify-center  mb-8">
        <ul className="inline-flex -space-x-px text-sm">
          <li>
            <button
              onClick={handlePreviousClick}
              className={`flex items-center justify- px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg ${pageNumber === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 hover:text-gray-700'
                }`}
              disabled={pageNumber === 0}
            >
              Previous
            </button>
          </li>
          <p className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300">
            {pageNumber + 1} of {totalPages}
          </p>
          <li>
            <button
              onClick={handleNextClick}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;