import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import { useParams } from 'react-router-dom'; 
import { API_URL, PROFILEPHOTO_URL } from '../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer, Slide } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';

const Userfollowersfollowing = ({ activeTab, onClose }) => {
    const [value, setValue] = useState(activeTab);
    const [followers, setFollowers] = useState([]);
    const [followerscount, setFollowerscount] = useState(0);
    const [followings, setFollowings] = useState([]);
    const [followingscount, setFollowingscount] = useState(0);
    const [loadingFollowers, setLoadingFollowers] = useState(false);
    const [loadingFollowings, setLoadingFollowings] = useState(false);
    const [loadingMoreFollowers, setLoadingMoreFollowers] = useState(false);
    const [loadingMoreFollowings, setLoadingMoreFollowings] = useState(false);
    const [followersPage, setFollowersPage] = useState(0);
    const [followingsPage, setFollowingsPage] = useState(0);
    const [hasMoreFollowers, setHasMoreFollowers] = useState(true);
    const [hasMoreFollowings, setHasMoreFollowings] = useState(true);
    const followersEndRef = useRef(null);
    const followingsEndRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const { userid } = useParams(); 
    const token = localStorage.getItem('token'); 
    const navigate = useNavigate();

    const fetchFollowers = async (page, isLoadMore = false) => {
        if (isLoadMore) setLoadingMoreFollowers(true);
        else setLoadingFollowers(true); 
        try {
            const response = await axios.get(`${API_URL}/profile/v2/followers?user_id=${userid}&page_number=${page}&per_page=100`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const fetchedFollowers = response.data.data.followers || [];
            console.log('followe',fetchedFollowers)
            setFollowerscount(response.data.data.follower_count);
            setFollowers((prev) => [...prev, ...fetchedFollowers]);
    
            if (fetchedFollowers.length === 0 || fetchedFollowers.length < 10) {
                setHasMoreFollowers(false);
            }
        } catch (error) {
            console.error('Error fetching followers:', error);
        } finally {
            setLoadingFollowers(false); 
            setLoadingMoreFollowers(false);
        }
    };
    
    const fetchFollowings = async (page, isLoadMore = false) => {
        if (isLoadMore) setLoadingMoreFollowings(true);
        else setLoadingFollowings(true); 
        try {
            const response = await axios.get(`${API_URL}/profile/v2/followings?user_id=${userid}&page_number=${page}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const fetchedFollowings = response.data.data.followings || [];
            console.log('following',fetchedFollowings)
            setFollowingscount(response.data.data.following_count);
            setFollowings((prev) => [...prev, ...fetchedFollowings]);
    
            if (fetchedFollowings.length === 0 || fetchedFollowings.length < 10) {
                setHasMoreFollowings(false);
            }
        } catch (error) {
            console.error('Error fetching followings:', error);
        } finally {
            setLoadingFollowings(false);
            setLoadingMoreFollowings(false); 
        }
    };
    

    useEffect(() => {
        fetchFollowers(followersPage);
        fetchFollowings(followingsPage);
    }, [userid, token]);

    // useEffect(() => {
    //     const options = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 1.0
    //     };

    //     const observer = new IntersectionObserver(([entry]) => {
    //         if (entry.isIntersecting) {
    //             if (value === '1' && hasMoreFollowers && !loadingMoreFollowers) {
    //                 setFollowersPage((prev) => prev + 1);
    //                 fetchFollowers(followersPage + 1, true);  
    //             }
    //             if (value === '2' && hasMoreFollowings && !loadingMoreFollowings) {
    //                 setFollowingsPage((prev) => prev + 1);
    //                 fetchFollowings(followingsPage + 1, true); 
    //             }
    //         }
    //     }, options);

    //     if (followersEndRef.current) {
    //         observer.observe(followersEndRef.current);
    //     }

    //     if (followingsEndRef.current) {
    //         observer.observe(followingsEndRef.current);
    //     }

    //     return () => {
    //         if (followersEndRef.current) {
    //             observer.unobserve(followersEndRef.current);
    //         }

    //         if (followingsEndRef.current) {
    //             observer.unobserve(followingsEndRef.current);
    //         }
    //     };
    // }, [value, followersPage, followingsPage, hasMoreFollowers, hasMoreFollowings, loadingMoreFollowers, loadingMoreFollowings]);

    // useEffect(() => {
    //     const options = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 1.0,
    //     };
    
    //     const observerCallback = ([entry]) => {
    //         if (entry.isIntersecting) {
    //             if (value === '1' && hasMoreFollowers && !loadingMoreFollowers) {
    //                 setFollowersPage((prev) => prev + 1);
    //                 fetchFollowers(followersPage + 1, true);
    //             }
    //             if (value === '2' && hasMoreFollowings && !loadingMoreFollowings) {
    //                 setFollowingsPage((prev) => prev + 1);
    //                 fetchFollowings(followingsPage + 1, true);
    //             }
    //         }
    //     };
    
    //     const observer = new IntersectionObserver(observerCallback, options);
    
    //     const currentFollowersEndRef = followersEndRef.current;
    //     const currentFollowingsEndRef = followingsEndRef.current;
    
    //     if (value === '1' && currentFollowersEndRef) {
    //         observer.observe(currentFollowersEndRef);
    //     }
    
    //     if (value === '2' && currentFollowingsEndRef) {
    //         observer.observe(currentFollowingsEndRef);
    //     }
    
    //     return () => {
    //         if (currentFollowersEndRef) {
    //             observer.unobserve(currentFollowersEndRef);
    //         }
    //         if (currentFollowingsEndRef) {
    //             observer.unobserve(currentFollowingsEndRef);
    //         }
    //     };
    // }, [value, followersPage, followingsPage, hasMoreFollowers, hasMoreFollowings, loadingMoreFollowers, loadingMoreFollowings]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };
    
        const observerCallback = ([entry]) => {
            if (entry.isIntersecting) {
                if (value === '1') { 
                    if (hasMoreFollowers && !loadingMoreFollowers) {
                        setFollowersPage((prev) => prev + 1);
                        fetchFollowers(followersPage + 1, true);
                    } else if (!hasMoreFollowers) {
                        toast.info('No More Followers to load', {
                            position: 'top-right',
                             autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            transition: "Slide",
                            closeButton: false,
                            transition: Slide, 
                            theme: "colored",
                        });
                    }
                } else if (value === '2') { 
                    if (hasMoreFollowings && !loadingMoreFollowings) {
                        setFollowingsPage((prev) => prev + 1);
                        fetchFollowings(followingsPage + 1, true);
                    } else if (!hasMoreFollowings) {
                        toast.info('No More Followings to load', {
                            position: 'top-right',
                             autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            closeButton: false,
                            transition: Slide, 
                            theme: "colored",
                        });
                    }
                }
            }
        };
    
        const observer = new IntersectionObserver(observerCallback, options);
    
        const currentFollowersEndRef = followersEndRef.current;
        const currentFollowingsEndRef = followingsEndRef.current;
    
        if (value === '1' && currentFollowersEndRef) {
            observer.observe(currentFollowersEndRef);
        }
    
        if (value === '2' && currentFollowingsEndRef) {
            observer.observe(currentFollowingsEndRef);
        }
    
        return () => {
            if (currentFollowersEndRef) {
                observer.unobserve(currentFollowersEndRef);
            }
            if (currentFollowingsEndRef) {
                observer.unobserve(currentFollowingsEndRef);
            }
        };
    }, [value, followersPage, followingsPage, hasMoreFollowers, hasMoreFollowings, loadingMoreFollowers, loadingMoreFollowings]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleProfile = (userId) => {
        navigate(`/smartsocial/${userId}/`);
        onClose();
      };

      const handleFollow = async (userId, type) => {
        setButtonDisabled(true);


        try {
            const response = await axios.post(`${API_URL}/profile/v2/follow/${userId}`, {}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Followed:', response.data);
    
            if (type === 'follower') {
                setFollowers((prevFollowers) =>
                    prevFollowers.map((follower) =>
                        follower.follower_id === userId ? { ...follower, is_user_following: true } : follower
                    )
                );
            } else if (type === 'following') {
                setFollowings((prevFollowings) =>
                    prevFollowings.map((following) =>
                        following.following_id === userId ? { ...following, is_user_following: true } : following
                    )
                );
            }
        } catch (error) {
            console.error('Error following user:', error);
        } finally {
            setTimeout(() => setButtonDisabled(false), 2000); 
        }
    };
    
    const handleUnfollow = async (userId, type) => {
        setButtonDisabled(true);

        try {
            const response = await axios.post(`${API_URL}/profile/v2/unfollow/${userId}`, {}, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Unfollowed:', response.data);
    
            if (type === 'follower') {
                setFollowers((prevFollowers) =>
                    prevFollowers.map((follower) =>
                        follower.follower_id === userId ? { ...follower, is_user_following: false } : follower
                    )
                );
            } else if (type === 'following') {
                setFollowings((prevFollowings) =>
                    prevFollowings.map((following) =>
                        following.following_id === userId ? { ...following, is_user_following: false } : following
                    )
                );
            }
        } catch (error) {
            console.error('Error unfollowing user:', error);
        } finally {
            setTimeout(() => setButtonDisabled(false), 9000);
        }
    };
    
    

    useEffect(() => {
        document.body.style.overflow = 'hidden'; 
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center mt-6">
                   <ToastContainer />
            <div className="fixed inset-0 bg-black/50 backdrop-blur" onClick={onClose}></div>
            <CloseIcon className="absolute top-0 right-4 text-white cursor-pointer" onClick={onClose} />
            <div className="relative z-10 flex">
                <div className="bg-white w-[440px] h-[640px] flex flex-col items-center rounded-md">
                    <Box sx={{ width: '100%', typography: 'body1', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <TabList onChange={handleChange} aria-label="Followers and Following tabs" centered>
                                    <Tab label={`${followerscount} Followers`} value="1" />
                                    <Tab label={`${followingscount} Following`} value="2" />
                                </TabList>
                            </Box>

                            <TabPanel value="1">
                                {loadingFollowers ? (
                                    <div className="flex items-center justify-center w-[390px] h-[550px]">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="flex flex-col w-[390px] h-[550px] overflow-y-auto mb-5">
                                        {(followers || []).map((follower) => (
                                            <div key={follower._id} className="flex items-center gap-4 ml-0 my-1">
                                                <img className="w-12 h-12 rounded-full cursor-pointer" 
                                                     src={`${PROFILEPHOTO_URL}/${follower.profile_image}`} 
                                                     alt='profile picture'
                                                     onClick={() => handleProfile(follower.follower_id)} />
                                                <div className="font-medium flex-1 cursor-pointer" onClick={() => handleProfile(follower.follower_id)}>
                                                    <div>{follower.first_name} {follower.last_name}</div>
                                                    <div className="text-sm text-gray-500">{follower.username}</div>
                                                </div>
                                                {follower.follow_button_status && (
                                                    <FollowButton
                                                        isFollowing={follower.is_user_following}
                                                        onClick={() =>
                                                            follower.is_user_following
                                                                ? handleUnfollow(follower.follower_id, 'follower')
                                                                : handleFollow(follower.follower_id, 'follower')
                                                        }
                                                        buttonDisabled={buttonDisabled}
                                                    />
                                                )}
                                            </div>
                                        ))}

                                        {loadingMoreFollowers && (
                                            <div className="flex items-center justify-center min-h-[50px]">
                                                <CircularProgress size={24} />
                                            </div>
                                        )}

                                        {/* {!hasMoreFollowers && followers.length > 0 && (
                                            <div className="text-center text-gray-500 my-2">No more followers to load</div>
                                        )} */}

                                        <div ref={followersEndRef} />
                                    </div>
                                )}
                            </TabPanel>

                            <TabPanel value="2">
                                {loadingFollowings ? (
                                    <div className="flex items-center justify-center w-[390px] h-[550px] mb-5 ">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="flex flex-col w-[390px] h-[550px] overflow-y-auto">
                                        {(followings || []).map((following) => (
                                            <div key={following._id} className="flex items-center gap-4 ml-0 my-1">
                                                <img className="w-12 h-12 rounded-full cursor-pointer" 
                                                     src={`${PROFILEPHOTO_URL}/${following.profile_image}`} 
                                                     alt='profile picture'
                                                     onClick={() => handleProfile(following.following_id)} />
                                                <div className="font-medium flex-1 cursor-pointer" onClick={() => handleProfile(following.following_id)}>
                                                    <div>{following.first_name} {following.last_name}</div>
                                                    <div className="text-sm text-gray-500">{following.username}</div>
                                                </div>
                                                {following.follow_button_status && (
                                                    <FollowButton
                                                        isFollowing={following.is_user_following}
                                                        onClick={() =>
                                                            following.is_user_following
                                                                ? handleUnfollow(following.following_id, 'following')
                                                                : handleFollow(following.following_id, 'following')
                                                        }
                                                        buttonDisabled={buttonDisabled}
                                                    />
                                                )}
                                            </div>
                                        ))}

                                        {loadingMoreFollowings && (
                                            <div className="flex items-center justify-center min-h-[50px]">
                                                <CircularProgress size={24} />
                                            </div>
                                        )}
{/* 
                                        {!hasMoreFollowings && followings.length > 0 && (
                                            <div className="text-center text-gray-500 my-2">No more followings to load</div>
                                        )} */}

                                        <div ref={followingsEndRef} />
                                    </div>
                                )}
                            </TabPanel>

                        </TabContext>
                    </Box>
                </div>
            </div>
        </div>
    );
};
const FollowButton = ({ isFollowing, onClick, buttonDisabled }) => {
    return (
        <button
            type="button"
            className={
                isFollowing
                    ? "text-black bg-white border border-black hover:bg-black/20 font-medium rounded-lg text-md px-4 py-1.5 ml-auto"
                    : "text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-md px-7 py-1.5 ml-auto"
            }
            onClick={onClick}
            disabled={buttonDisabled}
        >
            {isFollowing ? "Following" : "Follow"}
        </button>
    );
};

export default Userfollowersfollowing;