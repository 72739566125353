import React, { useState, useEffect } from 'react';
import { API_URL, PROFILEPHOTO_URL } from "../../../../../Config/config";
import { CommentIcon, LikeIcon,LikedIcon} from "./icon";
import axios from "axios";



const Vidsbottomsection = ({ video, handleViewComments, handleProfile }) => {
  
    const [isUserLiked, setIsUserLiked] = useState(video.isUserLiked);
    const [likeCount, setLikeCount] = useState(video.likeCount);
   

    const handleLikeClick = async () => {
        try {
          const token = localStorage.getItem('token');
          const vidsId = localStorage.getItem('vidsId');
    
          if (!token || !vidsId) {
            throw new Error('Token or Vids ID not found in local storage');
          }
    
          const url = `${API_URL}/vids/v2/like/${vidsId}`;
          const config = {
            method: 'post',
            url: url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
    
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);
    
          const response = await axios.request(config);
    
          if (response.data.likeCount !== undefined) {
            setLikeCount(response.data.likeCount);
          }
    
        } catch (error) {
          console.error('Error toggling like status:', error);
        
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
        }
      };

    return (
        <div>
              <div className="flex justify-between items-center mt-4 mx-2 ">
                    <div className="flex items-center">
                    <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
               {isUserLiked ? <LikedIcon /> : <LikeIcon />}</div>
                      <div className="mx-3" onClick={handleViewComments}><CommentIcon /></div>
                      {/* <ShareIcon /> */}
                </div>
             </div>
           <div className="flex text-sm mt-4 ml-3.5 text-white">
                    <div>{likeCount} likes</div>
                </div>

                 <div className="flex flex-col ml-3.5 mt-1">
                     <span className="text-white text-sm mr-2 ">
                     <span className=" cursor-pointer" onClick={() => handleProfile(video.user.userId)}>
                         {video.user.firstName} {video.user.lastName}
                         </span>
                        <span className="ml-2 text-xs text-white/80"> {video.caption}</span>
                     </span>
                 </div>  
        </div>
    );
};

export default Vidsbottomsection;
