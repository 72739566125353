// import React, { useState, useEffect } from "react";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { CommentIcon, LikeIcon, ShareIcon } from "./icon";
// import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import axios from "axios";
// import { API_URL, PROFILEPHOTO_URL } from "../../../Config/config";
// import { formatDate } from "../UserProfile/Mdlgscreen/Utils/dateutil";
// import { CircularProgress } from '@mui/material';
// import Postimage from "./postimages";

// const Post = () => {
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [loadingImages, setLoadingImages] = useState([]); 

//   const fetchPosts = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('Token not found in localStorage');
//     }

//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
//       headers: { 
//         'Authorization': `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await axios.request(config);
//       const newPosts = response.data.data;

//       const uniqueNewPosts = newPosts.filter(newPost => 
//         !posts.some(post => post.id === newPost.id)
//       );

//       setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
//       setLoadingImages(prevLoadingImages => [
//         ...prevLoadingImages, 
//         ...new Array(uniqueNewPosts.length).fill(true)
//       ]);
//       setHasMore(newPosts.length > 0);

//       if (newPosts.length === 0 || newPosts.length < 10) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     fetchPosts(0);
//   }, []);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchPosts(page);
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight 
//           && hasMore && !loading && !loadingMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [hasMore, loading, loadingMore]);

//   const handleImageLoad = (index) => {
//     setLoadingImages(prevLoadingImages => {
//       const newLoadingImages = [...prevLoadingImages];
//       newLoadingImages[index] = false;
//       return newLoadingImages;
//     });
//   };

//   return (
//     <>
//       <div className="relative">
//         {loading && posts.length === 0 ? (
//           <div className="mt-24 flex justify-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <>
//             <div className="flex flex-col 2xl:w-[450px] xl:w-[430px] lg:w-[430px] md:w-[430px]  sm:w-[430px] w-[352px]">
//               {posts.map((post, index) => (
//                 <div key={index} className="flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
//                   <div className="flex justify-between w-full items-center mt-2 ml-2">
//                     <div className="flex items-center">
//                       <div className="bg-black rounded-full w-10 h-10 overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <div className="ml-2">
//                         <span className="block text-black text-sm">{post.user.firstName} {post.user.lastName}</span>
//                         <span className="block text-gray-500 text-xs">
//                           {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
//                         </span>
//                       </div>
//                     </div>
//                     {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
//                   </div>

//                   <div className="flex justify-center items-center">
//                     < Postimage post={post} handleImageLoad={handleImageLoad} loadingImages={loadingImages}  index={index}/>
//                     {/* <div className="bg-black/90 mt-2 mb-0.1 w-full 2xl:h-96 xl:h-96 lg:h-96 md:h-96 sm:h-96 h-72 flex justify-center relative">
//                       {loadingImages[index] && (
//                         <div className="absolute inset-0 flex items-center justify-center">
//                           <CircularProgress />
//                         </div>
//                       )}
//                       <img 
//                         src={`${PROFILEPHOTO_URL}/${post.contents[0].url}`} 
//                         alt="Post" 
//                         className={`object-cover w-full h-full ${loadingImages[index] ? 'hidden' : 'block'}`}
//                         onLoad={() => handleImageLoad(index)}
//                       />
//                     </div> */}
//                   </div>

//                   <div className="flex justify-between items-center mt-2 mx-2">
//                     <div className="flex items-center">
//                       <div className="mx-1"><LikeIcon /></div>
//                       <div className="mx-3"><CommentIcon /></div>
//                       <ShareIcon />
//                     </div>
//                     <BookmarkBorderOutlinedIcon style={{ fontSize: 22 }} />
//                   </div>

//                   <div className="flex text-sm mt-2 ml-3.5 text-gray-900">
//                     <div> {post.likeCount> 1 ? `${post.likeCount} likes` : `${post.likeCount} like`}</div>
//                   </div>

//                   <div className="flex ml-3.5 mt-0.5">
//                     <span className="text-black text-sm mr-2">
//                       {post.user.firstName} {post.user.lastName}
//                       <span className="ml-2 text-xs text-gray-700">{post.caption}</span>
//                     </span>
//                   </div>

//                   <div className="mx-3.5 mb-1 mt-0 text-gray-500">
//                     <div className="flex text-sm">
//                       View All {post.commentCount} Comments
//                     </div>
//                     <div className="flex items-center text-xs mx-1">
//                       <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <span className="mx-2">Add a comment ...</span>
//                     </div>
//                   </div>

//                   <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
//                 </div>
//               ))}
//             </div>
//             {loadingMore && (
//               <div className="mt-8 flex justify-center">
//                 <CircularProgress />
//               </div>
//             )}
//             {!loadingMore && !hasMore && posts.length > 0 && (
//               <div className="mt-4 text-gray-400 text-center">No more posts.</div>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default Post;

// import React, { useState, useEffect } from "react";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { CommentIcon, LikeIcon, ShareIcon } from "./icon";
// import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import axios from "axios";
// import { API_URL, PROFILEPHOTO_URL } from "../../../Config/config";
// import { formatDate } from "../UserProfile/Mdlgscreen/Utils/dateutil";
// import { CircularProgress } from '@mui/material';
// import Postimage from "./postimages";

// const Post = () => {
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [loadingImages, setLoadingImages] = useState([]); 

//   const fetchPosts = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('Token not found in localStorage');
//     }

//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
//       headers: { 
//         'Authorization': `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await axios.request(config);
//       const newPosts = response.data.data;

//       const uniqueNewPosts = newPosts.filter(newPost => 
//         !posts.some(post => post.id === newPost.id)
//       );

//       setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
//       setLoadingImages(prevLoadingImages => [
//         ...prevLoadingImages, 
//         ...new Array(uniqueNewPosts.length).fill(true)
//       ]);
//       setHasMore(newPosts.length > 0);

//       if (newPosts.length === 0 || newPosts.length < 10) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     fetchPosts(0);
//   }, []);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchPosts(page);
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
//           && hasMore && !loading && !loadingMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [hasMore, loading, loadingMore]);

//   return (
//     <>
//       <div className="relative mt-0 ">
//         {loading && posts.length === 0 ? (
//           <div className="mt-24 flex justify-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <>
//             <div className="flex flex-col 2xl:w-[450px] xl:w-[430px] lg:w-[430px] md:w-[430px]  sm:w-[430px] w-[352px]">
//               {posts.map((post, index) => (
//                 <div key={index} className="flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
//                   <div className="flex justify-between w-full items-center mt-2 ml-2">
//                     <div className="flex items-center">
//                       <div className="bg-black rounded-full  w-10 h-10 mb-1  overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <div className="ml-2">
//                         <span className="block text-black text-sm">{post.user.firstName} {post.user.lastName}</span>
//                         <span className="block text-gray-500 text-xs">
//                           {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
//                         </span>
//                       </div>
//                     </div>
//                     {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
//                   </div>

//                   <div className="flex justify-center items-center">
//                     <Postimage post={post}  />
//                   </div>

//                   <div className="flex justify-between items-center mt-2 mx-2">
//                     <div className="flex items-center">
//                       <div className="mx-1"><LikeIcon /></div>
//                       <div className="mx-3"><CommentIcon /></div>
//                       <ShareIcon />
//                     </div>
//                     <BookmarkBorderOutlinedIcon style={{ fontSize: 22 }} />
//                   </div>

//                   <div className="flex text-sm mt-2 ml-3.5 text-gray-900">
//                     <div>{post.likeCount > 1 ? `${post.likeCount} likes` : `${post.likeCount} like`}</div>
//                   </div>

//                   <div className="flex ml-3.5 mt-0.5">
//                     <span className="text-black text-sm mr-2">
//                       {post.user.firstName} {post.user.lastName}
//                       <span className="ml-2 text-xs text-gray-700">{post.caption}</span>
//                     </span>
//                   </div>

//                   <div className="mx-3.5 mb-1 mt-0 text-gray-500">
//                     <div className="flex text-sm">
//                       View All {post.commentCount} Comments
//                     </div>
//                     <div className="flex items-center text-xs mx-1">
//                       <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <span className="mx-2">Add a comment ...</span>
//                     </div>
//                   </div>

//                   <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
//                 </div>
//               ))}
//             </div>
//             {loadingMore && (
//               <div className="mt-8 flex justify-center">
//                 <CircularProgress />
//               </div>
//             )}
//             {!loadingMore && !hasMore && posts.length > 0 && (
//               <div className="mt-4 text-gray-400 text-center">No more posts.</div>
//             )}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default Post;

import React, { useState, useEffect, useRef } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CommentIcon, LikeIcon, ShareIcon } from "../icon";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import axios from "axios";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import { formatDate } from "../../UserProfile/Mdlgscreen/Utils/dateutil";
import { CircularProgress } from '@mui/material';
import Postimage from "./postimages";
import Postbottomsection from "./postbottomsection";
import PostComment from "./postsubmitcomment";
import Modal from '../../UserProfile/Mdlgscreen/Postmodal/PostModal';
import { useNavigate } from "react-router-dom";


const LgPost = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingImages, setLoadingImages] = useState([]);
  const [activePostIndex, setActivePostIndex] = useState(null);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const navigate = useNavigate();

  const fetchPosts = async (pageNumber) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };

    try {
      const response = await axios.request(config);
      const newPosts = response.data.data;

      const uniqueNewPosts = newPosts.filter(newPost => 
        !posts.some(post => post.id === newPost.id)
      );

      setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
      setLoadingImages(prevLoadingImages => [
        ...prevLoadingImages, 
        ...new Array(uniqueNewPosts.length).fill(true)
      ]);
      setHasMore(newPosts.length > 0);

      if (newPosts.length === 0 || newPosts.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchPosts(0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      setLoadingMore(true);
      fetchPosts(page);
    }
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
          && hasMore && !loading && !loadingMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, loadingMore]);


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));
          setActivePostIndex(index);
        } else {
          const index = Number(entry.target.getAttribute('data-index'));
          if (index === activePostIndex) {
            setActivePostIndex(null);
          }
        }
      });
    }, { threshold: 0.5 });

    document.querySelectorAll('.post').forEach((element) =>{ 
      observer.observe(element);
    });

    return () => {
      document.querySelectorAll('.post').forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [posts, activePostIndex]);


  const incrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId ? { ...post, commentCount: post.commentCount + 1 } : post
      )
    );
  };

  const decrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId && post.commentCount > 0 ? { ...post, commentCount: post.commentCount - 1 } : post
      )
    );
  };


  const openModal = (post) => {
    setSelectedPost(post);
    localStorage.setItem('postId', post.id);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const handleProfile = (userId) => {
    navigate(`/smartsocial/${userId}/`);
  };
  

  return (
    <>
      <div className="relative mt-0 2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-44 sm:ml-0 ml-0 ">
        
        {loading && posts.length === 0 ? (
          <div className="mt-24 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="flex flex-col  w-[430px]   ">
              {posts.map((post, index) => (
                <div key={post.id} data-index={index} className="post flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
                  <div className="flex justify-between w-full items-center mt-2 ml-2">
                    <div className="flex items-center">
                      <div className="bg-black rounded-full  w-10 h-10 mb-1  overflow-hidden cursor-pointer"  onClick={() => handleProfile(post.user.userId)}>
                        <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
                      </div>
                      <div className="ml-2">
                        <span className="block text-black text-sm cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                          {post.user.firstName} {post.user.lastName}
                          </span>
                        <span className="block text-gray-500 text-xs">
                          {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
                        </span>
                      </div>
                    </div>
                    {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
                  </div>

                  <div className="flex justify-center items-center">
                    <Postimage post={post} isActive={activePostIndex === index} />
                  </div>
                 
                     <div>
                      <Postbottomsection post={post} openModal={openModal} />


          <div className="mx-3.5 mb-1 mt-0 text-gray-500">
            <div className="flex text-sm cursor-pointer"    onClick={() => openModal(post)}>
            View All {post.commentCount} Comments
            </div>
            <div className="flex items-center text-xs mx-1">
              {/* <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
                <img
                  src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`}
                  alt="Profile"
                  className="object-cover w-full h-full"
                />
              </div> */}
                <PostComment
                          postId={post.id}
                          incrementCommentCount={incrementCommentCount}
                          activeEmojiPicker={activeEmojiPicker}
                          setActiveEmojiPicker={setActiveEmojiPicker}
                          
                        />           
                         </div>
          </div>
                    </div>

                  <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
                </div>
              ))}
            </div>
            {loadingMore && (
              <div className="mt-8 flex justify-center">
                <CircularProgress />
              </div>
            )}
            {!loadingMore && !hasMore && posts.length > 0 && (
              <div className="mt-4 text-gray-400 text-center">No more posts.</div>
            )}
          </>
        )}
      </div>

      {selectedPost && (
  <Modal 
    post={selectedPost} 
    onClose={closeModal} 
    postIds={selectedPost.id} 
    incrementCommentCount={incrementCommentCount} 
    decrementCommentCount={decrementCommentCount} 
  />
)}

    </>
  );
};

export default LgPost;
