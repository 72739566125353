// import React from "react";
// import { connect } from "react-redux";
// import Footer from "../Footer/Footer";
// import Navbar from "../Navbar/Navbar";
// import Middlecontent from "./Middlecontent";
// import Header from "./Header";
// import SubHeader from "./Subheader";
// import Pagination from "./Middlecontent/Pagination";

// const Realestate = () => {
//   return (
//     <div className="min-h-screen w-full flex flex-col">
//       <Header />
//       <SubHeader />
//       <div className="flex-grow">
//         <Middlecontent />
//       </div>
//       <Pagination  />
//     </div>
//   );
// };



// export default Realestate;


import React from "react";
import { connect } from "react-redux";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import Middlecontent from "./Middlecontent";
import Header from "./Header";
import SubHeader from "./Subheader";
import Pagination from "./Middlecontent/Pagination";
import {Navigate} from 'react-router-dom';

const Realestate = ({ real_estate_view }) => {
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (!userData) {
    return <Navigate to="/login" />;
  }


  return (
    <div className="min-h-screen w-full flex flex-col ">
      <Header />
      {real_estate_view === "list" && <SubHeader />}
      <div className="flex-grow">
        <Middlecontent currentView={real_estate_view} />
      </div>
      {real_estate_view === "list" && <Pagination />}
    </div>
  );
};

const mapStateToProps = state => ({
  real_estate_view: state.view.real_estate_view,
});

export default connect(mapStateToProps)(Realestate);
