import axios from "axios";
import URLS from "../Urls";
const Bearer = "Bearer ";
const authApi = {
  
  loginWithPhone: async (params) => {

    try {
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let data = await axios.post(URLS.login, params, axiosConfig);

      return data;
    } catch (err) {

      throw err.response;
    }
  },

  verifyOtp: async (params) => {
    try {
      const data = await axios.post(URLS.otpVerifyUrl, params);
      return data;
    } catch (error) {
      throw error.response;
    }
  },

  removeRequest: async (params) => {
    try {
      const data = await axios.get(URLS.removeAccount, params);
      return data;
    } catch (error) {
      throw error.response;
    }
  },

  accountRemoveRequest: async (token, params) => {
    try {
      const data = await axios.get(URLS.sendAccountRemove, params, {
        headers: {
          Authorization: `${Bearer}${token}`,
        },
      });

      return data;
    } catch (error) {
      throw error.response;
    }
  },

  removeAccount: async (token, requestid, params) => {
    try {
      const data = await axios.post(
        URLS.verifyRemoveRequest + requestid,
        params,
        {
          headers: {
            Authorization: `${Bearer}${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      throw error.response;
    }
  },
  fetchUserInfo: async (token) => {
    try {
      const config = {
        headers: {
          Authorization: `${Bearer}${token}`,
        },
      };
      const response = await axios.get(URLS.userInfo, config);
      return response.data.data;
    }
    catch (error) {
      throw error.response;
    }
  },
  
};

export default authApi;
