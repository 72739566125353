import {
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAILURE,
  UPDATE_TOTAL_PROPERTIES,
  FETCH_COUNT_PROPERTIES,
  FETCH_STATE_SUCCESS,
  UPDATE_PAGE_NUMBER,
  FETCH_FAVORITES_SUCCESS,
  TOGGLE_LIKE_PROPERTY,
} from '../Action/propertyAction';

const initialState = {
  properties: [],
  propertiesFetched: false,
  loading: false,
  error: null,
  totalProperties: null,
  countProperties: null,
  state: null,
  state_code: null,
  pageNumber: 0,
  likedProperties: [],
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.payload.properties,
        propertiesFetched: true,
        loading: false,
        error: null,
      };
    case FETCH_PROPERTIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_TOTAL_PROPERTIES:
      return {
        ...state,
        loading: false,
        totalProperties: action.payload,
      };
    case FETCH_COUNT_PROPERTIES:
      return {
        ...state,
        loading: false,
        countProperties: action.payload,
      };
    case FETCH_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        state: action.payload.state,
        state_code: action.payload.state_code,
      };
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case FETCH_FAVORITES_SUCCESS:
      return {
        ...state,
        likedProperties: action.payload,
      };
    case TOGGLE_LIKE_PROPERTY:
      const propertyId = action.payload;
      const likedIndex = state.likedProperties.indexOf(propertyId);
      if (likedIndex !== -1) {
       
        return {
          ...state,
          likedProperties: state.likedProperties.filter(id => id !== propertyId),
        };
      } else {
       
        return {
          ...state,
          likedProperties: [...state.likedProperties, propertyId],
        };
      }
    default:
      return state;
  }
};

export default propertyReducer;