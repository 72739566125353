import React from 'react';

const Listskeleton = () => {
   
   

    return (
        <div className="  mt-6 border p-4 mr-2 shadow-2xl   border-gray-200 rounded-xl ">
          <div className="h-6 bg-gray-300 rounded-full mt-8 mx-4 justify-center"></div>
        
  
          <div className=" rounded-full  my-12 mx-4  flex justify-center items-center  animate-pulse">
            <div className=" w-28 h-28 rounded-3xl bg-gray-300  border-2 border-gray-800 ">
                <svg
                    className="w-12 h-12  my-7 mx-7 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
                </div>
            </div>
           
            <div className="my-16 mx-4 animate-pulse ">
            <div className="h-4 bg-gray-300 rounded-full  w-72 mb-4"></div>
            <div className="h-4 bg-gray-300 rounded-full  w-72 mb-4"></div>
            <div className="h-4 bg-gray-300 rounded-full  w-72 mb-4"></div>
          
        </div>
     </div>
  );
}

export default Listskeleton;