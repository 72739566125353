import React from 'react';


const SubHeaderskeleton = () => {
  

  
  return (
    <div className=" xl:ml-20 mx-4 flex items-center animate-pulse mb-4 "> 
      <div className="flex flex-col">
        <h1 className=" h-4 bg-gray-300 rounded-full  w-64 justify-center flex "></h1>
        <h2 className="h-6 bg-gray-300 rounded-full  w-64 justify-center   my-2 flex flex-col"></h2>
        
        <p className="h-4 bg-gray-300 rounded-full  w-96 justify-center mt-3 "></p>
        <p className="h-4 bg-gray-300 rounded-full  w-96 justify-center mt-2 "></p>

        <div className="mt-8 flex">
          <div className="mr-10 flex items-center">
         
            <div className="flex flex-col mr-10">
              <span className=" h-4 bg-gray-300 rounded-full  w-44 justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  w-44 justify-center mt-2 mr-6"></span>
            </div>
          </div>

        

          <div className="mr-24 flex items-center">
         
            <div className="flex flex-col">
            <span className=" h-4 bg-gray-300 rounded-full  w-44 justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  w-44 justify-center mt-2 mr-10"></span>
            </div>
          </div>

        </div>
      </div>

     
      <div className=" w-56 h-36  bg-gray-200  ml-32 hidden md:block  rounded-xl  mt-12"> </div> 
    </div>
  );
};

export default SubHeaderskeleton;