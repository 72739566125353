import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './style.css';

const List = ({data,images}) => {
   
   

    return (
        <div className="ml-0   mt-6 border p-4  shadow-2xl   border-blue-800 rounded-xl  ">
      <h1 className="font-semibold text-3xl mx-2 mt-8 text-blue-800 justify-center"> 
        Listing Office and Agent
        </h1>
        
        <div className="flex items-center justify-center my-12 mx-4">
          <img className="w-24 h-24 rounded-3xl   border-2 border-blue-800 mr-8" src={data?.agent_contact_info?.agent?.image} alt="Profile" />
            </div>
            <div className="my-16 mx-2 ">
             <p className="font-semibold text-xl mt-8 my-2 text-blue-900">Name: <span className="font-normal text-lg text-black ">{data?.agent_contact_info?.agent?.name}</span></p>
              <p className="font-semibold text-xl my-2 text-blue-900">Email: <span className="font-normal text-lg text-black">{data?.agent_contact_info?.agent?.email}</span></p>
              <p className="font-semibold text-xl my-2  text-blue-900">Phone: <span className="font-normal text-lg text-black">{data?.agent_contact_info?.agent?.phones[0]?.number}</span></p>
          
        </div>
     </div>
  );
}

export default List;