import {
    FETCH_POST_COMMENT_REQUEST,
    FETCH_POST_COMMENT_SUCCESS,
    FETCH_POST_COMMENT_FAILURE,
    FETCH_COMMENT_REPLIES_REQUEST,
    FETCH_COMMENT_REPLIES_SUCCESS,
    FETCH_COMMENT_REPLIES_FAILURE
  } from '../../Action/smartsocial/postcommentaction';
  
  const initialState = {
    loading: false,
    comments: [],
    error: null,
    replies: {} 
  };
  
  const postcommentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_POST_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null
        };
      case FETCH_POST_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          comments: action.payload, 
          error: null
        };
      case FETCH_POST_COMMENT_FAILURE:
        return {
          ...state,
          loading: false,
          comments: [], 
          error: action.payload
        };
        case FETCH_COMMENT_REPLIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_COMMENT_REPLIES_SUCCESS:
      return {
        ...state,
        loading: false,
        replies: {
          ...state.replies,
          [action.payload.commentId]: action.payload.data
        }
      };
    case FETCH_COMMENT_REPLIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      default:
        return state;
    }
  };
  
  export default postcommentReducer;
  