import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { API_URL } from '../../../../../Config/config';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const VideoSubmitComment = ({ addNewComment, commentId, onSubmit, isReplying, onCancelReply, username, editingCommentId, editingContent, originalCommentContent, onEditSubmit, isEditing, onCancelEdit, editingReplyId, editingReplyContent, onCancelEditReply, onReplyEditSubmit }) => {

  const [content, setContent] = useState('');
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setContent(editingContent || editingReplyContent || '');
  }, [editingContent, editingReplyContent]);

  const handleCommentChange = (e) => {
    setContent(e.target.value);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (emojiObject) => {
    const newContent = (content || '') + emojiObject.emoji;
    setContent(newContent);
    setChosenEmoji(emojiObject);
  };

  const postComment = async () => {
    const token = localStorage.getItem('token');
    const vidsId = localStorage.getItem('vidsId');
    
    if (!token || !vidsId) {
        return;
    }

    const data = JSON.stringify({ content });

    let url;
    if (editingCommentId) {
        url = `${API_URL}/vids/v2/comment/${editingCommentId}`;
    } else if (editingReplyId) {
        url = `${API_URL}/vids/v2/replies/${editingReplyId}`;
    } else if (isReplying) {
        url = `${API_URL}/vids/v2/replies/${commentId}`;
    } else {
        url = `${API_URL}/vids/v2/comment/${vidsId}`;
    }

    const config = {
        method: editingCommentId || editingReplyId ? 'put' : 'post',
        maxBodyLength: Infinity,
        url: url,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    setLoading(true);

    try {
        const response = await axios.request(config);
        if (editingCommentId) {
            onEditSubmit(response.data.data);
        } else if (editingReplyId) {
            onReplyEditSubmit(response.data.data);
        } else if (isReplying) {
            onSubmit(commentId, response.data.data);
        } else {
            addNewComment(response.data.data);
        }
        setContent('');
        setChosenEmoji(null);
        setLoading(false);
        setShowEmojiPicker(false);

    } catch (error) {
        setLoading(false);
    }
};

  const handleCancelReply = () => {
    setContent('');
    onCancelReply();
  };
  const handleCancelEdit = () => {
    setContent('');
    onCancelEdit();
  };

  const handleCancelEditReply = () => {
    setContent('');
    onCancelEditReply();
  };

  const isUpdateDisabled = content === originalCommentContent || content === editingReplyContent;

  return (
    <div className='   h-18'>
    <div className='  mt-1 flex items-center '>
      {chosenEmoji ? (
        <span className="ml-2 cursor-pointer" onClick={toggleEmojiPicker}>{chosenEmoji.emoji}</span>
      ) : (
        <span className="ml-2 cursor-pointer " onClick={toggleEmojiPicker}>😊</span>
      )}
      {showEmojiPicker && (
        <div className="absolute bottom-[118px] ml-0 mb-10 z-10">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      <input
        className="bg-black/80 text-white w-full p-4 focus:outline-none"
        style={{ caretColor: 'gray' }}
        placeholder={isReplying ? `Reply to ${username}` : isEditing ? 'Edit your comment' : editingReplyId ? 'Edit your reply' : 'Add a comment...'}
        value={content}
        onChange={handleCommentChange}
        onFocus={() => setShowEmojiPicker(false)}
      />

      <div className="ml-2">
        {isReplying ? (
          <button
            className={` text-white py-1 px-3 rounded-xl ${!content.trim() && 'opacity-50 cursor-not-allowed'}`}
            onClick={postComment}
            disabled={!content.trim()}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} className='text-white' />
            ) : 'Reply'}
          </button>
        ) : isEditing || editingReplyId ? (
          <button
            className={` text-white py-1 px-3 rounded-full ${isUpdateDisabled && 'opacity-50 cursor-not-allowed'}`}
            onClick={postComment}
            disabled={isUpdateDisabled || !content.trim()}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} className='text-white' />
            ) : 'Update'}
          </button>
        ) : (
          <button
            className={` text-white py-1 px-3 mr-2 rounded-xl ${!content.trim() && 'opacity-50 cursor-not-allowed'}`}
            onClick={postComment}
            disabled={!content.trim()}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "white" }} className='text-white' />
            ) : 'Post'}
          </button>
        )}
      </div>

       {(isReplying || isEditing || editingReplyId) && (
        <button
          className="ml-2 text-gray-400 pr-2 py-2 rounded"
          onClick={isReplying ? handleCancelReply : isEditing ? handleCancelEdit : handleCancelEditReply}
        >
          <CloseIcon />
        </button>
      )}

    </div>
    </div>
  );
};

export default VideoSubmitComment;
