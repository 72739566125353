import axios from 'axios';
import { API_URL } from '../../../Config/config';

export const FETCH_REPLIES_REQUEST = 'FETCH_REPLIES_REQUEST';
export const FETCH_REPLIES_SUCCESS = 'FETCH_REPLIES_SUCCESS';
export const FETCH_REPLIES_FAILURE = 'FETCH_REPLIES_FAILURE';

const fetchRepliesRequest = (commentId) => ({
  type: FETCH_REPLIES_REQUEST,
  payload: commentId,
});

const fetchRepliesSuccess = (commentId, replies) => ({
  type: FETCH_REPLIES_SUCCESS,
  payload: { commentId, replies },
});

const fetchRepliesFailure = (commentId, error) => ({
  type: FETCH_REPLIES_FAILURE,
  payload: { commentId, error },
});

export const fetchReplies = (commentId) => {
  return async (dispatch) => {
    dispatch(fetchRepliesRequest(commentId));
    try {
      const token = localStorage.getItem('token');
      if (!token || !commentId) {
        throw new Error('Token or comment ID not found in local storage');
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
      const response = await axios.get(
        `${API_URL}/posts/v3/comment/reply/${commentId}?page_number=0`,
        config
      );

      dispatch(fetchRepliesSuccess(commentId, response.data.data));
    } catch (error) {
      dispatch(fetchRepliesFailure(commentId, error.message));
    }
  };
};
