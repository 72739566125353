import React, { useState, useRef, useEffect } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { PROFILEPHOTO_URL } from '../../../../../Config/config';
import { CircularProgress } from '@mui/material'; 

const Vids = ({ video }) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [overlayTime, setOverlayTime] = useState(null);
    const [overlayPosition, setOverlayPosition] = useState({ left: 0 });
    const videoRef = useRef(null);
    const previewVideoRef = useRef(null);
    const sliderRef = useRef(null); 
    const [isLoading, setIsLoading] = useState(true);


    const toggleMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVideoClick = () => {
        togglePlayPause();
    };

    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            setProgress((currentTime / duration) * 100);
        }
    };

    const handleSliderChange = (event) => {
        event.stopPropagation(); 
        if (videoRef.current) {
            const newTime = (event.target.value / 100) * videoRef.current.duration;
            videoRef.current.currentTime = newTime;
            setProgress(event.target.value);
        }
    };

    const handleMouseMove = (event) => {
        event.stopPropagation(); 
        const sliderRect = sliderRef.current.getBoundingClientRect();
        const offsetX = event.clientX - sliderRect.left;
        const newProgress = (offsetX / sliderRect.width) * 100;
        const newTime = (newProgress / 100) * videoRef.current.duration;
        setOverlayTime(newTime);
        setOverlayPosition({ left: offsetX });

        if (previewVideoRef.current) {
            previewVideoRef.current.currentTime = newTime;
        }
    };

    const handleMouseLeave = () => {
        setOverlayTime(null);
    };

    const handleLoadedData = () => {
        setIsLoading(false);
    };


    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
            return () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                }
            };
        }
    }, []);

    return (
        <div>
            <div
                className="2xl:w-[500px] 2xl:h-[750px] xl:w-[500px] xl:h-[750px] lg:w-[480px] lg:h-[750px] md:w-[380px] md:h-[750px] m-auto relative group bg-black/70 rounded-tl-lg rounded-bl-lg"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleVideoClick}
            >
                 {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div ><CircularProgress /></div>
                    </div>
                )}
                {video && video.thumbnail && (
                    <video
                        ref={videoRef}
                        src={`${PROFILEPHOTO_URL}/${video.url}`}
                        className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg"
                        autoPlay
                        muted={isMuted}
                        onEnded={handleVideoEnd}
                        onLoadedData={handleLoadedData}
                    />
                )}
                <button
                    className="absolute top-4 right-4 bg-gray-800/70 hover:bg-gray-700/70 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
                    onClick={(event) => { event.stopPropagation(); toggleMute(); }}
                >
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
                {!isPlaying && (
                    <button
                        className="absolute inset-0 m-auto w-16 h-16 bg-gray-800/60 hover:bg-gray-700/50 text-white rounded-full flex items-center justify-center shadow-lg"
                        onClick={(event) => { event.stopPropagation(); togglePlayPause(); }}
                    >
                        <PlayArrowIcon style={{ fontSize: 40 }} />
                    </button>
                )}
                {isHovered && (
                    <input
                        ref={sliderRef} 
                        type="range"
                        className="absolute bottom-4 left-4 right-4"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleSliderChange}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                        onClick={(event) => event.stopPropagation()} 
                    />
                )}
                {overlayTime !== null && (
                    <div
                    className="absolute w-28 h-44 border border-black rounded-lg bg-white/40 text-white shadow-lg"
                        style={{
                            bottom: '60px',
                            left: `${overlayPosition.left}px`,
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <video
                            ref={previewVideoRef}
                            src={`${PROFILEPHOTO_URL}/${video.url}`}
                            className="object-cover w-full h-full mb-2 rounded-lg"
                            // style={{
                            //   width: '95px',  
                            //   height: '128px', 
                            //   objectFit: 'cover',
                            //   }}
                            muted
                        />
                        <div className="text-xs mt-2 text-center">
                            {new Date(overlayTime * 1000).toISOString().substr(11, 8)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Vids;



// import React, { useState, useRef, useEffect } from 'react';
// import ReactPlayer from 'react-player';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PauseIcon from '@mui/icons-material/Pause';
// import { PROFILEPHOTO_URL } from '../../../../../Config/config';
// import { CircularProgress } from '@mui/material'; 

// const Vids = ({ video }) => {
//     const [isMuted, setIsMuted] = useState(true);
//     const [isPlaying, setIsPlaying] = useState(true);
//     const [isHovered, setIsHovered] = useState(false);
 
//     const [isLoading, setIsLoading] = useState(true);
//     const videoRef = useRef(null);
   
//     const toggleMute = () => {
//         setIsMuted(!isMuted);
//     };

//     const togglePlayPause = () => {
//         setIsPlaying(!isPlaying);
//     };

    

//     const handleLoadedData = () => {
//         setIsLoading(false);
//     };

//     return (
//         <div>
//             <div
//                 className="2xl:w-[500px] 2xl:h-[750px] xl:w-[500px] xl:h-[750px] lg:w-[480px] lg:h-[750px] md:w-[380px] md:h-[750px] m-auto relative group bg-black/70 rounded-tl-lg rounded-bl-lg"
//                 onMouseEnter={() => setIsHovered(true)}
//                 onMouseLeave={() => setIsHovered(false)}
//             >
//                 {isLoading && (
//                     <div className="absolute inset-0 flex items-center justify-center">
//                         <CircularProgress />
//                     </div>
//                 )}
//                 {video && video.thumbnail && (
//                     <ReactPlayer
//                         ref={videoRef}
//                         url={`${PROFILEPHOTO_URL}/${video.url}`}
//                         className="object-cover w-full h-full rounded-tl-lg rounded-bl-lg"
//                         playing={isPlaying}
//                         muted={isMuted}
//                         onEnded={() => setIsPlaying(true)} // Autoplay after end
//                         onReady={handleLoadedData}
//                         controls
//                         width="100%"
//                         height="100%"
//                     />
//                 )}
//                 <button
//                     className="absolute top-4 right-4 bg-gray-800/70 hover:bg-gray-700/70 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
//                     onClick={(event) => { event.stopPropagation(); toggleMute(); }}
//                 >
//                     {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
//                 </button>
//                 {!isPlaying && (
//                     <button
//                         className="absolute inset-0 m-auto w-16 h-16 bg-gray-800/60 hover:bg-gray-700/50 text-white rounded-full flex items-center justify-center shadow-lg"
//                         onClick={(event) => { event.stopPropagation(); togglePlayPause(); }}
//                     >
//                         <PlayArrowIcon style={{ fontSize: 40 }} />
//                     </button>
//                 )}
               
              
//             </div>
//         </div>
//     );
// };

// export default Vids;

