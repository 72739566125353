import React from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate } from 'react-router-dom';

const MarkerListingproperty = ({ property, closeHandler }) => {
  const navigate = useNavigate();

  const handleClick = (propertyId) => {
    navigate(`/homedetail/${propertyId}`);
  };

  return (
    <div>
      {/* <div className="absolute top-2 right-2">
        <CloseOutlinedIcon
          onClick={() => closeHandler()}
          className="text-gray-400 bg-black rounded-full p-1"
        />
      </div> */}
      <div onClick={() => handleClick(property.property_id)} className="cursor-pointer shadow-2xl">
        <div className="group relative shadow-2xl hover:scale-110 ease-in-out duration-500">
          <div className="bg-gray-200 ">
            <img
              loading="lazy"
              src={property.thumbnail}
              className="h-48 w-60 object-cover rounded-2xl object-center "
            />
            <div className="absolute top-0 left-0 flex flex-col">
              <div className="py-1 pl-2 pr-2 text-white bg-green-600 bg-opacity-80 rounded-tl-xl">
                {property.prop_status}
              </div>
              <div className="py-1 pl-2 pr-6 text-white bg-blue-600 bg-opacity-80">$&nbsp;{property.price}</div>
            </div>
            <div className="absolute bottom-0 left-0 w-60">
              <div className="flex px-0 py-0.5 text-white text-center bg-blue-600 bg-opacity-80">
                <div className="flex-auto">
                  {property.prop_type} | {property.beds} beds | {property.baths} baths | {property.lot_size}
                </div>
              </div>
              <div className="flex text-blue-800">
                <div className="grow justify-center py-1.5 pl-1 w-60 bg-zinc-300 rounded-bl-2xl rounded-br-2xl bg-opacity-80">
                  <div className="truncate">
                    {property.address.line}, {property.address.city}, {property.address.state},{' '}
                    {property.address.state_code}, {property.address.postal_code}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkerListingproperty;
