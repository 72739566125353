
export const SET_COORDINATES = 'SET_COORDINATES';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';


export const setCoordinates = (coordinates) => {
  console.log('Dispatching new coordinates:', coordinates); 
  return {
    type: SET_COORDINATES,
    payload: coordinates,
  };
};

export const setMapBounds = (bounds) => {
  console.log('Dispatching new map bounds:', bounds); 
  return {
    type: SET_MAP_BOUNDS,
    payload: bounds,
  };
};
