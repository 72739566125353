import React from 'react';

const AccordionBody = ({ id, children, isOpen }) => {
  return (
    <div
      id={`${id}-body`}
      className={`py-5   xl:ml-20  mx-4 text-md border-blue-800  ${isOpen ? '' : 'hidden'}`}
      aria-labelledby={id}
    >
      {children}
    </div>
  );
};

export default AccordionBody;
