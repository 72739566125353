
const sortOptions = {
    'Relevant Listing': 'list_date_min',
    'Newest Listing': 'list_date_max',
    'Lowest Price': 'price_min',
    'Highest Price': 'price_max',
    'Lot Size': 'lot_sqft_min',
    'Largest sqft': 'lot_sqft_max',
    'Low Photo Count': 'photo_count_min',
    'High Photo Count': 'photo_count_max',
    'More Bed': 'beds_max',
    'Less Bed' : 'beds_min',
  };
  
  export default sortOptions;
  