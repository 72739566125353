import React, { useState } from "react";
import Search from "./Search";
import Filter from "./Filter/Filter";
import LargeFilter from "./Filter/largefilter";
import MediumFilter from "./Filter/MediumFilter";
import SmallFilter from "./Filter/smallfilter";
import View from "./View"
import Listing from "./listing";
import { connect } from "react-redux";
import { changeView } from "../../Action/viewaction";
import { useMediaQuery } from 'react-responsive';

const Header = ({ real_estate_view, changeView }) => {
  const is2XL = useMediaQuery({ query: '(min-width: 1536px)'});
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' }); 
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' }); 
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });
  
  const headerClass = real_estate_view === 'map' ? "bg-gray-100 h-20 fixed top-20 z-10 w-full" : "bg-gray-100 h-20 ";

  return (
    <header className="bg-gray-100 h-20 fixed  top-20 z-20 w-full">
      <section className="flex  items-center mb-2">
        <form className="mt-4 mx-5 relative flex items-center flex-grow">
          <div className="">
            <Listing />
          </div>
          <div className='sm:max-w-96 md:min-w-64  max-w-md flex-grow'>
            <Search />
          </div>
          <div>
          {is2XL ? <Filter /> : (isXL ? <Filter />: (isLG ? <LargeFilter /> : (isMD ? <MediumFilter /> : (isSM ? <SmallFilter /> :  <SmallFilter /> ))))}
          </div>
        </form>
       
        <div className="ml-auto px-1 flex ">
          <div className="hidden md:flex">
            <View real_estate_view={real_estate_view} handleViewChange={changeView} />
          </div>
        </div>
      </section>
    </header>
  );
};

const mapStateToProps = state => ({
  real_estate_view: state.view.real_estate_view
});

const mapDispatchToProps = {
  changeView
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
