// src/actions/userActions.js
import axios from 'axios';
import { API_URL } from '../../../Config/config';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const fetchUserRequest = () => ({
  type: FETCH_USER_REQUEST,
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

// export const fetchUser = () => {
//   return async (dispatch) => {
//     dispatch(fetchUserRequest());
//     try {
//       const token = localStorage.getItem('token');
//       if (!token) {
//         throw new Error('Bearer token not found in local storage');
//       }

//       const response = await axios.get('http://dev.smartsuperapp.com/users/v2/info', {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       });

//       dispatch(fetchUserSuccess(response.data.data));
//     } catch (error) {
//       dispatch(fetchUserFailure(error.message));
//     }
//   };
// };

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch(fetchUserRequest());
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Bearer token not found in local storage');
      }
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        dispatch(fetchUserSuccess(JSON.parse(storedUser))); 
        return; 
      }
      const response = await axios.get(`${API_URL}/users/v2/info`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      dispatch(fetchUserSuccess(response.data.data));
      localStorage.setItem('user', JSON.stringify(response.data.data));

    } catch (error) {
      dispatch(fetchUserFailure(error.message));
    }
  };
};
