import React, { useState, useEffect, useRef } from 'react';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import './style.css';
import {  LikeIcon,LikedIcon, CommentIcon, ShareIcon } from './vidsicon';
import { calculateTimeAgo } from '../Utils/timeutils';
import { formatDate } from '../Utils/dateutil';
import axios from 'axios';

import { API_URL } from '../../../../../Config/config';


const VidsBottomsection = ({ video }) => {
    const [formattedTimeAgo, setFormattedTimeAgo] = useState('');
    const [isUserLiked, setIsUserLiked] = useState(video.isUserLiked);
    const [likeCount, setLikeCount] = useState(video.likeCount);
 
    useEffect(() => {
        if (video && video.createdAt) {
            const formattedTime = calculateTimeAgo(video.createdAt);
            setFormattedTimeAgo(formattedTime);
        }
    }, [video]);
   

    const handleLikeClick = async () => {
        try {
          const token = localStorage.getItem('token');
          const vidsId = localStorage.getItem('vidsId');
    
          if (!token || !vidsId) {
            throw new Error('Token or Vids ID not found in local storage');
          }
    
          const url = `${API_URL}/vids/v2/like/${vidsId}`;
          const config = {
            method: 'post',
            url: url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
    
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);
    
          const response = await axios.request(config);
    
          if (response.data.likeCount !== undefined) {
            setLikeCount(response.data.likeCount);
          }
    
        } catch (error) {
          console.error('Error toggling like status:', error);
        
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
        }
      };

    return (
        
               <>
                <div className="flex justify-between items-center mt-4 mx-2 ">
                    <div className="flex items-cenr">
                        <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
                        {isUserLiked ? <LikedIcon /> : <LikeIcon />}
                            </div>
                        <div className="mx-3"><CommentIcon /></div>
                        {/* <ShareIcon /> */}
                    </div>
                    {/* <div style={{ color: 'white', fontSize: 22 }}>
                        <BookmarkBorderOutlinedIcon />
                    </div> */}
                </div>
                <div className="flex text-sm mt-2 ml-4 text-white">
                    <div>{likeCount} likes</div>
                </div>
                <div className="flex text-sm mt-2 ml-4 text-gray-300 mb-1">
                    <div>{formatDate(video.createdAt)}</div>
                </div>
    </>
    );
};

export default VidsBottomsection;



