import React, { useState, useEffect, useRef } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CommentIcon, LikeIcon, ShareIcon, LikedIcon } from "../icon";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import axios from "axios";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import { formatDate } from "../../UserProfile/Mdlgscreen/Utils/dateutil";
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";


const Postbottomsection = ({post, handleViewComments}) => {
  
    const [isUserLiked, setIsUserLiked] = useState(post.isUserLiked);
    const [likeCount, setLikeCount] = useState(post.likeCount);
    const [loading, setLoading] = useState(false);
    const [showFullCaption, setShowFullCaption] = useState(false);
    const navigate = useNavigate();


    const handleLikeClick = async () => {
        if (loading) return;
    
        setLoading(true);
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found in local storage');
        }
    
        try {
          const url = `${API_URL}/posts/v3/like/${post.id}`;
          const config = {
            method: 'post',
            url: url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
    
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);
    
          const response = await axios.request(config);
    
          if (response.data.likeCount !== undefined) {
            setLikeCount(response.data.likeCount);
          }
    
        } catch (error) {
          console.error('Error toggling like status:', error);
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
        } finally {
          setLoading(false);
        }
      };
  
      const toggleCaption = () => {
        setShowFullCaption(!showFullCaption);
    };

    const renderCaption = () => {
        const wordCount = post.caption.split(" ").length;
        if (wordCount <= 20) {
            return post.caption;
        } else if (showFullCaption) {
            return (
                <>
                    {post.caption} 
                    <span className="text-blue-500 cursor-pointer ml-1" onClick={toggleCaption}>Less</span>
                </>
            );
        } else {
            const truncatedCaption = post.caption.split(" ").slice(0, 20).join(" ");
            return (
                <>
                    {truncatedCaption}...
                    <span className="text-blue-500 cursor-pointer ml-1" onClick={toggleCaption}>More</span>
                </>
            );
        }
    };


    const handleProfile = (userId) => {
      navigate(`/smartsocial/${userId}/`);
    };

    return (
        <>
          <div className="flex justify-between items-center mt-2 mx-2">
            <div className="flex items-center">
              <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
            {isUserLiked ? <LikedIcon /> : <LikeIcon />}
          </div>
              <div className="mx-3 cursor-pointer" onClick={() => handleViewComments(post)} ><CommentIcon /></div>
              {/* <ShareIcon /> */}
            </div>
            {/* <BookmarkBorderOutlinedIcon style={{ fontSie: 22 }} /> */}
          </div>
    
          <div className="flex text-xs mt-2 ml-3.5 text-gray-900">
            <span>{likeCount > 1 ? `${likeCount} likes` : `${likeCount} like`}</span>
          </div>
    
          <div className="flex ml-3.5 mt-0.5">
            <span className="text-black text-sm mr-2 " >
            <span className=" cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
              {post.user.firstName} {post.user.lastName}
              </span>
              <span className="ml-2 text-xs text-gray-700">{renderCaption()}</span>
            </span>
          </div>
    
        </>
      );
    };
    
    export default Postbottomsection;

    