import React from "react";
import LgPost from "./Largescreenpost";
import SmPost from "./smallscreenpost";
// import './style.css'
import { useMediaQuery } from 'react-responsive';


const Postlayout = () => {

  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });


  return (
   
      <div className="flex-1 mt-20 mb-28  sm:mt-20 md:mt-10 lg:mt-10 xl:mt-10 2xl:mt-10  justify-center flex scroll-smooth  post-container overflow-hidden" >
        
         {is2XL ?    <LgPost /> :
        isXL ?    <LgPost /> :
          isLG ?   <LgPost /> :
            isMD ?   <LgPost /> :
              isSM ? <SmPost /> :
              <SmPost />}
    </div>
  );
};

export default Postlayout;
