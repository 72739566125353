
import React from "react";

export const CommentIcon = () => (
    <svg width="25" height="25" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 38C24.7578 38 28.4313 36.9443 31.5558 34.9665C34.6804 32.9886 37.1156 30.1774 38.5537 26.8883C39.9918 23.5992 40.368 19.98 39.6349 16.4884C38.9018 12.9967 37.0922 9.78943 34.435 7.27208C31.7778 4.75474 28.3923 3.04041 24.7067 2.34587C21.0211 1.65134 17.2008 2.0078 13.729 3.37018C10.2572 4.73255 7.28982 7.03966 5.20208 9.99974C3.11433 12.9598 2 16.4399 2 20C2 22.976 2.76 25.78 4.11111 28.254L2 38L12.2874 36C14.8968 37.278 17.8608 38 21 38Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
)


export const ShareIcon = () => (
    <svg width="25" height="25" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 2L2 13.6471L16.5294 20L32.1765 9.41177L21 24.2353L27.7059 38L40 2Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);


export const LikeIcon = () => (
    <svg width="26" height="26" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.6518 0C25.8721 0 22.5628 1.66539 20.5 4.48043C18.4372 1.66539 15.1279 0 11.3482 0C8.33952 0.0034747 5.45503 1.22965 3.32756 3.40952C1.20009 5.5894 0.00339117 8.54494 0 11.6277C0 24.7558 18.9973 35.3821 19.8063 35.8209C20.0195 35.9385 20.2579 36 20.5 36C20.7421 36 20.9805 35.9385 21.1937 35.8209C22.0027 35.3821 41 24.7558 41 11.6277C40.9966 8.54494 39.7999 5.5894 37.6724 3.40952C35.545 1.22965 32.6605 0.0034747 29.6518 0ZM20.5 32.7827C17.1578 30.7873 2.92857 21.697 2.92857 11.6277C2.93148 9.34062 3.81948 7.14803 5.39784 5.5308C6.9762 3.91356 9.11608 3.00368 11.3482 3.00071C14.9083 3.00071 17.8972 4.94367 19.1455 8.0644C19.2559 8.33958 19.4435 8.57495 19.6847 8.74059C19.9259 8.90623 20.2097 8.99467 20.5 8.99467C20.7903 8.99467 21.0741 8.90623 21.3153 8.74059C21.5565 8.57495 21.7441 8.33958 21.8545 8.0644C23.1028 4.93804 26.0917 3.00071 29.6518 3.00071C31.8839 3.00368 34.0238 3.91356 35.6022 5.5308C37.1805 7.14803 38.0685 9.34062 38.0714 11.6277C38.0714 21.682 23.8386 30.7854 20.5 32.7827Z" fill="white"/>
</svg>

);

export const LikedIcon = () => (
<svg width="26" height="26" viewBox="0 0 252 193" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M235.694 23.8453C208.998 -6.24247 161.771 -7.66394 133.136 19.5335L133.037 19.6283C129.06 23.4188 122.845 23.4188 118.918 19.6283L118.819 19.5335C91.6754 -6.432 47.58 -6.52676 20.3372 19.344C-2.82902 41.282 -6.65692 76.7239 11.19 102.832C16.7579 111.029 23.7674 117.331 31.5227 122.116L121.006 191.152C124.188 193.616 128.761 193.616 131.993 191.152L220.879 122.021C228.336 117.283 235.097 111.123 240.516 103.305C257.468 78.9982 255.48 46.1149 235.694 23.8453Z" fill="#FF473E"/>
</svg>
)

export const PostIcon = ({ active }) => (
    <svg width="24" height="24" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24874 0C2.49098 0 1.76426 0.30102 1.22844 0.836838C0.692622 1.37266 0.391602 2.09938 0.391602 2.85714V37.1429C0.391602 37.9006 0.692622 38.6273 1.22844 39.1632C1.76426 39.699 2.49098 40 3.24874 40H37.5345C38.2922 40 39.0189 39.699 39.5548 39.1632C40.0906 38.6273 40.3916 37.9006 40.3916 37.1429V2.85714C40.3916 2.09938 40.0906 1.37266 39.5548 0.836838C39.0189 0.30102 38.2922 0 37.5345 0H3.24874ZM13.2487 17.1429C14.3854 17.1429 15.4755 16.6913 16.2792 15.8876C17.0829 15.0839 17.5345 13.9938 17.5345 12.8571C17.5345 11.7205 17.0829 10.6304 16.2792 9.82668C15.4755 9.02296 14.3854 8.57143 13.2487 8.57143C12.1121 8.57143 11.022 9.02296 10.2183 9.82668C9.41456 10.6304 8.96303 11.7205 8.96303 12.8571C8.96303 13.9938 9.41456 15.0839 10.2183 15.8876C11.022 16.6913 12.1121 17.1429 13.2487 17.1429ZM7.4716 30.6714L12.0373 23.3657C12.1657 23.1602 12.3443 22.9908 12.5562 22.8734C12.7681 22.7559 13.0065 22.6943 13.2487 22.6943C13.491 22.6943 13.7294 22.7559 13.9413 22.8734C14.1532 22.9908 14.3318 23.1602 14.4602 23.3657L18.1887 29.3314L24.1602 19.2257C24.2865 19.0119 24.4664 18.8347 24.6821 18.7116C24.8978 18.5885 25.1418 18.5237 25.3902 18.5237C25.6385 18.5237 25.8826 18.5885 26.0983 18.7116C26.314 18.8347 26.4939 19.0119 26.6202 19.2257L33.403 30.7029C33.5309 30.9197 33.5992 31.1664 33.6011 31.4182C33.6029 31.6699 33.5382 31.9176 33.4135 32.1363C33.2888 32.3549 33.1085 32.5368 32.8909 32.6634C32.6733 32.79 32.4262 32.8568 32.1745 32.8571H18.6087C18.4744 32.8566 18.3407 32.8373 18.2116 32.8C18.0816 32.8376 17.9469 32.8568 17.8116 32.8571H8.68589C8.43088 32.8571 8.18052 32.7889 7.9608 32.6595C7.74108 32.53 7.56001 32.3441 7.4364 32.1211C7.31278 31.8981 7.25111 31.646 7.2578 31.3911C7.2645 31.1362 7.33645 30.8877 7.4716 30.6714Z"
        fill={active ? 'white' : '#9CA3AF'}
      />
    </svg>
  );
  


export const VidsIcon = ({ active }) => (
    <svg width="24" height="24" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.8036 8.518H31.1477V0.424C33.1562 0.933282 35.0032 1.94252 36.5166 3.35774C38.0301 4.77296 39.1609 6.54814 39.8036 8.518ZM9.69209 0.424V8.518H0.942229C1.59176 6.53596 2.73622 4.7524 4.26733 3.33606C5.79844 1.91972 7.66557 0.917427 9.69209 0.424ZM27.6178 0H13.222V8.518H27.6178V0ZM40.3916 12.048H0.400237C0.388723 12.149 0.388723 12.251 0.400237 12.352V27.646C0.40021 30.9138 1.6949 34.0486 4.00089 36.364C6.30687 38.6794 9.43628 39.9868 12.704 40H27.9938C31.2684 39.9947 34.4074 38.6914 36.7227 36.3756C39.038 34.0599 40.3408 30.9207 40.3456 27.646V12.352C40.3725 12.2528 40.388 12.1508 40.3916 12.048ZM27.7358 26.328C27.5515 26.6766 27.2952 26.982 26.9838 27.224L19.9239 32.164C19.5498 32.4257 19.1114 32.5806 18.6559 32.612H18.42C18.0054 32.6182 17.5957 32.5228 17.2265 32.3342C16.8573 32.1456 16.5399 31.8695 16.302 31.53C16.042 31.14 15.902 30.68 15.902 30.21V20.99C15.9 20.53 16.03 20.08 16.28 19.694C16.54 19.34 16.878 19.05 17.268 18.848C17.668 18.668 18.1 18.578 18.5379 18.588C18.9599 18.608 19.3719 18.728 19.7379 18.942L26.7918 23.2C27.1374 23.3989 27.4268 23.6824 27.6329 24.0239C27.8389 24.3653 27.9549 24.7535 27.9698 25.152C28.0018 25.56 27.9198 25.966 27.7338 26.33"
        fill={active ? 'white' : '#9CA3AF'}
      />
    </svg>
  );
  



  export const AudioIcon = ({ active }) => (
    <svg width="24" height="25" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.3916 25C26.0825 25 30.6946 21.6429 30.6946 17.5V7.5C30.6946 3.35714 26.0825 0 20.3916 0C14.7007 0 10.0886 3.35714 10.0886 7.5V17.5C10.0886 21.6429 14.7007 25 20.3916 25ZM40.3916 17.4107C40.3916 17.2143 40.1734 17.0536 39.9068 17.0536H36.2704C36.0037 17.0536 35.7855 17.2143 35.7855 17.4107C35.7855 23.6741 28.8946 28.75 20.3916 28.75C11.8886 28.75 4.99766 23.6741 4.99766 17.4107C4.99766 17.2143 4.77948 17.0536 4.51281 17.0536H0.87645C0.609783 17.0536 0.391602 17.2143 0.391602 17.4107C0.391602 24.942 8.06433 31.1562 17.9674 32.0357V36.6071H9.1613C8.331 36.6071 7.66433 37.2455 7.66433 38.0357V39.6429C7.66433 39.8393 7.83403 40 8.04009 40H32.7431C32.9492 40 33.1189 39.8393 33.1189 39.6429V38.0357C33.1189 37.2455 32.4522 36.6071 31.6219 36.6071H22.5734V32.058C32.5916 31.2545 40.3916 25.0045 40.3916 17.4107Z"
        fill={active ? 'white' : '#9CA3AF'}
      />
    </svg>
  );
  

export const ViewIcon = () => (

    <svg width="30" height="30" viewBox="0 0 59 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.0502 20.1933C38.0502 22.3748 37.1494 24.4671 35.546 26.0097C33.9425 27.5523 31.7678 28.4189 29.5002 28.4189C27.2326 28.4189 25.0579 27.5523 23.4544 26.0097C21.851 24.4671 20.9502 22.3748 20.9502 20.1933C20.9502 18.0117 21.851 15.9194 23.4544 14.3768C25.0579 12.8342 27.2326 11.9676 29.5002 11.9676C31.7678 11.9676 33.9425 12.8342 35.546 14.3768C37.1494 15.9194 38.0502 18.0117 38.0502 20.1933Z" stroke="white" strokeWidth="4"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1 20.1932C5.56 8.9597 16.2076 1 29.5 1C42.7924 1 53.44 8.9597 58 20.1932C53.44 31.4267 42.7924 39.3864 29.5 39.3864C16.2076 39.3864 5.56 31.4267 1 20.1932Z" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    );


    export const PauseIcon = () => (
        <svg width="22" height="22" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.3013 25.8324C41.2329 23.2396 41.2329 16.7604 36.3013 14.1676L11.1102 0.915338C6.17264 -1.68283 0 1.55949 0 6.74774V33.2523C0 38.4405 6.17264 41.6828 11.1102 39.0847L36.3013 25.8324Z" fill="white"/>
        </svg>
        
        )

        export const MultiIcon = () => (
            <svg width="25" height="25" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.3917 12.4917C23.0812 12.4917 23.7425 12.2177 24.2301 11.7301C24.7177 11.2425 24.9917 10.5812 24.9917 9.89167C24.9917 9.2021 24.7177 8.54078 24.2301 8.05319C23.7425 7.56559 23.0812 7.29167 22.3917 7.29167C21.7021 7.29167 21.0408 7.56559 20.5532 8.05319C20.0656 8.54078 19.7917 9.2021 19.7917 9.89167C19.7917 10.5812 20.0656 11.2425 20.5532 11.7301C21.0408 12.2177 21.7021 12.4917 22.3917 12.4917ZM6.77083 0C4.9751 0 3.25291 0.713353 1.98313 1.98313C0.713353 3.25291 0 4.9751 0 6.77083V25.5208C0 27.3166 0.713353 29.0388 1.98313 30.3085C3.25291 31.5783 4.9751 32.2917 6.77083 32.2917H25.5208C27.3166 32.2917 29.0388 31.5783 30.3085 30.3085C31.5783 29.0388 32.2917 27.3166 32.2917 25.5208V6.77083C32.2917 4.9751 31.5783 3.25291 30.3085 1.98313C29.0388 0.713353 27.3166 0 25.5208 0H6.77083ZM3.125 6.77083C3.125 4.75833 4.75833 3.125 6.77083 3.125H25.5208C27.5333 3.125 29.1667 4.75833 29.1667 6.77083V25.5208C29.1667 26.0021 29.0729 26.4625 28.9042 26.8833L19.3479 17.9437C18.4795 17.1315 17.3349 16.6796 16.1458 16.6796C14.9568 16.6796 13.8121 17.1315 12.9438 17.9437L3.3875 26.8812C3.21626 26.448 3.12724 25.9867 3.125 25.5208V6.77083ZM17.2125 20.225L26.5979 29.0042C26.2562 29.1097 25.8972 29.1639 25.5208 29.1667H6.77083C6.39444 29.1667 6.03542 29.1125 5.69375 29.0042L15.0771 20.225C15.3666 19.954 15.7483 19.8033 16.1448 19.8033C16.5413 19.8033 16.923 19.954 17.2125 20.225ZM11.9792 37.5C10.8418 37.5005 9.72261 37.2144 8.72494 36.6683C7.72727 36.1221 6.88327 35.3334 6.27083 34.375H26.5625C28.6345 34.375 30.6216 33.5519 32.0868 32.0868C33.5519 30.6216 34.375 28.6345 34.375 26.5625V6.27292C35.3334 6.88535 36.1221 7.72936 36.6683 8.72702C37.2144 9.72469 37.5005 10.8439 37.5 11.9813V26.5646C37.4994 29.465 36.3469 32.2465 34.2957 34.2972C32.2446 36.3479 29.4629 37.5 26.5625 37.5H11.9792Z" fill="white"/>
</svg>

        )