import React, { useEffect, useState, useRef } from 'react';
import smartGearImage from '../../assets/images/SMARTGEARGREY1.png';
import smartIcon from '../../assets/images/SMARTGREY.png';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { setUser, clearUser } from '../Action/user';
import { PROFILEPHOTO_URL } from '../../Config/config';
import './style.css';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dropdownRef = useRef(null);
  const userData = useSelector(state => state.user1.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (storedUserData) {
      dispatch(setUser(storedUserData));
    } else {
      dispatch(clearUser());
    }
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen1(false);
      }
    };

    if (isDropdownOpen1) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen1]);

  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearUser());
    setIsDropdownOpen1(false);
    navigate('/');
  };

  const handleProfile = (userId) => {
    navigate(`/smartsocial/${userId}/`);
  };

  return (
    <nav className="bg-blue-900 fixed top-0 z-40 w-full">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center justify-between rtl:space-x-reverse">
          <img src={smartGearImage} className="h-12" alt="Smartie Logo" />
          <img src={smartIcon} className="h-10" alt="Smart Logo" />
        </a>
        <div className="flex items-center md:order-2 space-x-1 md:space-x-2 rtl:space-x-reverse">
          {isLoading ? (
            <div className="loader"><CircularProgress /></div>
          ) : userData ? (
            <div className="relative">
              <button
                onClick={toggleDropdown1}
                type="button"
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="w-12 h-12 rounded-full"
                  src={`${PROFILEPHOTO_URL}/${userData.profilePhoto}`}
                  alt="Profile"
                />
              </button>
              {isDropdownOpen1 && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="px-3 py-4">
                    <span className="block text-sm text-gray-900 cursor-pointer" onClick={() => handleProfile(userData.id)}>
                      {userData.firstName} {userData.lastName}</span>
                    <span className="block text-sm text-gray-500 truncate">{userData.email}</span>
                  </div>
                  <div className="border border-gray-300 w-full"></div>
                  <div className="">
                    <div className="my-4">
                      <a href="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Home
                      </a>
                    </div>
                    <div className="border border-gray-200 w-full"></div>
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 mt-1 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <a className="button" href="/login">Login</a>
            </>
          )}
          <button
            onClick={toggleSidebar}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 z-40 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400"
            aria-controls="mega-menu"
            aria-expanded={isSidebarOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div id="mega-menu-dropdown" className={`items-center justify-between  md:w-auto md:order-1 hidden md:flex`}>
          <ul className="flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse">
            <li>
              <a href="/" className="block py-2 px-3 border-gray-100 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 hover:text-blue-500 text-white" aria-current="page">Home</a>
            </li>
            <li>
              <a href="/realestate" className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
                Real Estate
              </a>
            </li>
            <li>
              <a href="/smartsocial" className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
                Smart Social
              </a>
            </li>
          </ul>
        </div>
      </div>

     
      {isSidebarOpen && (
        <div className="fixed inset-0 flex z-50">
          <div className="fixed inset-0 bg-black bg-opacity-50" onClick={toggleSidebar}></div>
          <div className="relative bg-white w-64 h-full shadow-xl p-4">
            <button onClick={toggleSidebar} className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="mt-8 space-y-4">
              <li>
                <a href="/" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Home
                </a>
              </li>
              <li>
                <a href="/realestate" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Real Estate
                </a>
              </li>
              <li>
                <a href="/smartsocial" className="block py-2 px-3 text-gray-700 hover:bg-gray-100">
                  Smart Social
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;



// import React, { useEffect, useState } from 'react';
// import smartGearImage from '../../assets/images/SMARTGEARGREY1.png';
// import smartIcon from '../../assets/images/SMARTGREY.png';
// import { connect } from "react-redux";
// import './style.css';
// import { PROFILEPHOTO_URL } from '../../Config/config';
// import { useSelector } from 'react-redux';
// import { setUser } from '../Action/user'; 
// import { useDispatch } from 'react-redux';
// import { clearUser } from '../Action/user'; 
// import CircularProgress from '@mui/material/CircularProgress';
// import { useNavigate } from 'react-router-dom';


// const Navbar = () => {

//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
//   const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
//   const [isCompanyDropdownOpen1, setIsCompanyDropdownOpen1] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const userData = useSelector(state => state.user1.userData);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedUserData = JSON.parse(localStorage.getItem('userData'));
//     if (storedUserData) {
//       dispatch(setUser(storedUserData));
//     } else {
//       dispatch(clearUser());
//     }
//     setIsLoading(false);
//   }, [dispatch]);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const toggleDropdown1 = () => {
//     setIsDropdownOpen1(!isDropdownOpen1);
//   };

//   const openCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(true);
//   };
//   const closeCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(false);
//   };
//   const openCompanyDropdown1 = () => {
//     setIsCompanyDropdownOpen1(true);
//   };
//   const closeCompanyDropdown1 = () => {
//     setIsCompanyDropdownOpen1(false);
//   };
//   const toggleCompanyDropdown = () => {
//     setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
//   };
//   const toggleCompany = () => {
//     setIsCompanyDropdownOpen1(!isCompanyDropdownOpen1);
//   };
//   const closeDropdown = () => {
//     setIsDropdownOpen(false);
//   };

//   const handleLogout = () => {
//     localStorage.clear();
//     dispatch(clearUser());
//     closeDropdown();
//     navigate('/');
//   };

//   return (
//     <nav className={`bg-blue-900 fixed top-0 z-40 w-full`}>
//       <div className="flex flex-wrap items-center justify-between mx-auto p-4">
//         <a href="/" className="flex items-center justify-between rtl:space-x-reverse">
//           <img src={smartGearImage} className="h-12" alt="Smartie Logo" />
//           <img src={smartIcon} className="h-10" alt="Smart Logo" />
//         </a> 
//         <div className="flex items-center md:order-2 space-x-1 md:space-x-2 rtl:space-x-reverse">
//         {isLoading ? (
//             <div className="loader"><CircularProgress /></div>
//           ) : userData ? (
//             <div className="relative">
//               <button
//                 onClick={toggleDropdown1}
//                 type="button"
//                 className="flex text-sm bg-gray-800  rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
//               >
//                 <span className="sr-only">Open user menu</span>
//                 <img
//                   className="w-12 h-12 rounded-full"
//                   src={`${PROFILEPHOTO_URL}/${userData.profilePhoto}`}
//                   alt="Profile"
//                 />
//               </button>
//               {isDropdownOpen1 && (
//                 <div className="absolute right-0  z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
//                     <div class="px-3 py-4">
//                        <span class="block text-sm text-gray-90">{userData.firstName} {userData.lastName} </span>
//                         <span class="block text-sm  text-gray-500 truncate ">{userData.email}</span>
//                       </div>
//                       <div className='border  border-gray-300 w-full'></div>
//                   <div className="py-3">
//                     <a
//                       href="#"
//                       className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//                     >
//                       Home
//                     </a>
//                     <a
//                       href="#"
//                       className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
//                     >
//                       Settings
//                     </a>
//                     {/* <a
//                       href="#"
//                       className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
//                     >
//                       Earnings
//                     </a> */}
//                      <div className='border  border-gray-200 w-full'></div>
//                     <button
//                       onClick={handleLogout}
//                       className="block w-full text-left px-4 py-2 mt-1 text-sm text-gray-700 hover:bg-gray-100"
//                     >
//                       Sign out
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </div>
//           ) : (
//             <>
//               {/* <a href="/login" className="text-white hover:bg-blue-800 font-medium rounded-lg text-sm px-4 py-2 md:px-5 md:py-2.5 focus:outline-none hidden sm:hidden lg:block">Sign up</a> */}
//               <a className="button" href="/login">Login</a>
//             </>
//           )}
//           <button onClick={toggleDropdown} type="button" className="inline-flex items-center p-2 w-10 h-10 z-40 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400" aria-controls="mega-menu" aria-expanded={isDropdownOpen}>
//             <span className="sr-only">Open main menu</span>
//             <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
//               <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
//             </svg>
//           </button>
//         </div>
//         <div id="mega-menu-dropdown" className={`items-center justify-between md:flex md:w-auto md:order-1 ${isDropdownOpen ? 'flex' : 'hidden'}`}>
//           <ul className="flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse">
//             <li>
//               <a href="/" className="block py-2 px-3 border-gray-100 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 hover:text-blue-500 text-white" aria-current="page">Home</a>
//             </li>
//             <li >
//               <a href="/realestate"   className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
//                 Realestate
//               </a>
//               </li>
//              <li >
//               <a href="/smartsocial"   className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
//                 Smart Social 
//               </a>
//               </li>
             
//             {/* <li onMouseEnter={openCompanyDropdown} onMouseLeave={closeCompanyDropdown}>
//               <button href="/smartsocial/vids" id="mega-menu-dropdown-button"  className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
//                 Smart Social <svg className={`w-2.5 h-2.5 ms-3 ${isCompanyDropdownOpen ? 'transform rotate-180' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
//                   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
//                 </svg>
//               </button>
//               <div id="mega-menu-dropdown" className={`absolute z-10 h-[100px] items-center justify-start shadow-2xl flex w-60 grid-cols-2 text-md bg-white rounded-lg md:grid-cols-3 border border-black ${isCompanyDropdownOpen ? '' : 'hidden'}`}>
//   <div>
//     <ul className="space-y-4">
//       <li>
//         <a href="/smartsocial/vids" className="text-black mx-4 hover:text-blue-600">VIDS</a>
//       </li>
//       <li>
//         <a href="/smartsocial/post" className="text-black mx-4 hover:text-blue-600">POST</a>
//       </li>
    
//     </ul>
//   </div>
// </div>

//             </li> */}
//             {/* <li onMouseEnter={openCompanyDropdown1} onMouseLeave={closeCompanyDropdown1}>
//               <button id="mega-menu-dropdown-button" onClick={toggleCompany} onBlur={closeDropdown} className="flex items-center justify-between w-full py-2 px-3 font-medium border-gray-100 hover:text-blue-600 md:w-auto hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 text-white">
//                 Smart Real Estate <svg className={`w-2.5 h-2.5 ms-3 ${isCompanyDropdownOpen1 ? 'transform rotate-180' : ''}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
//                   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
//                 </svg>
//               </button>
//               <div id="mega-menu-dropdown" className={`absolute z-10 grid w-60 grid-cols-2 text-md bg-white border rounded-lg shadow-lg md:grid-cols-3 ${isCompanyDropdownOpen1 ? '' : 'hidden'}`}>
//                 <div className="p-4 pb-0 text-gray-900 md:pb-4">
//                   <ul className="space-y-4">
//                     <li>
//                       <a href="/realestate" className="text-black hover:text-blue-600 whitespace-nowrap">FSBO</a>
//                     </li>
//                     <li>
//                       <a href="/realestate" className="text-black hover:text-blue-600 whitespace-nowrap">FOR SALE</a>
//                     </li>
//                     <li>
//                       <a href="/realestate" className="text-black hover:text-blue-600 whitespace-nowrap">FOR RENTAL</a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </li> */}
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// }



// export default Navbar;