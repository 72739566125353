import {
    FETCH_REPLIES_REQUEST,
    FETCH_REPLIES_SUCCESS,
    FETCH_REPLIES_FAILURE,
  } from '../../Action/smartsocial/postrepliescommentaction';
  
  const initialState = {
    loading: {},
    replies: {},
    error: {},
  };
  
  const replyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REPLIES_REQUEST:
        return {
          ...state,
          loading: { ...state.loading, [action.payload]: true },
          error: { ...state.error, [action.payload]: '' },
        };
      case FETCH_REPLIES_SUCCESS:
        return {
          ...state,
          loading: { ...state.loading, [action.payload.commentId]: false },
          replies: { ...state.replies, [action.payload.commentId]: action.payload.replies },
        };
      case FETCH_REPLIES_FAILURE:
        return {
          ...state,
          loading: { ...state.loading, [action.payload.commentId]: false },
          error: { ...state.error, [action.payload.commentId]: action.payload.error },
        };
      default:
        return state;
    }
  };
  
  export default replyReducer;
  