import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProperties, updatePageNumber } from '../../Action/propertyAction';
import Sortskeleton from './sortskeleton';
import sortOptions from '../../Config/sortoptions';
import './style.css';

const Sort = ({ loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = localStorage.getItem('selectedOption');
    return storedOption ? storedOption : 'Relevant Listing';
  });
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const totalProperties = useSelector(state => state.properties.totalProperties);
  const state = useSelector(state => state.properties.state);
  const state_code = useSelector(state => state.properties.state_code);

  useEffect(() => {
    const sortValue = localStorage.getItem('sort_by');
    const storedOption = Object.keys(sortOptions).find(key => sortOptions[key] === sortValue);
    setSelectedOption(storedOption || 'Relevant Listing');
  }, []);
  

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    if (selectedOption !== option) {
      setSelectedOption(option);
      clearLocalStorage();
      const sortValue = sortOptions[option];
      localStorage.setItem('sort_by', sortValue);
      dispatch(updatePageNumber(0));  
      dispatch(fetchProperties('', '', 0, sortValue));
      localStorage.setItem('currentPageNumber',0); 
    
    }
   
    setIsOpen(false);
  };
  
  const clearLocalStorage = () => {
    Object.keys(localStorage)
        .filter(key => key.startsWith('properties_page_'))
        .forEach(key => localStorage.removeItem(key));

    // localStorage.removeItem('properties_page_0');
    // localStorage.removeItem('currentPageNumber')
};


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  if (loading || !totalProperties || !state || !state_code) {
    return <Sortskeleton />;
  }

  const options = [
    'Relevant Listing',
    'Newest Listing',
    'Lowest Price',
    'Highest Price',
    'Lot Size',
    'Largest sqft',
    'Low Photo Count',
    'High Photo Count',
    'More Bed',
    'Less Bed'
  ];

  return (
    <div>
      <div>
        <h1 className="ml-6 mt-4 text-3xl font-semibold cursor-default">
          {state} {state_code} Relevant Listings
        </h1>
      </div>
      <div className="relative mt-4 ml-6" ref={dropdownRef}>
        <span className="text-gray-400 text-sm mt-2 ml-2 cursor-default ">{totalProperties} Homes</span>
        <button
          id="dropdownDefaultButton"
          onClick={toggleDropdown}
          className="xl:ml-28 lg:ml-[100px] md:ml-4 sm:ml-4 ml-4 text-black bg-white hover:text-blue-700 font-medium rounded-lg text-sm px-2 py-1.5 text-center inline-flex items-center hover:bg-gray-100"
          type="button"
        >
          <span className="text-gray-400">Sort by : </span> <span className="">&nbsp;{selectedOption}</span>

          <svg
            className={`w-2.5 h-2.5 ms-3 ${isOpen ? 'transform rotate-180' : ''}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
          </svg>
        </button>
        {isOpen && (
          <div
            id="dropdown"
            className="ml-36 xl:ml-56 lg:ml-52  md:ml-32 p-2 shadow-2xl sm:ml-40 absolute z-10 scrollbar-hide border bg-white divide-y divide-gray-100 rounded-lg max-h-80 overflow-y-auto"
            style={{ top: 'calc(100% + 2px)', left: 0 }}
          >
            <ul className="py-0 text-md text-gray-700" aria-labelledby="dropdownDefaultButton">
              {options.map(option => (
                <li key={option} className='hover:bg-gray-200'>
                  <button
                    onClick={() => handleOptionSelect(option)}
                    className={`block px-2 py-2  text-black    whitespace-nowrap ${
                      selectedOption === option ? 'font-bold' : ''
                    }`}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sort;
