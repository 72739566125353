import axios from 'axios';
import { API_URL } from '../../../../Config/config';

export const fetchDropdownData = async () => {
  try {
    let prop_status = localStorage.getItem('prop_status');
    
    if (!prop_status) {
      prop_status = 'for_sale';
    }
    
    const token = localStorage.getItem('token');

    if (!token) {
      return null;
    }
    
    const response = await axios.get(`${API_URL}/property/v4/filter/dropdowns?category=realtor`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    let priceMin, priceMax;

    if (prop_status === 'for_sale') {
      priceMin = response.data.data.salePrice.priceMin;
      priceMax = response.data.data.salePrice.priceMax;
    } else if (prop_status === 'for_rent') {
      priceMin = response.data.data.rentPrice.priceMin;
      priceMax = response.data.data.rentPrice.priceMax;
    } 

    const livingAreaMin = response.data.data.livingAreaMin
    const livingAreaMax = response.data.data.livingAreaMax
    const lotsizemin = response.data.data.lotSizeMin
    const lotsizemax = response.data.data.lotSizeMax

    const hometype = response.data.data.homeType;
  
    return { priceMin, priceMax, livingAreaMin, livingAreaMax, hometype, lotsizemin, lotsizemax };
  } catch (error) {
    console.error('Error fetching dropdown data:', error);
    return null;
  }
};
