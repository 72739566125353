// import React, { useState, useEffect, useRef, useCallback } from "react";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import { CommentIcon, LikeIcon, MuteIcon, PauseIcon, ShareIcon, ViewIcon } from "../icon";
// import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
// import axios from "axios";
// import { CircularProgress } from '@mui/material';
// import VolumeOffIcon from '@mui/icons-material/VolumeOff';
// import VolumeUpIcon from '@mui/icons-material/VolumeUp';
// import VideoModal from '../../UserProfile/Mdlgscreen/VidsModal/VideoModal';


// const Vids = () => {
//   const [vids, setVids] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [activeVideoIndex, setActiveVideoIndex] = useState(null);
//   const [pausedVideos, setPausedVideos] = useState({});
//   const [mutedVideos, setMutedVideos] = useState({});
//   // const [mutedAll, setMutedAll] = useState(false); 
//   const [selectedVideo, setSelectedVideo] = useState(null);

//   const [loadingStates, setLoadingStates] = useState({}); 
//   const videoRefs = useRef([]);

//   const fetchVids = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('Token not found in localStorage');
//     }
  
//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/vids/v2/public-feed?page_number=${pageNumber}`,
//       headers: { 
//         'Authorization': `Bearer ${token}`
//       }
//     };
  
//     try {
//       const response = await axios.request(config);
//       const newVids = response.data.data;
  
//       const uniqueNewVids = newVids.filter(newVid => 
//         !vids.some(vid => vid.id === newVid.id)
//       );
  
//       setVids(prevVids => [...prevVids, ...uniqueNewVids]);
//       setLoadingStates(prevLoadingStates => ({
//         ...prevLoadingStates,
//         ...uniqueNewVids.reduce((acc, vid) => ({
//           ...acc,
//           [vid.id]: true
//         }), {})
//       }));
//       setHasMore(newVids.length > 0);
  
//       if (newVids.length === 0 || newVids.length < 10) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };
  
//   useEffect(() => {
//     setLoading(true);
//     fetchVids(0);
//   }, []);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchVids(page);
//     }
//   }, [page]);

//   const handleScroll = useCallback(() => {
//     if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
//         && hasMore && !loading && !loadingMore) {
//       setPage(prevPage => prevPage + 1);
//     }
//   }, [hasMore, loading, loadingMore]);

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [handleScroll]);

//   const observer = useRef(
//     new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         const index = parseInt(entry.target.dataset.index);
//         if (entry.isIntersecting) {
//           setActiveVideoIndex(index);
//         }
//       });
//     }, { threshold: 0.75 })
//   );

//   useEffect(() => {
//     const { current: currentObserver } = observer;
//     const currentVideoRefs = videoRefs.current;

//     currentVideoRefs.forEach((video, index) => {
//       if (video) {
//         currentObserver.observe(video);
//       }
//     });

//     return () => {
//       currentVideoRefs.forEach((video) => {
//         if (video) {
//           currentObserver.unobserve(video);
//         }
//       });
//     };
//   }, [vids]);

//   useEffect(() => {
//     videoRefs.current.forEach((video, index) => {
//       if (video) {
//         if (index === activeVideoIndex && !pausedVideos[index]) {
          
//           if (video.paused) {
//             video.play().catch((error) => {
//             });
//           }
//         } else {
//           if (!video.paused) {
//             video.pause();
//           }
//         }
//       }
//     });
//   }, [activeVideoIndex, pausedVideos]);

//   const handleVideoClick = (index) => {
//     setPausedVideos(prevPausedVideos => {
//       const newPausedStatus = !prevPausedVideos[index];
//       const video = videoRefs.current[index];
      
//       if (video) {
//         if (newPausedStatus) {
//           video.pause();
//         } else {
//           video.play().catch((error) => {
//           });
//         }
//       }
      
//       return { ...prevPausedVideos, [index]: newPausedStatus };
//     });
//   };

//   const handleMuteClick = (index) => {
//     setMutedVideos(prevMutedVideos => {
//       const newMutedStatus = !prevMutedVideos[index];
//       const video = videoRefs.current[index];
      
//       if (video) {
//         video.muted = newMutedStatus;
//       }
      
//       return { ...prevMutedVideos, [index]: newMutedStatus };
//     });
//   };

//   // const handleMuteClick = () => {
//   //   setMutedAll(prevMutedAll => {
//   //     const newMuteStatus = !prevMutedAll;
//   //     videoRefs.current.forEach(video => {
//   //       if (video) {
//   //         video.muted = newMuteStatus;
//   //       }
//   //     });
//   //     return newMuteStatus;
//   //   });
//   // };

//   const handleLoadedData = (index) => {
//     setLoadingStates(prev => ({ ...prev, [index]: false }));
//   };

//   const handleWaiting = (index) => {
//     setLoadingStates(prev => ({ ...prev, [index]: true }));
//   };

//   const handlePlaying = (index) => {
//     setLoadingStates(prev => ({ ...prev, [index]: false }));
//   };

//   const handleEnded = (index) => {
//     const video = videoRefs.current[index];
//     if (video) {
//       video.currentTime = 0; 
//       video.play().catch((error) => {
//         console.error("Error playing video:", error);
//       });
//     }
//   };

//   const openModal = (video) => {
//     setSelectedVideo(video);
//     localStorage.setItem('vidsId', video.id);
//   };

//   const closeModal = () => {
//     setSelectedVideo(null);
//   };

//   return (
//     <>
//     <div className="relative mt-0 2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-44 sm:ml-0 ml-0">
//       {loading && vids.length === 0 ? (
//         <div className="mt-24 flex justify-center">
//           <CircularProgress />
//         </div>
//       ) : (
//         <>
//           <div>
//             {vids.map((video, index) => (
//               <div
//                 key={index}
//                 className="flex bg-black w-[350px] sm:w-[410px] md:w-[410px] lg:w-[480px] xl:w-[480px] 2xl:w-[480px] 2xl:h-[730px] xl:h-[730px] lg:h-[730px] md:h-[650px] sm:h-[550px] h-[500px] rounded-sm border border-black shadow-2xl flex-col relative mb-4"
//                 onClick={() => handleVideoClick(index)}
//               >
//                 <div className="h-[730px]">
//                   <video
//                     ref={el => videoRefs.current[index] = el}
//                     data-index={index}
//                     src={`${PROFILEPHOTO_URL}/${video.url}`}
//                     className="object-cover w-full h-full rounded-sm"
//                     muted={mutedVideos[index] || false}
//                     // muted={mutedAll  || false}
//                     playsInline
//                     onLoadedData={() => handleLoadedData(index)}
//                     onWaiting={() => handleWaiting(index)}
//                     onPlaying={() => handlePlaying(index)}
//                     onEnded={() => handleEnded(index)}
//                   />
//                   {loadingStates[index] && (
//                     <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/50 rounded-full w-14 h-14 flex items-center justify-center">
//                       <CircularProgress />
//                     </div>
//                   )}
//                 </div>
//                 <div className="absolute bottom-12 ml-4 flex items-center mb-4">
//                   <div className="bg-black rounded-full w-10 h-10 overflow-hidden">
//                     <img 
//                       src={`${PROFILEPHOTO_URL}/${video.user.profilePhoto}`} 
//                       alt="Profile" 
//                       className="object-cover w-full h-full" 
//                     />
//                   </div>
//                   <span className="ml-2 text-white font-semibold">{video.user.irstName} {video.user.lastName}</span>
//                   <div className="flex justify-center">f
//                     {/* <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg border border-black text-sm px-3 py-1 ml-2">Follow</button> */}
//                   </div>
//                 </div>
//                 <div className="absolute bottom-8 text-white text-sm ml-6">
//                   <span>{video.caption}</span>
//                 </div>
//                 <div 
//                   className="text-white absolute top-0 mt-4 right-3.5 bg-black/40 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
//                   onClick={(e) => {
//                     e.stopPropagation(); 
//                     handleMuteClick(index);
//                     // handleMuteClick();
//                   }}
//                 >
//                   {/* {mutedAll ? <VolumeOffIcon /> : <VolumeUpIcon />} */}
//                   {mutedVideos[index] ? <VolumeOffIcon /> : <VolumeUpIcon />}
//                 </div>
//                 <div className="text-white absolute bottom-28 right-3.5 mb-32 flex flex-col items-center">
//                   <div className="relative flex items-center justify-center">
//                     <ViewIcon />
//                   </div>
//                   <span className="text-white text-xs font-thin">{video.viewCount}</span>
//                 </div>
//                 <div className="text-white absolute bottom-24 right-3.5 mb-24 flex flex-col items-center">
//                   <div className="relative flex items-center justify-center">
//                     <LikeIcon />
//                   </div>
//                   <span className="text-white text-xs font-thin">{video.likeCount}</span>
//                 </div>
//                 <div className="text-white absolute bottom-16 right-3.5 mb-20 flex flex-col items-center cursor-pointer" onClick={() => openModal(video)}> 
//                   <div className="relative flex items-center justify-center">
//                     <CommentIcon />
//                   </div>
//                   <span className="text-white text-xs font-thin">{video.commentCount}</span>
//                 </div>
//                 <div className="text-white absolute bottom-10 right-4 mb-16">
//                   <ShareIcon />
//                 </div>
//                 <div className="text-white absolute bottom-6 right-3.5 mb-10">
//                   <BookmarkBorderOutlinedIcon style={{ fontSize: 20 }} />
//                 </div>
//                 <div className="text-white absolute bottom-0 mb-6 right-3">
//                   <MoreVertIcon style={{ fontSize: 25 }} />
//                 </div>
//                 {pausedVideos[index] && (
//                   <div className="text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/40 rounded-full w-14 h-14 flex items-center justify-center">
//                     <PauseIcon />
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//           {loadingMore && (
//             <div className="mt-8 flex justify-center">
//               <CircularProgress />
//             </div>
//           )}
//           {!loadingMore && !hasMore && vids.length > 0 && (
//             <div className="mt-4 text-gray-400 text-center">No more Vids.</div>
//           )}
//         </>
//       )}
//     </div>
//     {selectedVideo && (
//       <VideoModal video={selectedVideo} onClose={closeModal} />
//     )}
//     </>
//   );
// };

// export default Vids;

import React, { useState, useEffect, useRef, useCallback } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { CommentIcon, LikeIcon, MuteIcon, PauseIcon, ShareIcon, ViewIcon, ExpandIcon , LikedIcon} from "../icon";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import axios from "axios";
import { CircularProgress } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VideoModal from '../../UserProfile/Mdlgscreen/VidsModal/VideoModal';
import './style.css'
import Vidsskeleton from "./Skeleton/vidsskeleton";
import Vidskeleton from "./Skeleton/vidskeleton";
import { useNavigate } from "react-router-dom";


const Vids = () => {
  const [vids, setVids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);
  const [pausedVideos, setPausedVideos] = useState({});
  // const [mutedVideos, setMutedVideos] = useState({});
  const [mutedAll, setMutedAll] = useState(false); 
  const [selectedVideo, setSelectedVideo] = useState(null);
  const navigate = useNavigate();
  const [wasPlaying, setWasPlaying] = useState(false);

  const [loadingStates, setLoadingStates] = useState({}); 
  const videoRefs = useRef([]);

  const scrollContainerRef = useRef(null);

  const [isUserLiked, setIsUserLiked] = useState({});
  const [likeCount, setLikeCount] = useState({});


  const fetchVids = async (pageNumber) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }
  
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/vids/v2/public-feed?page_number=${pageNumber}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };
  
    try {
      const response = await axios.request(config);
      const newVids = response.data.data;
  
      const uniqueNewVids = newVids.filter(newVid => 
        !vids.some(vid => vid.id === newVid.id)
      );
  
      setVids(prevVids => [...prevVids, ...uniqueNewVids]);
      setLoadingStates(prevLoadingStates => ({
        ...prevLoadingStates,
        ...uniqueNewVids.reduce((acc, vid) => ({
          ...acc,
          [vid.id]: true
        }), {})
      }));
      setHasMore(newVids.length > 0);
  
      if (newVids.length === 0 || newVids.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };
  
  useEffect(() => {
    setLoading(true);
    fetchVids(0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      setLoadingMore(true);
      fetchVids(page);
    }
  }, [page]);

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (container) {
      if (container.scrollHeight - container.scrollTop <= container.clientHeight + 50 && hasMore && !loading && !loadingMore) {
        setPage(prevPage => prevPage + 1);
      }
    }
  }, [hasMore, loading, loadingMore]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = parseInt(entry.target.dataset.index);
        if (entry.isIntersecting) {
          setActiveVideoIndex(index);
        }
      });
    }, { threshold: 0.75 })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    const currentVideoRefs = videoRefs.current;

    currentVideoRefs.forEach((video, index) => {
      if (video) {
        currentObserver.observe(video);
      }
    });

    return () => {
      currentVideoRefs.forEach((video) => {
        if (video) {
          currentObserver.unobserve(video);
        }
      });
    };
  }, [vids]);

  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === activeVideoIndex && !pausedVideos[index]) {
          
          if (video.paused) {
            video.play().catch((error) => {
            });
          }
        } else {
          if (!video.paused) {
            video.pause();
          }
        }
      }
    });
  }, [activeVideoIndex, pausedVideos]);

  const handleVideoClick = (index) => {
    setPausedVideos(prevPausedVideos => {
      const newPausedStatus = !prevPausedVideos[index];
      const video = videoRefs.current[index];
      
      if (video) {
        if (newPausedStatus) {
          video.pause();
        } else {
          video.play().catch((error) => {
          });
        }
      }
      
      return { ...prevPausedVideos, [index]: newPausedStatus };
    });
  };

  // const handleMuteClick = (index) => {
  //   setMutedVideos(prevMutedVideos => {
  //     const newMutedStatus = !prevMutedVideos[index];
  //     const video = videoRefs.current[index];
      
  //     if (video) {
  //       video.muted = newMutedStatus;
  //     }
      
  //     return { ...prevMutedVideos, [index]: newMutedStatus };
  //   });
  // };

  const handleMuteClick = () => {
    setMutedAll(prevMutedAll => {
      const newMuteStatus = !prevMutedAll;
      videoRefs.current.forEach(video => {
        if (video) {
          video.muted = newMuteStatus;
        }
      });
      return newMuteStatus;
    });
  };

  const handleLoadedData = (index) => {
    setLoadingStates(prev => ({ ...prev, [index]: false }));
  };

  const handleWaiting = (index) => {
    setLoadingStates(prev => ({ ...prev, [index]: true }));
  };

  const handlePlaying = (index) => {
    setLoadingStates(prev => ({ ...prev, [index]: false }));
  };

  const handleEnded = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      video.currentTime = 0; 
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
  };

  const openModal = (video) => {
    const videoElement = videoRefs.current[activeVideoIndex];
  if (videoElement && !videoElement.paused) {
    videoElement.pause();  
    setWasPlaying(true);
  } else {
    setWasPlaying(false); 
  }

    setSelectedVideo(video);
    localStorage.setItem('vidsId', video.id);
  };

  const closeModal = () => {
    const videoElement = videoRefs.current[activeVideoIndex];
  
  if (videoElement && wasPlaying) {
    videoElement.play();
  }

    setSelectedVideo(null);
  };

  const handleLikeClick = async (videoId) => {
    if (loading) return;
  
    setLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in local storage');
    }
  
    try {
      const url = `${API_URL}/vids/v2/like/${videoId}`;
      const config = {
        method: 'post',
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      setVids(prevVids => 
        prevVids.map(vid => {
          if (vid.id === videoId) {
            const isLiked = !vid.isUserLiked;
            const newLikeCount = isLiked ? vid.likeCount + 1 : vid.likeCount - 1;
  
            return {
              ...vid,
              isUserLiked: isLiked,
              likeCount: newLikeCount,
            };
          }
          return vid;
        })
      );
  
      const response = await axios.request(config);
  
    } catch (error) {
      console.error('Error toggling like status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfile = (userId) => {
    navigate(`/smartsocial/${userId}/`);
  };

  return (
    <>
    <div className="scroll-container scrollbar-hide fixed inset-0 mt-2 flex  justify-center  ml-64  2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-40" 
        ref={scrollContainerRef}
        style={{ pointerEvents: 'auto' }} 
        >
      {loading && vids.length === 0 ? (
        <div className="">
          <Vidskeleton />
        </div>
      ) : (
        <>
          <div>
            {vids.map((video, index) => (
              <div
                key={index}
                className={`flex 2xl:w-[500px] xl:w-[500px] lg:w-[500px] md:w-[500px] video-container h-screen  shadow-2xl border border-black flex-col relative snap-center snap-always   mb-2.5 ${index === activeVideoIndex ? 'bg-black' : 'bg-gray-800'}`}
                onClick={() => handleVideoClick(index)}
              >
                <div className="h-full">
                  <video
                    ref={el => videoRefs.current[index] = el}
                    data-index={index}
                    src={`${PROFILEPHOTO_URL}/${video.url}`}
                    className="object-cover w-full h-full "
                    // muted={mutedVideos[index] || false}
                    muted={mutedAll  || false}
                    playsInline
                    onLoadedData={() => handleLoadedData(index)}
                    onWaiting={() => handleWaiting(index)}
                    onPlaying={() => handlePlaying(index)}
                    onEnded={() => handleEnded(index)}
                  />
                  {loadingStates[index] && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/50 rounded-full w-14 h-14 flex items-center justify-center">
                      <CircularProgress />
                    </div>
                  )}
                </div>
                <div className="absolute bottom-12 ml-4 flex items-center mb-4">
                  <div className="bg-black rounded-full w-10 h-10 overflow-hidden cursor-pointer" onClick={(e) => { e.stopPropagation(); handleProfile(video.user.userId); }}>
                    <img 
                      src={`${PROFILEPHOTO_URL}/${video.user.profilePhoto}`} 
                      alt="Profile" 
                      className="object-cover w-full h-full" 
                    />
                  </div>
                  <span className="ml-2 text-white font-semibold shadow-xl cursor-pointer" onClick={(e) => { e.stopPropagation(); handleProfile(video.user.userId); }}>
                    {video.user.firstName} {video.user.lastName}
                    </span>
                  <div className="flex justify-center">
                    {/* <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg border border-black text-sm px-3 py-1 ml-2">Follow</button> */}
                  </div>
                </div>
                <div className="absolute bottom-8 text-white text-sm ml-6">
                  <span>{video.caption}</span>
                </div>
                <div 
                  className="text-white absolute top-0 mt-14 right-2.5 bg-black/40 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleMuteClick(index);
                    // handleMuteClick();
                  }}
                >
                  {mutedAll ? <VolumeOffIcon /> : <VolumeUpIcon />}
                  {/* {mutedVideos[index] ? <VolumeOffIcon /> : <VolumeUpIcon />} */}
                </div>
                <div 
                  className="text-white absolute top-0 mt-2 right-2.5 bg-black/40 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
                  onClick={(e) => {  
                    e.stopPropagation(); 
                    openModal(video)} }
                >
                  <ExpandIcon />
                </div>
                <div className="text-white absolute bottom-8 right-3 mb-32 flex flex-col items-center">
                  <div className="relative flex items-center justify-center">
                    <ViewIcon />
                  </div>
                  <span className="text-white text-xs font-thin">{video.viewCount}</span>
                </div>
                <div className="text-white absolute bottom-5 right-3.5 mb-20 flex flex-col items-center">
                  <div className="relative flex items-center justify-center cursor-pointer" 
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleLikeClick(video.id);
                  }}
                    >
                    {video.isUserLiked ? <LikedIcon /> : <LikeIcon />}
                  </div>
                  <span className="text-white text-xs font-thin">{video.likeCount }</span>
                </div>
                <div className="text-white absolute bottom-0  right-3.5 mb-10  flex flex-col items-center cursor-pointer"onClick={(e) => {
            e.stopPropagation(); 
            openModal(video);
  }}> 
                  <div className="relative flex items-center justify-center ">
                    <CommentIcon />
                  </div>
                  <span className="text-white text-xs font-thin">{video.commentCount}</span>
                </div>
                {/* <div className="text-white absolute bottom-10 right-4 mb-16">
                  <ShareIcon />
                </div> */}
                {/* <div className="text-white absolute bottom-6 right-3.5 mb-10">
                  <BookmarkBorderOutlinedIcon style={{ fontSize: 20 }} />
                </div> */}
                {/* <div className="text-white absolute bottom-0 mb-6 right-3">
                  <MoreVertIcon style={{ fontSize: 25 }} />
                </div> */}
                {pausedVideos[index] && (
                  <div className="text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black/40 rounded-full w-14 h-14 flex items-center justify-center">
                    <PauseIcon />
                  </div>
                )}
              </div>
            ))}
             {loadingMore && (
            <div className=" flex items-center justify-center mb-4 ">
              <Vidsskeleton />
            </div>
          )}
          {!loadingMore && !hasMore && vids.length > 0 && (
            <div className="mt-4 text-gray-400 text-center">No more Vids.</div>
          )}
          </div>
         
        </>
      )}
    </div>
    {selectedVideo && (
      <VideoModal video={selectedVideo} onClose={closeModal} />
    )}
    </>
  );
};

export default Vids;

