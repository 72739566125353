import { SET_COORDINATES, SET_MAP_BOUNDS } from '../Action/mapaction';

const initialState = {
  coordinates: null,
  mapBounds: null,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COORDINATES:
      console.log('New Coordinates Set in Reducer:', action.payload);
      return {
        ...state,
        coordinates: action.payload,
      };
    case SET_MAP_BOUNDS:
      console.log('New Map Bounds Set in Reducer:', action.payload); 
      return {
        ...state,
        mapBounds: action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
