import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import Home from "./Component/Home";
import Login from "./Component/Login";
import Realestate from "./Component/Realestate";
import HomeDetail from "./Component/HomeDetail";
import SmartSocial from "./Component/SmartSocial";
import './App.css';

const App = () => {
  const location = useLocation();
  
  const hideNavAndFooter = location.pathname.startsWith("/smartsocial");

  useEffect(() => {
    if (location.pathname.startsWith("/smartsocial")) {
      document.body.classList.add('bg-smartsocial');
    } else {
      document.body.classList.remove('bg-smartsocial');
    }
    return () => {
      document.body.classList.remove('bg-smartsocial');
    };
  }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen">
      {!hideNavAndFooter && <Navbar />}
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/realestate" element={<Realestate />} />
          <Route path="/homedetail/:propertyId" element={<HomeDetail />} />
          <Route path="/smartsocial/*" element={<SmartSocial />} />
        </Routes>
      </div>
      {!hideNavAndFooter && <Footer />}
    </div>
  );
};

export default App;
