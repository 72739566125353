import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import "./style.css"
import Middlecontent from "./Middlecontent";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (
    
      <>
      </>
      
  );
};

export default Home;
