import React ,{ useState} from "react";
import { CommentIcon, LikeIcon, ShareIcon, LikedIcon } from "./icon";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { API_URL, } from "../../../../../Config/config";
import axios from "axios";

const PostBottomSection = ({post, handleViewComments, handleProfile}) => {
    const [isUserLiked, setIsUserLiked] = useState(post.isUserLiked);
    const [likeCount, setLikeCount] = useState(post.likeCount);

    const handleLikeClick = async () => {
        try {
          const token = localStorage.getItem('token');
          const postId = localStorage.getItem('postId');
    
          if (!token || !postId) {
            throw new Error('Token or Post ID not found in local storage');
          }
    
          const url = `${API_URL}/posts/v3/like/${postId}`;
          const config = {
            method: 'post',
            url: url,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
    
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);
    
          const response = await axios.request(config);
    
          if (response.data.likeCount !== undefined) {
            setLikeCount(response.data.likeCount);
          }
    
        } catch (error) {
          console.error('Error toggling like status:', error);
        
          setIsUserLiked(!isUserLiked);
          setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
        }
      };


    return (
        <div >
        <div className="flex justify-between items-center mt-5 mx-2 ">
  <div className="flex items-center">
    <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
        {isUserLiked ? <LikedIcon /> : <LikeIcon />}</div>
    <div className="mx-3" onClick={handleViewComments}><CommentIcon /></div>
    {/* <ShareIcon /> */}
  </div>
  {/* <BookmarkBorderOutlinedIcon style={{ fontSize: 22, color: 'white' }} /> */}
</div>
      <div className="flex text-sm mt-4 ml-3.5 text-white">
            <div>{likeCount} likes</div>
          </div>

          <div className="flex flex-col ml-3.5 mt-1">
            <span className="text-white text-sm mr-2 " >
              <span className=" cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                {post.user.firstName} {post.user.lastName}
                </span>
              <span className="ml-2 text-xs text-white/80"> {post.caption}</span>
            </span>
          </div>
    </div>
    
    
    );
};

export default PostBottomSection;



