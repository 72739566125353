
import axios from 'axios';
import { API_URL } from '../../../Config/config';

export const FETCH_POST_COMMENT_REQUEST = 'FETCH_POST_COMMENT_REQUEST';
export const FETCH_POST_COMMENT_SUCCESS = 'FETCH_POST_COMMENT_SUCCESS';
export const FETCH_POST_COMMENT_FAILURE = 'FETCH_POST_COMMENT_FAILURE';
export const FETCH_COMMENT_REPLIES_REQUEST = 'FETCH_COMMENT_REPLIES_REQUEST';
export const FETCH_COMMENT_REPLIES_SUCCESS = 'FETCH_COMMENT_REPLIES_SUCCESS';
export const FETCH_COMMENT_REPLIES_FAILURE = 'FETCH_COMMENT_REPLIES_FAILURE';

export const fetchPostCommentRequest = () => ({
  type: FETCH_POST_COMMENT_REQUEST
});

export const fetchPostCommentSuccess = (data) => ({
  type: FETCH_POST_COMMENT_SUCCESS,
  payload: data
});

export const fetchPostCommentFailure = (error) => ({
  type: FETCH_POST_COMMENT_FAILURE,
  payload: error
});

export const fetchCommentRepliesRequest = () => ({
    type: FETCH_COMMENT_REPLIES_REQUEST
  });
  
 export const fetchCommentRepliesSuccess = (data) => ({
    type: FETCH_COMMENT_REPLIES_SUCCESS,
    payload: data
  });
  
  export const fetchCommentRepliesFailure = (error) => ({
    type: FETCH_COMMENT_REPLIES_FAILURE,
    payload: error
  });

  export const fetchPostCommentData = (pageNumber = 0) => async (dispatch, getState) => {
    dispatch(fetchPostCommentRequest());
  
    try {
      const token = localStorage.getItem('token');
      const postId = localStorage.getItem('postId');
  
      if (!token) {
        throw new Error('Token not found');
      }
  
      if (!postId) {
        throw new Error('Post ID not found');
      }
  
      const config = {
        method: 'get',
        url: `${API_URL}/posts/v3/comment/${postId}?page_number=${pageNumber}`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
  
      const response = await axios.request(config);
      const newComments = response.data.data;
  
      const currentComments = getState().postcomment.comments;
      const allComments = pageNumber === 0 ? newComments : [...currentComments, ...newComments];
  
      dispatch(fetchPostCommentSuccess(allComments));
    } catch (error) {
      dispatch(fetchPostCommentFailure(error.message));
    }
  };
  


export const fetchCommentReplies = (commentId) => {
    return async (dispatch) => {
      dispatch(fetchCommentRepliesRequest());
  
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchCommentRepliesFailure('No token found in local storage'));
        return;
      }
  
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${API_URL}/posts/v3/comment/reply/${commentId}?page_number=0`,
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };
  
      try {
        const response = await axios.request(config);
        dispatch(fetchCommentRepliesSuccess({ commentId, data: response.data.data }));
      } catch (error) {
        dispatch(fetchCommentRepliesFailure(error.message));
      }
    };
  };