export const calculateTimeAgo = (createdAt) => {
    const currentDate = new Date();
    const postDate = new Date(createdAt);

    const timeDifference = currentDate - postDate;

    const seconds = Math.floor(timeDifference / 1000);

    const justNowThreshold = 10; 

    if (seconds < justNowThreshold) {
        return 'Just now';
    }

    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 7) {
        const weeks = Math.floor(days / 7);
        return `${weeks} w${weeks > 1 ? '' : ''} `;
    } else if (days > 0) {
        return `${days} d${days > 1 ? '' : ''} `;
    } else if (hours > 0) {
        return `${hours} h${hours > 1 ? '' : ''} `;
    } else if (minutes > 0) {
        return `${minutes} m${minutes > 1 ? '' : ''} `;
    } else {
        return `${seconds} s${seconds !== 1 ? '' : ''} `;
    }
};
