import React, { useState } from 'react';
import { LikeIcon, CommentIcon, ShareIcon, LikedIcon } from '../icon';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { formatDate } from '../Utils/dateutil';
import axios from 'axios';
import { API_URL } from '../../../../../Config/config';

const Postbottomsection = ({ post }) => {
  const [isUserLiked, setIsUserLiked] = useState(post.isUserLiked);
  const [likeCount, setLikeCount] = useState(post.likeCount);

  const handleLikeClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const postId = localStorage.getItem('postId');

      if (!token || !postId) {
        throw new Error('Token or Post ID not found in local storage');
      }

      const url = `${API_URL}/posts/v3/like/${postId}`;
      const config = {
        method: 'post',
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      setIsUserLiked(!isUserLiked);
      setLikeCount(prevCount => isUserLiked ? prevCount - 1 : prevCount + 1);

      const response = await axios.request(config);

      if (response.data.likeCount !== undefined) {
        setLikeCount(response.data.likeCount);
      }

    } catch (error) {
      console.error('Error toggling like status:', error);
    
      setIsUserLiked(!isUserLiked);
      setLikeCount(prevCount => isUserLiked ? prevCount + 1 : prevCount - 1);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mt-3 mx-2">
        <div className="flex items-center ">
          <div className="mx-1 cursor-pointer" onClick={handleLikeClick}>
            {isUserLiked ? <LikedIcon /> : <LikeIcon />}
          </div>
          <div className="mx-3 cursor-pointer">
            <CommentIcon />
          </div>
          {/* <div className='cursor-pointer'> 
          <ShareIcon />
          </div> */}
        </div>
        {/* <div className='cursor-pointer' style={{ color: 'white', fontSize: 22 }}>
          <BookmarkBorderOutlinedIcon />
        </div> */}
      </div>
      <div className="flex text-sm mt-2 ml-4 text-white">
        <div>{likeCount} likes</div>
      </div>
      <div className="flex text-sm mt-2 ml-4 text-gray-300 mb-2">
        <div>{formatDate(post.createdAt)}</div>
      </div>
    </>
  );
};

export default Postbottomsection;
