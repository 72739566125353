import axios from 'axios';
import { API_URL } from '../../../Config/config';

export const SET_COMMENT = 'SET_COMMENT';
export const RESET_COMMENT = 'RESET_COMMENT';
export const SET_EMOJI = 'SET_EMOJI';
export const TOGGLE_EMOJI_PICKER = 'TOGGLE_EMOJI_PICKER';
export const POST_COMMENT_REQUEST = 'POST_COMMENT_REQUEST';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const POST_COMMENT_FAILURE = 'POST_COMMENT_FAILURE';


export const setComment = (comment) => ({
    type: SET_COMMENT,
    payload: comment,
  });
  
  export const resetComment = () => ({
    type: RESET_COMMENT,
  });
  
  export const setEmoji = (emoji) => ({
    type: SET_EMOJI,
    payload: emoji,
  });
  
  export const toggleEmojiPicker = () => ({
    type: TOGGLE_EMOJI_PICKER,
  });
  
  export const postCommentRequest = () => ({
    type: POST_COMMENT_REQUEST,
  });
  
  export const postCommentSuccess = (data) => ({
    type: POST_COMMENT_SUCCESS,
    payload: data,
  });
  
  export const postCommentFailure = (error) => ({
    type: POST_COMMENT_FAILURE,
    payload: error,
  });
  
  export const postComment = (comment) => {
    return (dispatch) => {
      dispatch(postCommentRequest());
  
      const token = localStorage.getItem('token');
      const postId = localStorage.getItem('postId');
      if (!token || !postId) {
        dispatch(postCommentFailure('Token or Post ID not found in local storage'));
        return;
      }
  
      const data = JSON.stringify({ comment: comment });
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/posts/v3/comment/${postId}`,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: data
      };
  
      axios.request(config)
        .then((response) => {
          dispatch(postCommentSuccess(response.data));
          dispatch(resetComment());
        })
        .catch((error) => {
          dispatch(postCommentFailure(error));
        });
    };
  };