import { API_URL } from "../Config/config";

//  const API_URL = `http://dev.smartsuperapp.com/`;
// const API_URL ='http://api.smartsuperapp.com/';

const URLS = {
  login: `${API_URL}/users/v2/send-otp`,
  otpVerifyUrl: `${API_URL}/users/v2/verify-otp`,
  removeAccount: `${API_URL}/users/v2/account-remove-request`,
  verifyRemoveRequest: `${API_URL}/users/v2/account-remove`,
  userInfo: `${API_URL}/users/v2/info`,
};

export default URLS;


