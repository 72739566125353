import React, { useState, useRef, useEffect} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RxDotFilled } from 'react-icons/rx';
import '../style.css';
import { PROFILEPHOTO_URL } from '../../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';


const PostModalimage = ({ post }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const audioRef = useRef(null);
    const [audioTime, setAudioTime] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const slides = post && post.contents ? post.contents : [];
    const isSinglePost = slides.length === 1;

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', updateAudioTime);
            audioRef.current.addEventListener('loadedmetadata', updateAudioDuration);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', updateAudioTime);
                audioRef.current.removeEventListener('loadedmetadata', updateAudioDuration);
            }
        };
    }, []);

    const updateAudioTime = () => {
        if (audioRef.current) {
            setAudioTime(audioRef.current.currentTime);
        }
    };

    const updateAudioDuration = () => {
        if (audioRef.current) {
            setAudioDuration(audioRef.current.duration);
        }
    };

    const handleAudioSliderChange = (event, newValue) => {
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
            setAudioTime(newValue);
        }
    };

    const handleImageLoad = () => {
        setLoading(false);
    };

    const prevSlide = () => {
        setLoading(true);
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        setLoading(true);
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setLoading(true);
        setCurrentIndex(slideIndex);
    };

    const toggleMute = () => {
        if (audioRef.current) {
            audioRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    return (
        <div>
            {isSinglePost ? (
                <div className="2xl:w-[680px] 2xl:h-[700px] xl:w-[680px] xl:h-[700px] lg:w-[550px] lg:h-[700px] md:w-[400px] md:h-[700px] m-auto relative group bg-black/80 rounded-tl-lg rounded-bl-lg overflow-hidden">
                {loading && (
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <CircularProgress />
                        </div>
                    )}
                    <img
                        src={`${PROFILEPHOTO_URL}/${slides[0].url}`}
                        alt="Post"
                        className={`absolute inset-0 w-full h-full  object-contain rounded-tl-lg rounded-bl-lg duration-500 ease-in-out ${loading ? 'hidden' : 'block'}`}
                        onLoad={handleImageLoad}
                    />
                </div>
            ) : (
                <div className="2xl:w-[680px] 2xl:h-[700px] xl:w-[680px] xl:h-[700px] lg:w-[550px] lg:h-[700px] md:w-[400px] md:h-[700px] m-auto relative group bg-black/80 rounded-tl-lg rounded-bl-lg overflow-hidden">
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <CircularProgress />
                        </div>
                    )}
                    <img
                        src={`${PROFILEPHOTO_URL}/${slides[currentIndex].url}`}
                        alt="Slide"
                        className={`absolute inset-0 w-full h-full object-contain rounded-tl-lg rounded-bl-lg duration-500 ease-in-out ${loading ? 'hidden' : 'block'}`}
                        onLoad={handleImageLoad}
                    />
                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-1 translate-y-[-50%] left-5 rounded-full p-2 bg-black text-white cursor-pointer'
                        onClick={prevSlide}>
                        <ChevronLeftIcon />
                    </div>
                    <div className='hidden group-hover:block absolute top-[50%] -translate-x-1 translate-y-[-50%] right-5 rounded-full p-2 bg-black text-white cursor-pointer'
                        onClick={nextSlide}>
                        <ChevronRightIcon />
                    </div>
                    <div className='absolute bottom-8 w-full flex justify-center'>
                        {slides.map((slide, slideIndex) => (
                            <div
                                key={slideIndex}
                                onClick={() => goToSlide(slideIndex)}
                                className={`text-2xl cursor-pointer ${slideIndex === currentIndex ? 'text-blue-500' : 'text-white'}`}
                            >
                                <RxDotFilled />
                            </div>
                        ))}
                    </div>
                </div>
            )}
             {post.hasBlurt && post.blurt && (
                <div className="absolute top-3 2xl:left-[630px] xl:left-[630px] lg:left-[480px] md:left-[340px] z-20 flex flex-col items-center space-y-2">
                    <audio ref={audioRef} src={`${PROFILEPHOTO_URL}/${post.blurt.url}`} loop autoPlay muted />
                    <button onClick={toggleMute} className="bg-black text-white rounded-full p-2">
                        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </button>
                    
                </div>
            )}
            {/* {audioRef.current && (
            <Slider
                value={audioTime}
                min={0}
                max={audioDuration}
                onChange={handleAudioSliderChange}
                aria-labelledby="audio-slider"
                style={{ width: '380px', color: 'white', position: 'absolute', bottom: '2px', left: '2%', transform: 'translateX(-0%)' }}
                className='z-20'
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${formatTime(value)} / ${formatTime(audioDuration)}`}
                ValueLabelComponent={({ children, open, value }) => (
                    <Tooltip open={open} enterTouchDelay={0} placement="top" title={formatTime(value)}>
                        {children}
                    </Tooltip>
                )}
            />
        )} */}
        </div>
    );
};

export default PostModalimage;

const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
