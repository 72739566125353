import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk'; 
// import { composeWithDevTools } from 'redux-devtools-extension';


import viewReducer from '../Reducers/viewreducers';
import propertyReducer from '../Reducers/propertyReducer';
import searchReducer from '../Reducers/searchreducers';
import userReducer from '../Reducers/smartsocial/userreducer';
import userProfileReducer from '../Reducers/smartsocial/userprofilereducer';
import postcommentreducer from '../Reducers/smartsocial/postcommentreducer';
import replyReducer from '../Reducers/smartsocial/postrepliescommentreducer';
import commentReducer from '../Reducers/smartsocial/submitcommentreducer';
import userReducer1 from '../Reducers/user';
import mapReducer from '../Reducers/mapreducer'; 

const rootReducer = combineReducers({
  view: viewReducer,
  properties: propertyReducer,
  search: searchReducer,
  user: userReducer,
  user1: userReducer1,
  userProfile: userProfileReducer,
  postcomment: postcommentreducer,
  replies: replyReducer,
  comment: commentReducer,
  map: mapReducer,
});


// const composeEnhancers = composeWithDevTools({
 
// });

const store = createStore(
  rootReducer,
 (applyMiddleware(thunk))
);

export default store;
