import React, { useState, useEffect, useRef } from 'react';
import { fetchDropdownData } from './Fliterapi';
import { fetchProperties , updatePageNumber} from '../../../Action/propertyAction';
import ArrowIcon from '../ArrowIcon';

import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import homeTypeImages from './homeTypeImages';
import './style.css';

const Filter = () => {
 const dropdownRef = useRef(null);
 const dispatch = useDispatch();

 const [isOpen, setIsOpen] = useState(false);
 const [isOpen1, setIsOpen1] = useState(false);
 const [isOpen2, setIsOpen2] = useState(true);
 const [isOpen3, setIsOpen3] = useState(false);
 const [isOpen4, setIsOpen4] = useState(false);
 const [isOpen5, setIsOpen5] = useState(false);
 const [isOpen6, setIsOpen6] = useState(false);
 const [isOpen7, setIsOpen7] = useState(false);
 const [isOpen8, setIsOpen8] = useState(false);
 const [isOpen9, setIsOpen9] = useState(false);
 const [isOpen10, setIsOpen10] = useState(true);
 const [isOpen11, setIsOpen11] = useState(false);
 const [isOpen12, setIsOpen12] = useState(false);
 const [isOpen13, setIsOpen13] = useState(false);
 const [isOpen14, setIsOpen14] = useState(false);
 const [isOpen15, setIsOpen15] = useState(false);

 
 const [priceMinData, setPriceMinData] = useState([]);
 const [priceMaxData, setPriceMaxData] = useState([]);
 const [selectedMin, setSelectedMin] = useState('');
 const [selectedMax, setSelectedMax] = useState('');

 const [homeType, setHomeType] = useState([]);
 const [buttonLabel, setButtonLabel] = useState('Property Type');
 const [doneClicked, setDoneClicked] = useState(false);
 const [selectedTypes, setSelectedTypes] = useState(() => {
 const storedValue = localStorage.getItem('filter_proptype');
 return storedValue ? storedValue.split(', ') : ['any'];
 });
 const [closeIconVisible, setCloseIconVisible] = useState(false);
 const [HomesizeDataMin, setHomesizeDataMin] = useState ([]);
 const [selectedbedroomMinValue, setselectedbedroomMinValue] = useState('');
 const [selectedbedroomMaxValue, setselectedbedroomMaxValue] = useState('');
 const [selectedbathroomMinValue, setselectedbathroomMinValue] = useState('');
 const [selectedbathroomMaxValue, setselectedbathroomMaxValue] = useState('');
 const [selectedRange, setSelectedRange] = useState('Rooms'); 
 const [isDoneClicked, setIsDoneClicked] = useState(false);

 const [livingAreaMin, setlivingAreaMin] = useState([]);
 const [livingAreaMax, setlivingAreaMax] = useState([]);
 const [selectedlivingMin, setselectedlivingMin] = useState('');
 const [selectedlivingMax, setselectedlivingMax] = useState('');
 const [lotsizemin, setlotsizemin] = useState([]);
 const [lotsizemax, setlotsizemax] = useState([]);
 const [selectedlotMin, setselectedlotMin] = useState('');
 const [selectedlotMax, setselectedlotMax]= useState('');
 const [activeFiltersCount, setActiveFiltersCount] = useState(0);

 const toggleDropdown = () => {
 setIsOpen(!isOpen);
 if (isOpen3) setIsOpen3(false);
 if (isOpen4) setIsOpen4(false);
 if (isOpen9) setIsOpen9(false);
 };
 const toggleDropdown1 = () => {
 setIsOpen1(!isOpen1);
 if (isOpen2) setIsOpen2(false);
 };

 const toggleDropdown2 = () => {
 setIsOpen2(!isOpen2);
 if (isOpen1) setIsOpen1(false);
 };
 const toggleDropdown3 = () => {
 setIsOpen3(!isOpen3);
 if (isOpen) setIsOpen(false);
 if (isOpen4) setIsOpen4(false);
 if (isOpen9) setIsOpen9(false);
 };

 const toggleDropdown4 = () => {
 setIsOpen4(!isOpen4);
 if (isOpen) setIsOpen(false);
 if (isOpen3) setIsOpen3(false);
 if (isOpen9) setIsOpen9(false);
 if (isOpen5) setIsOpen5(false);
 if (isOpen6) setIsOpen6(false); 
 if (isOpen7) setIsOpen7(false);
 if (isOpen8) setIsOpen8(false); 
 };
 const toggleDropdown5 = () => {
 setIsOpen5(!isOpen5);
 if (isOpen6) setIsOpen6(false); 
 if (isOpen7) setIsOpen7(false);
 if (isOpen8) setIsOpen8(false); 
 };
 const toggleDropdown6 = () => {
 setIsOpen6(!isOpen6);
 if (isOpen5) setIsOpen5(false);
 if (isOpen8) setIsOpen8(false); 
 if (isOpen7) setIsOpen7(false);
 };

 const toggleDropdown7 = () => {
 setIsOpen7(!isOpen7);
 if (isOpen8) setIsOpen8(false); 
 if (isOpen6) setIsOpen6(false); 
 if (isOpen5) setIsOpen5(false);
 };

 const toggleDropdown8 = () => {
 setIsOpen8(!isOpen8);
 if (isOpen7) setIsOpen7(false);
 if (isOpen6) setIsOpen6(false); 
 if (isOpen5) setIsOpen5(false);
 };

 const toggleDropdown9 = () => {
 setIsOpen9(!isOpen9);
 if (isOpen) setIsOpen(false);
 if (isOpen3) setIsOpen3(false);
 if (isOpen4) setIsOpen4(false);
 };
 
 const toggleDropdown10 = () => {
 setIsOpen10(!isOpen10);
 };

 const toggleDropdown11 = () => {
 setIsOpen11(!isOpen11);
 if (isOpen12) setIsOpen12(false);
 if (isOpen14) setIsOpen14(false);
 if (isOpen15) setIsOpen15(false);
 
 };
 const toggleDropdown12 = () => {
 setIsOpen12(!isOpen12);
 if (isOpen11) setIsOpen11(false);
 if (isOpen14) setIsOpen14(false);
 if (isOpen15) setIsOpen15(false);
 };
 const toggleDropdown13 = () => {
 setIsOpen13(!isOpen13);
 };
 const toggleDropdown14 = () => {
 setIsOpen14(!isOpen14);
 if (isOpen11) setIsOpen11(false);
 if (isOpen12) setIsOpen12(false);
 if (isOpen15) setIsOpen15(false);

 };
 const toggleDropdown15 = () => {
 setIsOpen15(!isOpen15);
 if (isOpen11) setIsOpen11(false);
 if (isOpen14) setIsOpen14(false);
 if (isOpen12) setIsOpen12(false);
 };


 const handleClickOutside = (event) => {
 if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
 setIsOpen(false); 
 setIsOpen3(false); 
 setIsOpen4(false); setIsOpen5(!isOpen5); setIsOpen6(!isOpen6);
 setIsOpen7(!isOpen7); setIsOpen8(!isOpen8);
 setIsOpen9(false); 
 }
};

useEffect(() => {
 window.addEventListener('click', handleClickOutside);
 return () => {
 window.removeEventListener('click', handleClickOutside);
 };
}, []);

useEffect(() => {
 const fetchData = async () => {
 try {
 const data = await fetchDropdownData();
 if (data) {
 setPriceMinData(data.priceMin);
 setPriceMaxData(data.priceMax);
 setHomeType(data.hometype);
 setlivingAreaMin(data.livingAreaMin)
 setlivingAreaMax(data.livingAreaMax)
 setlotsizemin(data.lotsizemin)
 setlotsizemax(data.lotsizemax)
 }
 } catch (error) {
 console.error('Error fetching price data:', error);
 }
 };

 const filterByPrice = localStorage.getItem('filter_by_price');
 if (filterByPrice) {
 const { min, max } = JSON.parse(filterByPrice);
 setSelectedMin(min);
 setSelectedMax(max);
 }

 const filterByLiving = localStorage.getItem('filter_by_livingarea');
 if (filterByLiving) {
 const {areamin, areamax} = JSON.parse(filterByLiving);
 setselectedlivingMin(areamin);
 setselectedlivingMax(areamax);
 }

 const filterByLot = localStorage.getItem('filter_by_lotsize');
 if (filterByLot) {
 const {lotmin, lotmax} = JSON.parse(filterByLot);
 setselectedlotMin(lotmin);
 setselectedlotMax(lotmax);
 }

 const filterByBed = localStorage.getItem('filter_by_bedroom');
 if (filterByBed) {
 const { bedmin, bedmax } = JSON.parse(filterByBed);
 setselectedbedroomMinValue(bedmin);
 setselectedbedroomMaxValue(bedmax);
 }
 
 const filterByBath = localStorage.getItem('filter_by_bathroom');
 if (filterByBath) {
 const { bathmin, bathmax } = JSON.parse(filterByBath);
 setselectedbathroomMinValue(bathmin);
 setselectedbathroomMaxValue(bathmax);
 }


 fetchData();
}, []);

useEffect(() => {
 
 const filterByLiving = localStorage.getItem('filter_by_livingarea');
 const filterByLot = localStorage.getItem('filter_by_lotsize');
 let count = 0;
 if (filterByLiving) {
 const { areamin, areamax } = JSON.parse(filterByLiving);
 if (areamin || areamax) count += 1;
 }
 if (filterByLot) {
 const { lotmin, lotmax } = JSON.parse(filterByLot);
 if (lotmin || lotmax) count += 1;
 }
 setActiveFiltersCount(count);
 }, []);

const handleMinSelect = (min, event) => {
 setSelectedMin(min.value);
 setIsOpen1(false);
 setIsOpen2(true);
 event.stopPropagation(); 
};

const handleMaxSelect = (max, event) => {
 setSelectedMax(max.value);
 setIsOpen2(false);
 event.stopPropagation(); 
};

const filteredMaxOptions = priceMaxData.filter(maxOption => selectedMin === '' || parseInt(maxOption.value) > parseInt(selectedMin));

const clearLocalStorage = () => {
 Object.keys(localStorage)
 .filter(key => key.startsWith('properties_page_'))
 .forEach(key => localStorage.removeItem(key));

 // localStorage.removeItem('properties_page_0');
 // localStorage.removeItem('currentPageNumber')
};
const handleDone = () => {
 setIsOpen(false);
 setIsOpen1(false);
 setIsOpen2(false);
 clearLocalStorage();
 dispatch(updatePageNumber(0)); 
 localStorage.setItem('filter_by_price', JSON.stringify({ min: selectedMin, max: selectedMax }));
 dispatch(fetchProperties('','',0,selectedMin, selectedMax));
 localStorage.setItem('currentPageNumber',0); 
};

 
const handleClearPrice = () => {
 setSelectedMin('');
 setSelectedMax('');
 clearLocalStorage();
 localStorage.removeItem('filter_by_price');
 dispatch(updatePageNumber(0)); 
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0);
};

const formatPrice = (value) => {
 if (value >= 1000000) {
 return `${value / 1000000} M`;
 } else if (value >= 1000) {
 return `${value / 1000}k`;
 } else {
 return `${value}`;
 }
};

const getPriceButtonText = () => {
 const minText = selectedMin !== '' ? formatPrice(selectedMin) : '';
 const maxText = selectedMax !== '' ? formatPrice(selectedMax) : '';
 if (selectedMin && selectedMax) {
 return (
 <div className="flex items-center">
 <span className="mr-1">${minText} - ${maxText}</span>
 <div type="button" className="" onClick={handleClearPrice}>
 <CloseIcon style={{ width: '20px', height: '24px' }} />
 </div>
 </div>
 );
 } else if (selectedMin) {
 return ( <div className="flex items-center">
 <span className="mr-1"> Min ${minText} </span>
 <div type="button" className="" onClick={handleClearPrice}>
 <CloseIcon style={{ width: '20px', height: '24px' }} />
 </div>
 </div>
 );
 } else if (selectedMax) {
 return ( <div className="flex items-center">
 <span className="mr-1"> Max ${maxText}</span>
 <div type="button" className="" onClick={handleClearPrice}>
 <CloseIcon style={{ width: '20px', height: '24px' }} />
 </div>
 </div>
 );
 } else {
 return 'Price';
 }
};

const handleDone1 = () => {
 clearLocalStorage();
 if (selectedTypes.includes('any')) {
 setButtonLabel('Property Type');
 localStorage.removeItem('filter_proptype');
 } else if (selectedTypes.length === 1) {
 const selectedType = homeType.find((type) => type.value === selectedTypes[0]);
 if (selectedType) {
 setButtonLabel(selectedType.label);
 localStorage.setItem('filter_proptype', selectedType.value);
 }
 } else {
 const selectedTypeValues = selectedTypes.join(', ');
 setButtonLabel(`${selectedTypes.length} Selected`);
 localStorage.setItem('filter_proptype', selectedTypeValues);
 }
 setIsOpen3(false);
 
 dispatch(updatePageNumber(0));
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0); 
 setDoneClicked(true);
 setCloseIconVisible(
 selectedTypes.length > 1 || (selectedTypes.length === 1 && selectedTypes[0] !== 'any')
 );
};

const handleclear = () => {
 setSelectedTypes(['any']);
 setButtonLabel('Property Type');
 localStorage.removeItem('filter_proptype');
 setIsOpen3(false); 
 clearLocalStorage();
 dispatch(updatePageNumber(0));
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0); 
 setDoneClicked(false);
 setCloseIconVisible(false);
};

useEffect(() => {
 if (homeType.length > 0) {
 const storedValue = localStorage.getItem('filter_proptype');
 if (storedValue) {
 const selectedTypes = storedValue.split(', ');
 if (selectedTypes.length === 1) {
 const selectedType = homeType.find((type) => type.value === selectedTypes[0]);
 if (selectedType) {
 setButtonLabel(selectedType.label);
 }
 } else if (selectedTypes.length > 1) {
 setButtonLabel(`${selectedTypes.length} Selected`);
 }
 }
 }
}, [homeType]);

const handleSelection = (value) => {
 setSelectedTypes((prevSelected) => {
 if (value === 'any') {
 return prevSelected.includes('any') ? [] : ['any'];
 } else {
 const newSelected = prevSelected.includes(value)
 ? prevSelected.filter((type) => type !== value)
 : [...prevSelected.filter((type) => type !== 'any'), value];
 return newSelected.length === 0 ? ['any'] : newSelected;
 }
 });
};

useEffect(() => {
 setCloseIconVisible(
 selectedTypes.length > 1 || (selectedTypes.length === 1 && selectedTypes[0] !== 'any')
 );
}, [selectedTypes]);

const handlelivingMinSelect = (minarea, event) => {
 setselectedlivingMin(minarea.value);
 setIsOpen11(false);
 event.stopPropagation(); 
 }

 const handlelivingMaxSelect = (max, event) => {
 setselectedlivingMax(max.value);
 setIsOpen12(false);
 event.stopPropagation(); 
 }

 const filteredlivingareaMaxOptions = livingAreaMax.filter(maxOption => selectedlivingMin === '' || parseInt(maxOption.value) > parseInt(selectedlivingMin));

 const getlivingareaText = () => {
 if (selectedlivingMin && selectedlivingMax) {
 return `${selectedlivingMin}sqft - ${selectedlivingMax}sqft`;
 } else if (selectedlivingMin) {
 return `${selectedlivingMin}sqft - MAX`;
 } else if (selectedlivingMax) {
 return `MIN - ${selectedlivingMax}sqft`;
 } 
 }

 const handlelotMinSelect = (lotmin, event) => {
 setselectedlotMin(lotmin.value);
 setIsOpen14(false);
 event.stopPropagation();
 }

 const handlelotMaxSelect = (lotmax, event) => {
 setselectedlotMax(lotmax.value);
 setIsOpen15(false);
 event.stopPropagation(); 
 }

 const filteredlotsizeMaxOptions = lotsizemax.filter(maxoption => selectedlotMin === '' || parseInt(maxoption.value) > parseInt(selectedlotMin));

 const getlotsizeText = () => { 
 if (selectedlotMin && selectedlotMax) {
 return `${selectedlotMin}sqft - ${selectedlotMax}sqft`;
 } else if (selectedlotMin) {
 return `${selectedlotMin}sqft - MAX`;
 } else if (selectedlotMax) {
 return `MIN - ${selectedlotMax}sqft`;
 } 
 }

 const handleApplyClick = () => {
 setIsOpen9(false)
 clearLocalStorage();
 let count = 0;

 if (selectedlivingMin || selectedlivingMax) {
 localStorage.setItem('filter_by_livingarea', JSON.stringify({
 areamin: selectedlivingMin,
 areamax: selectedlivingMax
 }));
 count += 1;
 } else {
 localStorage.removeItem('filter_by_livingarea');
 }

 if (selectedlotMin || selectedlotMax) {
 localStorage.setItem('filter_by_lotsize', JSON.stringify({
 lotmin: selectedlotMin,
 lotmax: selectedlotMax
 }));
 count += 1;
 } else {
 localStorage.removeItem('filter_by_lotsize');
 }

 setActiveFiltersCount(count);

 dispatch(updatePageNumber(0));  
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0);
 }


 const handleResetClick = () => {
  setIsOpen9(false)
  clearLocalStorage();
 setselectedlivingMin('');
 setselectedlivingMax('');
 setselectedlotMin('');
 setselectedlotMax('');
 localStorage.removeItem('filter_by_livingarea');
 localStorage.removeItem('filter_by_lotsize');
 setActiveFiltersCount(0);
 dispatch(updatePageNumber(0));  
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0);
 }


 useEffect(() => {
 if ((selectedbedroomMinValue !== '' && selectedbedroomMinValue !== null) || 
 (selectedbedroomMaxValue !== '' && selectedbedroomMaxValue !== null) || 
 (selectedbathroomMinValue !== '' && selectedbathroomMinValue !== null) || 
 (selectedbathroomMaxValue !== '' && selectedbathroomMaxValue !== null)) {
 let bedroomRange = '0+ bed';
 let bathroomRange = '0+ bath';
 
 if (selectedbedroomMinValue !== '' && selectedbedroomMaxValue !== '') {
 bedroomRange = `${selectedbedroomMinValue}-${selectedbedroomMaxValue} bed`;
 } else if (selectedbedroomMinValue !== '' && selectedbedroomMaxValue === '') {
 bedroomRange = `${selectedbedroomMinValue}+ bed`;
 } else if (selectedbedroomMinValue === '' && selectedbedroomMaxValue !== '') {
 bedroomRange = `0-${selectedbedroomMaxValue} bed`;
 }
 
 if (selectedbathroomMinValue !== '' && selectedbathroomMaxValue !== '') {
 bathroomRange = `${selectedbathroomMinValue}-${selectedbathroomMaxValue} bath`;
 } else if (selectedbathroomMinValue !== '' && selectedbathroomMaxValue === '') {
 bathroomRange = `${selectedbathroomMinValue}+ bath`;
 } else if (selectedbathroomMinValue === '' && selectedbathroomMaxValue !== '') {
 bathroomRange = `0-${selectedbathroomMaxValue} bath`;
 }
 
 setSelectedRange(`${bedroomRange} / ${bathroomRange}`);
 setIsDoneClicked(true);
 } else {
 setSelectedRange('Rooms');
 }
 }, [selectedbedroomMinValue, selectedbedroomMaxValue, selectedbathroomMinValue, selectedbathroomMaxValue]);


const handlebedroomMinSelect = (value, event) => {
 setselectedbedroomMinValue(value);
 setIsOpen5(false);
 setIsOpen6(true); 
 event.stopPropagation(); 
};

const handlebedroomMaxSelect = (value, event) => {
 setselectedbedroomMaxValue(value);
 setIsOpen6(false);
 event.stopPropagation(); 
};

const handlebathroomMinSelect = (value, event) => {
 setselectedbathroomMinValue(value);
 setIsOpen7(false);
 setIsOpen8(true); 
 event.stopPropagation(); 
};

const handlebathroomMaxSelect = (value, event) => {
 setselectedbathroomMaxValue(value);
 setIsOpen8(false);

 event.stopPropagation(); 
};

const handleDoneClick = () => {
  clearLocalStorage();
 const isSelectionMade = selectedbedroomMinValue !== '' || selectedbedroomMaxValue !== '' || selectedbathroomMinValue !== '' || selectedbathroomMaxValue !== '';

 if (isSelectionMade) {
 let bedroomRange = '0+ bed';
 let bathroomRange = '0+ bath';

 if (selectedbedroomMinValue !== '' && selectedbedroomMaxValue !== '') {
 bedroomRange = `${selectedbedroomMinValue}-${selectedbedroomMaxValue} bed`;
 } else if (selectedbedroomMinValue !== '' && selectedbedroomMaxValue === '') {
 bedroomRange = `${selectedbedroomMinValue}+ bed`;
 } else if (selectedbedroomMinValue === '' && selectedbedroomMaxValue !== '') {
 bedroomRange = `0-${selectedbedroomMaxValue} bed`;
 }

 if (selectedbathroomMinValue !== '' && selectedbathroomMaxValue !== '') {
 bathroomRange = `${selectedbathroomMinValue}-${selectedbathroomMaxValue} bath`;
 } else if (selectedbathroomMinValue !== '' && selectedbathroomMaxValue === '') {
 bathroomRange = `${selectedbathroomMinValue}+ bath`;
 } else if (selectedbathroomMinValue === '' && selectedbathroomMaxValue !== '') {
 bathroomRange = `0-${selectedbathroomMaxValue} bath`;
 }

 localStorage.setItem('filter_by_bedroom', JSON.stringify({ bedmin: selectedbedroomMinValue, bedmax: selectedbedroomMaxValue }));
 localStorage.setItem('filter_by_bathroom', JSON.stringify({ bathmin: selectedbathroomMinValue, bathmax: selectedbathroomMaxValue }));

 setSelectedRange(`${bedroomRange} / ${bathroomRange}`);
 } else {
 setSelectedRange('Rooms');
 }

 setIsDoneClicked(isSelectionMade);
 setIsOpen4(false);
 dispatch(updatePageNumber(0));  
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0);
};

 
const handleClearSelection = () => {
  clearLocalStorage();
 setselectedbedroomMinValue('');
 setselectedbedroomMaxValue('');
 setselectedbathroomMinValue('');
 setselectedbathroomMaxValue('');
 setSelectedRange('Rooms');
 localStorage.removeItem('filter_by_bedroom');
 localStorage.removeItem('filter_by_bathroom');
 
 setIsDoneClicked(false); 
 dispatch(updatePageNumber(0));  
 dispatch(fetchProperties('','',0));
 localStorage.setItem('currentPageNumber',0);
};

const BedroomMinOptions = [0, 1, 2, 3, 4, 5];
const BedroomMaxOptions = [0, 1, 2, 3, 4, 5].filter(option => option > selectedbedroomMinValue);

const BathroomMinOptions = [0, 1, 2, 3, 4, 5];
const BathroomMaxOptions = [0, 1, 2, 3, 4, 5].filter(option1 => option1 > selectedbathroomMinValue);

 return (
 <div className=" relative flex " ref={dropdownRef}>

 <section className="relative inline-block ml-2 text-left">
 <button
 onClick={toggleDropdown}
 className="border border-gray-600 bg-white min-w-[90px] justify-between flex h-[49px] whitespace-nowrap font-medium rounded-lg text-md px-2 text-center items-center"
 type="button"
 aria-expanded={isOpen ? 'true' : 'false'}
 >
 {getPriceButtonText()}
 <ArrowIcon isOpen={isOpen} />
 </button>
 {isOpen && (
 <div className="z-10 absolute left-0 mt-2 w-80 rounded-xl border border-gray-600 shadow bg-white">
 <h1 className="mx-3 my-2 flex justify-between items-center">
 <span>Price</span>
 <button type="button" className="underline text-md text-gray-500 hover:text-gray-800" onClick={handleDone}>
 Done
 </button>
 </h1>
 <div className='flex mt-4'>
 <div>
 <button
 onClick={toggleDropdown1}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mx-5 mb-3 px-2 py-2.5 text-center inline-flex items-center w-28"
 type="button"
 aria-expanded={isOpen1 ? 'true' : 'false'}
 >
 {selectedMin ? `$ ${selectedMin}` : 'MIN'}
 <ArrowIcon isOpen={isOpen1} />
 </button>
 {isOpen1 && (
 <ul className="w-28 rounded-xl overflow-y-auto scroll shadow-xl items-center justify-center scroll-smooth scrollbar-hide max-h-96 mx-5 mb-4 border border-gray-600 bg-white">
 {priceMinData.map((minPrice, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full"

 onClick={(event) => handleMinSelect(minPrice, event)}
 >
 {minPrice.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 <div className='py-2'>
 <span>-</span>
 </div>
 <div>
 <button
 onClick={toggleDropdown2}
 className="border border-gray-600 justify-between mx-5 font-medium rounded-lg text-sm mb-3 px-2 py-2.5 text-center inline-flex items-center w-28"
 type="button"
 aria-expanded={isOpen2 ? 'true' : 'false'}
 >
 {selectedMax ? `$ ${selectedMax}` : 'MAX'}
 <ArrowIcon isOpen={isOpen2} />
 </button>
 {isOpen2 && (
 <ul className="w-28 rounded-xl overflow-y-auto scroll shadow-xl items-center justify-center scroll-smooth scrollbar-hide max-h-96 mx-5 mb-4 border border-gray-600 bg-white">
 {filteredMaxOptions.map((maxPrice, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full" // Inside the min and max dropdown items

 onClick={(event) => handleMaxSelect(maxPrice, event)}
 >
 {maxPrice.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 </div>
 </div>
 )}
 </section>

 <section className="relative inline-block ml-2 text-left">
 <button
 onClick={toggleDropdown3}
 className="border border-gray-600 min-w-[138px] justify-between h-[49px] bg-white font-medium rounded-lg text-md px-1.5 text-center flex items-center whitespace-nowrap"
 type="button"
 aria-expanded={isOpen3 ? 'true' : 'false'}
 >
 {buttonLabel}
 {closeIconVisible && (
 <CloseIcon
 style={{ width: '20px', height: '24px' }} 
 className=" cursor-pointer "
 type="button"
 onClick={handleclear}
 />
)}

 <ArrowIcon isOpen={isOpen3} />
 </button>
 {isOpen3 && (
 <div className="absolute mt-2 bg-white border border-gray-600 shadow-md rounded-2xl z-10">
 <h1 className="mx-3 my-2 w-96 flex justify-between items-center">
 <span>Property type</span>
 <button className="underline text-md text-gray-500 hover:text-gray-800" onClick={handleDone1}>
 Done
 </button>
 </h1>
 <div className="grid grid-cols-3 gap-3 p-4">
 <button
 type="button"
 className={`border border-gray-600 rounded-lg h-20 w-full flex flex-col items-center ${
 selectedTypes.includes('any') ? 'bg-black text-white' : 'hover:bg-gray-100'
 }`}
 onClick={() => handleSelection('any')}
 >
 <MapsHomeWorkOutlinedIcon
 className={`w-6 h-6 mt-4 ${selectedTypes.includes('any') ? 'text-white' : 'text-black'}`}
 />
 <div className="text-sm mt-1">Any</div>
 </button>
 {homeType.map((type) => (
 <button
 key={type.value}
 type="button"
 className={`border border-gray-600 rounded-lg h-20 w-full flex flex-col items-center ${
 selectedTypes.includes(type.value) ? 'bg-black text-white' : 'hover:bg-gray-100'
 
 }`}
 onClick={() => handleSelection(type.value)}
 >
 {homeTypeImages[type.value] && (
 <img
 src={homeTypeImages[type.value]}
 className={`w-6 h-5 mt-4 ${selectedTypes.includes(type.value) ? 'filter-white' : ''}`}
 alt={type.label}
 />
 )}
 <div className="text-sm mt-1 whitespace-normal truncate text-center px-2">
 {type.label}
 </div>
 </button>
 ))}
 </div>
 </div>
 )}
 </section>

 <section className="relative inline-block ml-2 text-left ">
 <button
 id="dropdownDefaultButton"
 onClick={toggleDropdown4}
 className="border border-gray-600 bg-white min-w-[90px] h-[49px] justify-between font-medium rounded-lg text-md px-1.5 text-center flex items-center whitespace-nowrap max-w-[176px] truncate"
 type="button"
 aria-expanded={isOpen4 ? 'true' : 'false'}
 >
 <span className="truncate">{selectedRange}</span> 
 {isDoneClicked && ( 
 <CloseIcon className=" h-4 w-4" onClick={handleClearSelection} />
 )}
 <ArrowIcon isOpen={isOpen4} />
 </button>
 {isOpen4 && (
 <div className="absolute mt-2 w-80 bg-white border border-gray-600 shadow-md rounded-2xl z-10" >
 <h1 className="mx-3 my-2 flex justify-between items-center">
 <span>Rooms</span>
 <button className="underline text-md text-gray-500 hover:text-gray-800" onClick={handleDoneClick}>Done</button>
 </h1>

 <div className="mx-3 mt-4 text-black/60">
 Bedrooms
 <div className='flex'>
 <div>
 <button
 onClick={toggleDropdown5}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-5 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen5 ? 'true' : 'false'}
 >
 {selectedbedroomMinValue ? ` ${selectedbedroomMinValue} ` : 'MIN'}
 
 <ArrowIcon isOpen={isOpen5} />
 </button>
 {isOpen5 && (
 <ul className="w-32 rounded-xl mb-4 border border-gray-600 shadow bg-white">
 {BedroomMinOptions.map((option,index) => (
 <li key={index} type='button' className="flex justify-center items-center px-2 py-2 hover:bg-gray-100 hover:rounded-t-xl cursor-pointer w-full" 
 onClick={(event) => handlebedroomMinSelect(option, event)}>
 {option}
 </li>
 ))}
 </ul>
 )}
 </div>
 
 <div>
 <button
 onClick={toggleDropdown6}
 className="border border-gray-600 font-medium justify-between rounded-lg text-sm mt-2 mx-4 mb-3 px-4 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen6 ? 'true' : 'false'}
 >
 {selectedbedroomMaxValue ? ` ${selectedbedroomMaxValue} ` : 'MAX'}
 
 <ArrowIcon isOpen={isOpen6} />
 </button>
 {isOpen6 && (
 <ul className="w-32 rounded-xl mx-5 mb-4 border border-gray-600 shadow bg-white">
 {BedroomMaxOptions.map((option, index) => (
 <li key={index} type='button' className="flex justify-center items-center hover:rounded-t-xl px-2 py-2 hover:bg-gray-100 cursor-pointer w-full" 
 onClick={(event) => handlebedroomMaxSelect(option, event)}>
 {option}
 </li>
 ))}
 </ul>
 )}
 </div>
 </div>
 </div>
 <div className="mx-3 mt-4 text-black/60">
 Bathrooms
 <div className='flex'>
 <div>
 <button
 onClick={toggleDropdown7}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-5 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen7 ? 'true' : 'false'}
 >
 {selectedbathroomMinValue ? ` ${selectedbathroomMinValue} ` : 'MIN'}
 
 <ArrowIcon isOpen={isOpen7} />
 </button>
 {isOpen7 && (
 <ul className="w-32 rounded-xl mb-4 border border-gray-600 shadow bg-white">
 {BathroomMinOptions.map((option1, index) => (
 <li key={index} type='button' className="flex justify-center hover:rounded-t-xl items-center px-2 py-2 hover:bg-gray-100 cursor-pointer w-full" 
 onClick={(event) => handlebathroomMinSelect(option1, event)}>
 {option1}
 </li>
 ))}
 </ul>
 )}
 </div>
 <div>
 <button
 onClick={toggleDropdown8}
 className="border border-gray-600 font-medium justify-between rounded-lg text-sm mt-2 mx-4 mb-3 px-4 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen8 ? 'true' : 'false'}
 >
 {selectedbathroomMaxValue ? ` ${selectedbathroomMaxValue} ` : 'MAX'}
 
 <ArrowIcon isOpen={isOpen8} />
 </button>
 {isOpen8 && (
 <ul className="w-32 rounded-xl mx-5 mb-4 border border-gray-600 shadow bg-white">
 {BathroomMaxOptions.map((option1, index) => (
 <li key={index} type='button' className="flex justify-center hover:rounded-t-xl items-center px-2 py-2 hover:bg-gray-
 100 cursor-pointer w-full" 
 onClick={(event) =>handlebathroomMaxSelect(option1, event)}
 >
 {option1}
 </li>
 ))}
 </ul>
 )}
 </div>
 </div>
 </div>
 </div>
 )}
 </section>

 <section className="relative inline-block ml-2 text-left">
 <button
 onClick={toggleDropdown9}
 className="border border-gray-600 bg-white min-w-[90px] justify-between h-[49px] whitespace-nowrap font-medium rounded-lg text-md px-1.5 text-center flex items-center"
 type="button"
 aria-expanded={isOpen9 ? 'true' : 'false'}
 >
 More
 {activeFiltersCount > 0 && (
 <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-black border-1 border-gray-600 rounded-full -top-2 -end-2">
 {activeFiltersCount}
 </div>
 )}
 <ArrowIcon isOpen={isOpen9} />
 </button>
 {isOpen9 && (
 <div className="absolute mt-2 w-80 overflow-y-auto scroll shadow-xl whitespace-nowrap scroll-smooth scrollbar-hide bg-white border border-gray-600 rounded-2xl z-10 top-12" >
 
 <h1 className="mx-3 my-2 flex justify-between items-center">
 <span>More</span>
 
 </h1>
 <div className=" mt-4 border-b border-gray-400 ">
 <h1 onClick={toggleDropdown10} 
 type="button"
 aria-expanded={isOpen10 ? 'true' : 'false'}
 className="flex justify-between items-center my-2 mt-4 mx-4 cursor-pointer">
 Living area
 <div className='flex items-center'>
 <div className='text-xs text-gray-500'>
 {getlivingareaText()}
 </div>
 <ArrowIcon isOpen={isOpen10} />
 </div>
 </h1>
 {isOpen10 && (
 <div className='flex mx-4'>
 <div>
 <button
 onClick={toggleDropdown11}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-5 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen11 ? 'true' : 'false'}
 >
 {selectedlivingMin ? ` ${selectedlivingMin} sqft` : 'MIN'}
 <ArrowIcon isOpen={isOpen11} /> 
 </button>
 {isOpen11 && (
 <ul className="w-32 rounded-xl max-h-48 overflow-y-auto scrollbar-hide mb-4 border border-gray-600 shadow bg-white">
 {livingAreaMin.map((minarea, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full" 
 onClick={(event) => handlelivingMinSelect(minarea, event)}
 type="button"
 >
 {minarea.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 <div className='item-center py-4 px-3'> - </div>
 <div>
 <button
 onClick={toggleDropdown12}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-4 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen12 ? 'true' : 'false'}
 >
 {selectedlivingMax ? ` ${selectedlivingMax} sqft` : 'MAX'}
 <ArrowIcon isOpen={isOpen12} /> 
 </button>
 {isOpen12 && (
 <ul className="w-32 rounded-xl max-h-48 overflow-y-auto scrollbar-hide mb-4 border border-gray-600 shadow bg-white">
 {filteredlivingareaMaxOptions.map((maxarea, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full" // Inside the min and max dropdown items
 onClick={(event) => handlelivingMaxSelect(maxarea, event)}
 type="button"
 >
 {maxarea.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 </div>
 )}
 </div>
 <div className=" mt-4 mb-6 border-gray-400 ">
 <h2 onClick={toggleDropdown13} 
 type="button"
 aria-expanded={isOpen13 ? 'true' : 'false'}
 className="flex justify-between items-center my-2 mt-4 mx-4 cursor-pointer">
 Lot Size
 <div className='flex items-center'>
 <div className='text-xs text-gray-500'>
 {getlotsizeText()}
 </div>
 <ArrowIcon isOpen={isOpen13} />
 </div>
 </h2>
 {isOpen13 && (
 <div className='flex mx-4'>
 <div>
 <button
 onClick={toggleDropdown14}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-5 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen14 ? 'true' : 'false'}
 >
 {selectedlotMin ? ` ${selectedlotMin} sqft` : 'MIN'}
 <ArrowIcon isOpen={isOpen14} /> 
 </button>
 {isOpen14 && (
 <ul className="w-32 rounded-xl max-h-48 overflow-y-auto scrollbar-hide mb-4 border border-gray-600 shadow bg-white">
 {lotsizemin.map((lotmin, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full" 
 onClick={(event) => handlelotMinSelect(lotmin, event)}
 type="button"
 >
 {lotmin.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 <div className='item-center py-4 px-3'> - </div>
 <div>
 <button
 onClick={toggleDropdown15}
 className="border border-gray-600 justify-between font-medium rounded-lg text-sm mt-2 mb-3 px-4 py-2.5 text-center inline-flex items-center w-32"
 type="button"
 aria-expanded={isOpen15 ? 'true' : 'false'}
 >
 {selectedlotMax ? ` ${selectedlotMax} sqft` : 'MAX'}
 <ArrowIcon isOpen={isOpen15} /> 
 </button>
 {isOpen15 && (
 <ul className="w-32 rounded-xl max-h-48 overflow-y-auto scrollbar-hide mb-4 border border-gray-600 shadow bg-white">
 {filteredlotsizeMaxOptions.map((lotmax, index) => (
 <li key={index} className="flex justify-center items-center px-2 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-t-xl w-full" 
 onClick={(event) => handlelotMaxSelect(lotmax, event)}
 type="button"
 >
 {lotmax.label}
 </li>
 ))}
 </ul>
 )}
 </div>
 </div>
 )}
 </div>
 <div className='border-t border-gray-600 sticky bottom-0 bg-white py-3'>
 <div className='flex mx-6 justify-between'>
 <button type='button' className=" text-gray-500 hover:text-gray-800" onClick={handleResetClick} >
 Reset
 </button>
 <button type='button' className=' bg-black hover:bg-black/70 text-white rounded-full py-1.5 px-20' onClick={handleApplyClick}>
 Apply
 </button>
 </div>
</div>

 </div>
 
 )}
 </section>

 </div>
 );
}

export default Filter;
