import React from 'react';


const sortskeleton = () => {
 

  return (
    
      <div className="animate-pulse"> 
        <h1 className="ml-6 mt-6 h-6 bg-gray-300 rounded-full mx-4 w-2/5  justify-center "> </h1>
        <h2  className="h-4 bg-gray-300 mt-4 ml-6 rounded-full  w-64 mb-8 ">
        <p className="h-4 bg-gray-300 mt-4 ml-72 rounded-full  w-64 "></p>
        </h2>
      </div>
      
  );
};

export default sortskeleton;
