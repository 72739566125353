import React, { useState, useRef, useEffect } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RxDotFilled } from 'react-icons/rx';
import { PROFILEPHOTO_URL } from '../../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const Postimage = ({ post }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isMuted, setIsMuted] = useState(true);
    const audioRef = useRef(null);
    const slides = post?.contents ?? [];
    const isSinglePost = slides.length === 1;

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = isMuted;
        }
    }, [isMuted]);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const prevSlide = () => {
        setLoading(true);
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        setLoading(true);
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setLoading(true);
        setCurrentIndex(slideIndex);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    return (
        <div className="bg-white/70 w-full h-[330px] mt-4">
            {isSinglePost ? (
                <div className="h-[330px] w-full relative group bg-black/90">
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <CircularProgress />
                        </div>
                    )}
                    <img
                        src={`${PROFILEPHOTO_URL}/${slides[0]?.url}`}
                        alt="Post"
                        className={`absolute inset-0 w-full h-full  object-contain rounded-tl-lg rounded-bl-lg duration-500 ease-in-out ${loading ? 'hidden' : 'block'}`}
                        onLoad={handleImageLoad}
                    />
                    {post?.hasBlurt && post?.blurt && (
                        <div className="absolute bottom-0 right-4 z-20 flex flex-col items-center space-y-2">
                            <audio ref={audioRef} src={`${PROFILEPHOTO_URL}/${post.blurt.url}`} loop autoPlay muted />
                            <button onClick={toggleMute} className="bg-black text-white rounded-full p-2" aria-label={isMuted ? 'Unmute' : 'Mute'}>
                                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-full h-[330px] relative group m-auto bg-black/90 overflow-hidden">
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center z-10">
                            <CircularProgress />
                        </div>
                    )}
                    <img
                        src={`${PROFILEPHOTO_URL}/${slides[currentIndex]?.url}`}
                        alt="Slide"
                        className={`absolute inset-0 w-full h-full object-contain rounded-tl-lg rounded-bl-lg duration-500 ease-in-out ${loading ? 'hidden' : 'block'}`}
                        onLoad={handleImageLoad}
                    />
                    {post?.hasBlurt && post?.blurt && (
                        <div className="absolute bottom-0 right-4 z-20 flex flex-col items-center space-y-2">
                            <audio ref={audioRef} src={`${PROFILEPHOTO_URL}/${post.blurt.url}`} loop autoPlay muted />
                            <button onClick={toggleMute} className="bg-black text-white rounded-full p-2" aria-label={isMuted ? 'Unmute' : 'Mute'}>
                                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                            </button>
                        </div>
                    )}
                    <div
                        className="absolute top-[50%] -translate-x-1 translate-y-[-50%] left-5 rounded-full p-2 bg-black text-white cursor-pointer"
                        onClick={prevSlide}
                        aria-label="Previous Slide"
                    >
                        <ChevronLeftIcon />
                    </div>
                    <div
                        className="absolute top-[50%] -translate-x-1 translate-y-[-50%] right-5 rounded-full p-2 bg-black text-white cursor-pointer"
                        onClick={nextSlide}
                        aria-label="Next Slide"
                    >
                        <ChevronRightIcon />
                    </div>
                    <div className="absolute bottom-0 w-full flex justify-center">
                        {slides.map((slide, slideIndex) => (
                            <div
                                key={slideIndex}
                                onClick={() => goToSlide(slideIndex)}
                                className={`text-2xl cursor-pointer ${slideIndex === currentIndex ? 'text-blue-500' : 'text-white'}`}
                                aria-label={`Go to slide ${slideIndex + 1}`}
                            >
                                <RxDotFilled />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Postimage;
