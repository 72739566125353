// import React, { useState, useEffect, useRef } from "react";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { CommentIcon, LikeIcon, ShareIcon } from "../icon";
// import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import axios from "axios";
// import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
// import { formatDate } from "../../UserProfile/Mdlgscreen/Utils/dateutil";
// import { CircularProgress } from '@mui/material';
// import Postimage from "./postimages";
// import Postbottomsection from "./postbottomsection";
// import PostComment from "./postsubmitcomment";
// import Modal from '../../UserProfile/Mdlgscreen/Postmodal/PostModal';

// const SmPost = () => {
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [loadingImages, setLoadingImages] = useState([]);
//   const [activePostIndex, setActivePostIndex] = useState(null);
//   const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
//   const [selectedPost, setSelectedPost] = useState(null);


//   const fetchPosts = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('Token not found in localStorage');
//     }

//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
//       headers: { 
//         'Authorization': `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await axios.request(config);
//       const newPosts = response.data.data;

//       const uniqueNewPosts = newPosts.filter(newPost => 
//         !posts.some(post => post.id === newPost.id)
//       );

//       setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
//       setLoadingImages(prevLoadingImages => [
//         ...prevLoadingImages, 
//         ...new Array(uniqueNewPosts.length).fill(true)
//       ]);
//       setHasMore(newPosts.length > 0);

//       if (newPosts.length === 0 || newPosts.length < 10) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     fetchPosts(0);
//   }, []);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchPosts(page);
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
//           && hasMore && !loading && !loadingMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [hasMore, loading, loadingMore]);


//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           const index = Number(entry.target.getAttribute('data-index'));
//           setActivePostIndex(index);
//         } else {
//           const index = Number(entry.target.getAttribute('data-index'));
//           if (index === activePostIndex) {
//             setActivePostIndex(null);
//           }
//         }
//       });
//     }, { threshold: 0.5 });

//     document.querySelectorAll('.post').forEach((element) => {
//       observer.observe(element);
//     });

//     return () => {
//       document.querySelectorAll('.post').forEach((element) => {
//         observer.unobserve(element);
//       });
//     };
//   }, [posts, activePostIndex]);


//   const incrementCommentCount = (postId) => {
//     setPosts(prevPosts =>
//       prevPosts.map(post =>
//         post.id === postId ? { ...post, commentCount: post.commentCount + 1 } : post
//       )
//     );
//   };

//   const openModal = (post) => {
//     setSelectedPost(post);
//     localStorage.setItem('postId', post.id);
//   };

//   const closeModal = () => {
//     setSelectedPost(null);
//   };

//   return (
//     <>
//       <div className="relative mt-0 2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-44 sm:ml-0 ml-0 ">
        
//         {loading && posts.length === 0 ? (
//           <div className="mt-24 flex justify-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <>
//             <div className="flex flex-col sm:w-[430px] w-[352px]">
//               {posts.map((post, index) => (
//                 <div key={post.id} data-index={index} className="post flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
//                   <div className="flex justify-between w-full items-center mt-2 ml-2">
//                     <div className="flex items-center">
//                       <div className="bg-black rounded-full  w-10 h-10 mb-1  overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <div className="ml-2">
//                         <span className="block text-black text-sm">{post.user.firstName} {post.user.lastName}</span>
//                         <span className="block text-gray-500 text-xs">
//                           {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
//                         </span>
//                       </div>
//                     </div>
//                     {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
//                   </div>

//                   <div className="flex justify-center items-center">
//                     <Postimage post={post} isActive={activePostIndex === index} />
//                   </div>
                 
//                      <div>
//                       <Postbottomsection post={post}  />


//           <div className="mx-3.5 mb-1 mt-0 text-gray-500">
//             <div className="flex text-sm cursor-pointer"    >
//             View All {post.commentCount} Comments
//             </div>
//             <div className="flex items-center text-xs mx-1">
//               {/* <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
//                 <img
//                   src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`}
//                   alt="Profile"
//                   className="object-cover w-full h-full"
//                 />
//               </div> */}
//                 <PostComment
//                           postId={post.id}
//                           incrementCommentCount={incrementCommentCount}
//                           activeEmojiPicker={activeEmojiPicker}
//                           setActiveEmojiPicker={setActiveEmojiPicker}
//                         />           
//                          </div>
//           </div>
//                     </div>

//                   <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
//                 </div>
//               ))}
//             </div>
//             {loadingMore && (
//               <div className="mt-8 flex justify-center">
//                 <CircularProgress />
//               </div>
//             )}
//             {!loadingMore && !hasMore && posts.length > 0 && (
//               <div className="mt-4 text-gray-400 text-center">No more posts.</div>
//             )}
//           </>
//         )}
//       </div>
//       {selectedPost && (
//         <Modal post={selectedPost} onClose={closeModal} />
//       )}
//     </>
//   );
// };

// export default SmPost;


// import React, { useState, useEffect, useRef } from "react";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { CommentIcon, LikeIcon, ShareIcon } from "../icon";
// import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
// import axios from "axios";
// import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
// import { formatDate } from "../../UserProfile/Mdlgscreen/Utils/dateutil";
// import { CircularProgress } from '@mui/material';
// import Postimage from "./postimages";
// import Postbottomsection from "./postbottomsection";
// import PostComment from "./postsubmitcomment";
// import Modal from '../../UserProfile/Mdlgscreen/Postmodal/PostModal';
// import { BottomSheet } from 'react-spring-bottom-sheet';
// import 'react-spring-bottom-sheet/dist/style.css';

// const SmPost = () => {
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);
//   const [loadingImages, setLoadingImages] = useState([]);
//   const [activePostIndex, setActivePostIndex] = useState(null);
//   const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
//   const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
//   const [bottomSheetPost, setBottomSheetPost] = useState(null);


//   const fetchPosts = async (pageNumber) => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       throw new Error('Token not found in localStorage');
//     }

//     const config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
//       headers: { 
//         'Authorization': `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await axios.request(config);
//       const newPosts = response.data.data;

//       const uniqueNewPosts = newPosts.filter(newPost => 
//         !posts.some(post => post.id === newPost.id)
//       );

//       setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
//       setLoadingImages(prevLoadingImages => [
//         ...prevLoadingImages, 
//         ...new Array(uniqueNewPosts.length).fill(true)
//       ]);
//       setHasMore(newPosts.length > 0);

//       if (newPosts.length === 0 || newPosts.length < 10) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoading(false);
//       setLoadingMore(false);
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     fetchPosts(0);
//   }, []);

//   useEffect(() => {
//     if (page > 0) {
//       setLoadingMore(true);
//       fetchPosts(page);
//     }
//   }, [page]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
//           && hasMore && !loading && !loadingMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, [hasMore, loading, loadingMore]);


//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           const index = Number(entry.target.getAttribute('data-index'));
//           setActivePostIndex(index);
//         } else {
//           const index = Number(entry.target.getAttribute('data-index'));
//           if (index === activePostIndex) {
//             setActivePostIndex(null);
//           }
//         }
//       });
//     }, { threshold: 0.5 });

//     document.querySelectorAll('.post').forEach((element) => {
//       observer.observe(element);
//     });

//     return () => {
//       document.querySelectorAll('.post').forEach((element) => {
//         observer.unobserve(element);
//       });
//     };
//   }, [posts, activePostIndex]);


//   const incrementCommentCount = (postId) => {
//     setPosts(prevPosts =>
//       prevPosts.map(post =>
//         post.id === postId ? { ...post, commentCount: post.commentCount + 1 } : post
//       )
//     );
//   };

//   const openBottomSheet = (post) => {
//     setBottomSheetPost(post);
//     setIsBottomSheetOpen(true);
//   };

//   const closeBottomSheet = () => {
//     setIsBottomSheetOpen(false);
//     setBottomSheetPost(null);
//   };


//   return (
//     <>
//       <div className="relative mt-0 2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-44 sm:ml-0 ml-0 ">
        
//         {loading && posts.length === 0 ? (
//           <div className="mt-24 flex justify-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <>
//             <div className="flex flex-col sm:w-[430px] w-[352px]">
//               {posts.map((post, index) => (
//                 <div key={post.id} data-index={index} className="post flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
//                   <div className="flex justify-between w-full items-center mt-2 ml-2">
//                     <div className="flex items-center">
//                       <div className="bg-black rounded-full  w-10 h-10 mb-1  overflow-hidden">
//                         <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//                       </div>
//                       <div className="ml-2">
//                         <span className="block text-black text-sm">{post.user.firstName} {post.user.lastName}</span>
//                         <span className="block text-gray-500 text-xs">
//                           {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
//                         </span>
//                       </div>
//                     </div>
//                     {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
//                   </div>

//                   <div className="flex justify-center items-center">
//                     <Postimage post={post} isActive={activePostIndex === index} />
//                   </div>
                 
//                      <div>
//                       <Postbottomsection post={post}  />


//           <div className="mx-3.5 mb-1 mt-0 text-gray-500">
//             <div className="flex text-sm cursor-pointer"   onClick={() => openBottomSheet(post)}  >
//             View All {post.commentCount} Comments
//             </div>
//             <div className="flex items-center text-xs mx-1">
//               {/* <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
//                 <img
//                   src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`}
//                   alt="Profile"
//                   className="object-cover w-full h-full"
//                 />
//               </div> */}
//                 <PostComment
//                           postId={post.id}
//                           incrementCommentCount={incrementCommentCount}
//                           activeEmojiPicker={activeEmojiPicker}
//                           setActiveEmojiPicker={setActiveEmojiPicker}
//                         />           
//                          </div>
//           </div>
//                     </div>

//                   <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
//                 </div>
//               ))}
//             </div>
//             {loadingMore && (
//               <div className="mt-8 flex justify-center">
//                 <CircularProgress />
//               </div>
//             )}
//             {!loadingMore && !hasMore && posts.length > 0 && (
//               <div className="mt-4 text-gray-400 text-center">No more posts.</div>
//             )}
//           </>
//         )}
//       </div>
      

// <BottomSheet
//         open={isBottomSheetOpen}
//         onDismiss={closeBottomSheet}
//         snapPoints={({ maxHeight }) => maxHeight / 2}
//         zIndex={50} 
//         className="bottom-sheet fixed inset-0 z-50 "
//       >
//         <div className="p-1">
//           <h2 className="text-lg font-semibold flex  justify-center">Comments </h2>
         
//         </div>
//       </BottomSheet>
//     </>
//   );
// };

// export default SmPost;


import React, { useState, useEffect, useRef } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CommentIcon, LikeIcon, ShareIcon } from "../icon";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import axios from "axios";
import { API_URL, PROFILEPHOTO_URL } from "../../../../Config/config";
import { formatDate } from "../../UserProfile/Mdlgscreen/Utils/dateutil";
import { CircularProgress } from '@mui/material';
import Postimage from "./postimages";
import Postbottomsection from "./postbottomsection";
import PostComment from "./postsubmitcomment";
import Modal from '../../UserProfile/Mdlgscreen/Postmodal/PostModal';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import BottomSheetComment from "./BottomSheet";
import CommentModal from '../../UserProfile/Smscreen/postmodal/commentmodal';
import { PostAddSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const SmPost = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingImages, setLoadingImages] = useState([]);
  const [activePostIndex, setActivePostIndex] = useState(null);
  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [bottomSheetPost, setBottomSheetPost] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const navigate = useNavigate();


  const fetchPosts = async (pageNumber) => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token not found in localStorage');
    }

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/posts/v3/public-feed?page_number=${pageNumber}`,
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    };

    try {
      const response = await axios.request(config);
      const newPosts = response.data.data;

      const uniqueNewPosts = newPosts.filter(newPost => 
        !posts.some(post => post.id === newPost.id)
      );

      setPosts(prevPosts => [...prevPosts, ...uniqueNewPosts]);
      setLoadingImages(prevLoadingImages => [
        ...prevLoadingImages, 
        ...new Array(uniqueNewPosts.length).fill(true)
      ]);
      setHasMore(newPosts.length > 0);

      if (newPosts.length === 0 || newPosts.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchPosts(0);
  }, []);

  useEffect(() => {
    if (page > 0) {
      setLoadingMore(true);
      fetchPosts(page);
    }
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2) 
          && hasMore && !loading && !loadingMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, loadingMore]);


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));
          setActivePostIndex(index);
        } else {
          const index = Number(entry.target.getAttribute('data-index'));
          if (index === activePostIndex) {
            setActivePostIndex(null);
          }
        }
      });
    }, { threshold: 0.5 });

    document.querySelectorAll('.post').forEach((element) => {
      observer.observe(element);
    });

    return () => {
      document.querySelectorAll('.post').forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [posts, activePostIndex]);


  const incrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId ? { ...post, commentCount: post.commentCount + 1 } : post
      )
    );
  };

  const decrementCommentCount = (postId) => {
    setPosts(prevPosts =>
      prevPosts.map(post =>
        post.id === postId && post.commentCount > 0 ? { ...post, commentCount: post.commentCount - 1 } : post
      )
    );
  };

  const openBottomSheet = (post) => {
    setBottomSheetPost(post);
    setIsBottomSheetOpen(true);
    localStorage.setItem('postId', post.id);

  };

  const closeBottomSheet = () => {
    setIsBottomSheetOpen(false);
    setBottomSheetPost(null);
    localStorage.removeItem('postId'); 
  };

  const handleViewComments = (post) => {
    setSelectedPost(post);
    setShowCommentModal(true);
    localStorage.setItem('postId',  post.id);
  };

const handleCloseCommentModal = () => {
    setShowCommentModal(false);
};


const handleProfile = (userId) => {
  navigate(`/smartsocial/${userId}/`);
};

  return (
    <>
      <div className="relative mt-0 2xl:ml-64 xl:ml-64 lg:ml-64 md:ml-44 sm:ml-0 ml-0 ">
        
        {loading && posts.length === 0 ? (
          <div className="mt-24 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="flex flex-col sm:w-[430px] w-[352px]">
              {posts.map((post, index) => (
                <div key={post.id} data-index={index} className="post flex bg-gray-200  flex-col mb-1 rounded-md shadow-xl border border-black">
                  <div className="flex justify-between w-full items-center mt-2 ml-2">
                    <div className="flex items-center">
                      <div className="bg-black rounded-full  w-10 h-10 mb-1  overflow-hidden cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                        <img src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
                      </div>
                      <div className="ml-2">
                        <span className="block text-black text-sm cursor-pointer" onClick={() => handleProfile(post.user.userId)}>
                          {post.user.firstName} {post.user.lastName}
                          </span>
                        <span className="block text-gray-500 text-xs">
                          {post.location.length > 48 ? `${post.location.slice(0, 48)}...` : post.location}
                        </span>
                      </div>
                    </div>
                    {/* <MoreVertIcon style={{ fontSize: 40 }} /> */}
                  </div>

                  <div className="flex justify-center items-center">
                    <Postimage post={post} isActive={activePostIndex === index} />
                  </div>
                 
                     <div>
                      <Postbottomsection post={post} handleViewComments={handleViewComments} />


          <div className="mx-3.5 mb-1 mt-0 text-gray-500">
          <div className="flex text-sm cursor-pointer" onClick={() => handleViewComments(post)}>
  View All {post.commentCount} Comments
</div>

            <div className="flex items-center text-xs mx-1">
              {/* <div className="bg-black rounded-full w-8 h-8 overflow-hidden">
                <img
                  src={`${PROFILEPHOTO_URL}/${post.user.profilePhoto}`}
                  alt="Profile"
                  className="object-cover w-full h-full"
                />
              </div> */}
                <PostComment
                          postId={post.id}
                          incrementCommentCount={incrementCommentCount}
                          activeEmojiPicker={activeEmojiPicker}
                          setActiveEmojiPicker={setActiveEmojiPicker}
                        />           
                         </div>
          </div>
                    </div>

                  <div className="mx-3.5 mb-2 text-xs text-gray-500">{formatDate(post.createdAt)}</div>
                </div>
              ))}
            </div>
            {loadingMore && (
              <div className="mt-8 flex justify-center">
                <CircularProgress />
              </div>
            )}
            {!loadingMore && !hasMore && posts.length > 0 && (
              <div className="mt-4 text-gray-400 text-center">No more posts.</div>
            )}
          </>
        )}
      </div>
      {showCommentModal && (
                <CommentModal post={posts}     postIds={selectedPost.id} 
                onClose={handleCloseCommentModal} incrementCommentCount={incrementCommentCount} decrementCommentCount={decrementCommentCount} />
            )}

    {/* <BottomSheetComment 
        isBottomSheetOpen={isBottomSheetOpen}
        closeBottomSheet={closeBottomSheet} 
        post={posts}
    /> */}

   {/* <BottomSheet
        open={isBottomSheetOpen}
        onDismiss={closeBottomSheet}
        snapPoints={({ maxHeight }) => [maxHeight * 0.25, maxHeight * 0.5, maxHeight * 0.75]}
        defaultSnap={({ maxHeight }) => maxHeight * 0.5}
        blocking={false}
        className="bottom-sheet fixed inset-0 z-50 "
      >
        <div className="p-1 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 50px)' }}>
          <h2 className="text-lg font-semibold flex  justify-center">Comments </h2>
        </div>
      </BottomSheet> */}
    </>
  );
};

export default SmPost;