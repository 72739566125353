import apartment from '../../../../assets/images/apartment.png';
import home from '../../../../assets/images/home.png';
import multi from '../../../../assets/images/building.png';
import mobile from '../../../../assets/images/home-2.png';
import condo from '../../../../assets/images/townhouse.png';
import condo1 from '../../../../assets/images/townhouse-2.png';
import town from '../../../../assets/images/home.png';
import land from '../../../../assets/images/field.png';
import farm from '../../../../assets/images/farm-house.png';
import duplex from '../../../../assets/images/duplex.png';

const homeTypeImages = {
  single_family: home,
  multi_family: multi,
  apartment: apartment,
  duplex_triplex: duplex,
  condos: condo1,
  condop: condo,
  condo_townhome: town,
  condo_townhome_rowhome_coop: town,
  townhomes: town,
  coop: town,
  land: land,
  farm: farm,
  mobile: mobile,
};

export default homeTypeImages;
