// import React, { useState, useEffect } from 'react';
// import { useNavigate, Routes, Route, useLocation , Navigate } from 'react-router-dom';
// import Profile from "../../../../assets/images/profile.jpeg"
// import { PostIcon, VidsIcon, AudioIcon } from './smicon';
// import SmPostsGrid from './smpostgird';
// import SmVidsGrid from './smvideogrid';
// import SmAudio from './Smaudio';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { fetchUserData } from '../userapi';
// import '../Mdlgscreen/style.css';
// import CircularProgress from '@mui/material/CircularProgress';
// import { PROFILEPHOTO_URL } from '../../../../Config/config';
// import Userfollowersfollowing from './userfollowers';

// const Smuser = ({user, loading, error}) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [activeTab, setActiveTab] = useState('vids');
//   const [selectedUserFollower, setSelectedUserFollower] = useState(null);
//   const [activeModalTab, setActiveModalTab] = useState('1');

//   useEffect(() => {
//     const path = location.pathname;
  
//     const isProfileVidsPath = /^\/smartsocial\/profile\/vids(?:\/|$)/.test(path);
//     const isProfilePath = /^\/smartsocial\/profile\/post(?:\/|$)/.test(path);
//     const isProfileAudioPath = /^\/smartsocial\/profile\/audio(?:\/|$)/.test(path);
    
//     if ( isProfileVidsPath && !isProfilePath && !isProfileAudioPath) {
//       setActiveTab('vids');
//     } else {
//       const lastPart = path.split('/').filter(Boolean).pop(); 
//       setActiveTab(lastPart || 'vids');
//     }
//   }, [location.pathname]);
  
//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
    
//     if (tab !== 'vids') {
//       navigate(tab);
//     } else {
 
//       navigate('./vids');
//     }
//   };

//   const openModal = (tab) => {
//     setActiveModalTab(tab); 
//     setSelectedUserFollower(true); 
//   };

//   const closeModal = () => {
//     setSelectedUserFollower(null); 
//   };

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className='text-white'><CircularProgress /></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <div className='text-white'>Error: {error}</div>
//       </div>
//     );
//   }
  


//   return (
//     <div className="flex flex-col items-center mt-20 mb-28 post-container w-full">
//          {user && (
//           <>
//       <div className="relative w-full max-w-5xl">
//         <div className="flex absolute w-20 sm:w-24 sm:h-24 h-20 bg-black rounded-full overflow-hidden ml-4">
//         <img src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
//         </div>

//         <div className="flex items-center text-white text-md sm:text-lg ml-28 sm:ml-40 font-regular pt-2">
//         {user.firstName} {user.lastName}
//         </div>
//         {/* {user.canEdit ? (
//   <button
//     type="button"
//     className="text-white bg-transparent border-2 border-black/40 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-0.5 ml-28 sm:ml-40 mt-3"
//   >
//     Edit
//   </button>
// ) : (
//   user.isUserFollowing ? (
//     <button
//       type="button"
//       className="text-white border border-gray-400 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-1 ml-28 sm:ml-40 mt-3"
//     >
//       Following
//     </button>
//   ) : (
//     <button
//       type="button"
//       className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-1 ml-28 sm:ml-40 mt-3"
//     >
//       Follow
//     </button>
//   )
// )} */}

//         <div className="flex text-white ml-6 mt-16 sm:mt-20 "> @{user.userName}</div>
//         {/* <div className="flex text-white ml-8 mt-1">User Bio</div> */}
//       </div>

//       <div className="flex items-center justify-center w-full border-t border-b border-white mt-6">

     

//         <div className="  text-lg text-white my-2 mx-6 sm:mx-14 cursor-pointer" onClick={() => openModal('1')}>
//        <div className="flex justify-center items-center text-sm" >
//        {user.followers}
//           </div>
//        <span className="text-gray-500 text-md  " >followers</span>
       
//         </div> 
        
//         <div className="  text-lg text-white my-2 mx-6 sm:mx-14 cursor-pointer" onClick={() => openModal('2')}>
//         <div className="flex justify-center items-center text-sm">
//         {user.followings}
//           </div>
//        <span className="text-gray-500 text-md ">following</span>
       
//         </div> 

//         {/* <div className="text-lg text-white my-2 mx-6 sm:mx-14">
//     <div className="flex justify-center items-center text-sm">
//         <div>{user.posts.length}</div>
//     </div>
//     <span className="text-gray-500 text-md">post</span>
// </div> */}

// <div className="text-lg text-white my-2 mx-6 sm:mx-14">
//     <div className="flex justify-center items-center text-sm">
//         <div>{user.totalLikes}</div>
//     </div>
//     <span className="text-gray-500 text-md">likes</span>
// </div>

//         </div>

//         <div className="w-full  border-b border-white   ">
//           <div className="flex justify-center text-lg text-white flex-wrap -mb-px">
//           <div
//               className={`flex mx-4  sm:mx-12 sm:p-3 cursor-pointer p-2 items-center text-gray-400 ${
//                 activeTab === 'vids' ? 'active border-b-4 text-white' : ''
//               }`}
//               onClick={() => handleTabChange('vids')}
//             >
//               <VidsIcon  active={activeTab === 'vids'}/>
//               <div className="mx-2 sm:mx-2.5">Vids</div>
//             </div>
//             <div
//               className={`flex mx-4 sm:mx-14 cursor-pointer  p-2 sm:p-3  items-center text-gray-400 ${
//                 activeTab === 'post' ? 'active border-b-4 text-white' : ''
//               }`}
//              onClick={() => handleTabChange('post')}
//             >
//               <PostIcon active={activeTab === 'post'} />
//               <div className="mx-2 sm:mx-2.5">Post</div>
//             </div>

//             {/* <div
//               className={`flex mx-4 sm:mx-12 sm:p-3 cursor-pointer p-2 items-center text-gray-400 ${
//                 activeTab === 'audio' ? 'active border-b-4 text-white' : ''
//               }`}
//               onClick={() => handleTabChange('audio')}
//             >
//               <AudioIcon  active={activeTab === 'audio'}/>
//               <div className="mx-2 sm:mx-2.5">Audio</div>
//             </div> */}
//           </div>
//         </div>

//          <Routes>
   
//           <Route path="vids" element={<SmVidsGrid vids={user.vids} />} />
//           <Route path="post" element={<SmPostsGrid posts={user.posts} />} />
//           <Route path="audio" element={<SmAudio />} />
//           <Route path="/" element={<Navigate to="vids" replace />} />
//         </Routes>
//         </>
//          )}
//          {selectedUserFollower && (
//         <Userfollowersfollowing activeTab={activeModalTab} onClose={closeModal}  userName={`${user.firstName} ${user.lastName}`}  /> 
//       )}
//       </div>
   
//   );
// };

// export default Smuser;


import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation , Navigate } from 'react-router-dom';
import { PostIcon, VidsIcon, AudioIcon } from './smicon';
import SmPostsGrid from './smpostgird';
import SmVidsGrid from './smvideogrid';
import SmAudio from './Smaudio';
import '../Mdlgscreen/style.css';
import CircularProgress from '@mui/material/CircularProgress';
import { PROFILEPHOTO_URL } from '../../../../Config/config';
import Userfollowersfollowing from './userfollowers';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

const Smuser = ({user, loading, error}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedUserFollower, setSelectedUserFollower] = useState(null);
  const [activeModalTab, setActiveModalTab] = useState('1');
  const [value, setValue] = useState('1'); 
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    const path = location.pathname;

    if (path.includes('/vids')) {
      setValue('1');
    } else if (path.includes('/post')) {
      setValue('2');
    } else if (path.includes('/audio')) {
      setValue('3');
    }
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const path = newValue === '1' ? 'vids' : newValue === '2' ? 'post' : 'audio';
    navigate(path);
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };


  const openModal = (tab) => {
    setActiveModalTab(tab); 
    setSelectedUserFollower(true); 
  };

  const closeModal = () => {
    setSelectedUserFollower(null); 
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className='text-white'><CircularProgress /></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className='text-white'>Error: {error}</div>
      </div>
    );
  }
  


  return (
    <div className="flex flex-col items-center mt-20 mb-28 post-container w-full">
         {user && (
          <>
      <div className="relative w-full max-w-5xl">
        <div className="flex absolute w-20 sm:w-24 sm:h-24 h-20 bg-black rounded-full overflow-hidden ml-4">
        {imageLoading && (
              <div className="flex items-center justify-center w-full h-full">
                <CircularProgress />
              </div>
            )}
        <img 
        src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} 
        alt="Profile" 
        className={`object-cover w-full h-full ${imageLoading ? 'hidden' : 'block'}`}
        onLoad={handleImageLoad}
        />
        </div>

        <div className="flex items-center text-white text-md sm:text-lg ml-28 sm:ml-40 font-regular pt-2">
        {user.firstName} {user.lastName}
        </div>
        {/* {user.canEdit ? (
  <button
    type="button"
    className="text-white bg-transparent border-2 border-black/40 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-0.5 ml-28 sm:ml-40 mt-3"
  >
    Edit
  </button>
) : (
  user.isUserFollowing ? (
    <button
      type="button"
      className="text-white border border-gray-400 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-1 ml-28 sm:ml-40 mt-3"
    >
      Following
    </button>
  ) : (
    <button
      type="button"
      className="text-white bg-blue-700 hover:bg-blue-800   font-medium rounded-lg text-sm px-4 sm:px-6 py-0.5  sm:py-1 ml-28 sm:ml-40 mt-3"
    >
      Follow
    </button>
  )
)} */}

        <div className="flex text-white ml-6 mt-16 sm:mt-20 "> @{user.userName}</div>
        {/* <div className="flex text-white ml-8 mt-1">User Bio</div> */}
      </div>

      <div className="flex items-center justify-center w-full border-t border-b border-white mt-6">

     

        <div className="  text-lg text-white my-2 mx-6 sm:mx-14 cursor-pointer" onClick={() => openModal('1')}>
       <div className="flex justify-center items-center text-sm" >
       {user.followers}
          </div>
       <span className="text-gray-500 text-md  " >followers</span>
       
        </div> 
        
        <div className="  text-lg text-white my-2 mx-6 sm:mx-14 cursor-pointer" onClick={() => openModal('2')}>
        <div className="flex justify-center items-center text-sm">
        {user.followings}
          </div>
       <span className="text-gray-500 text-md ">following</span>
       
        </div> 

        {/* <div className="text-lg text-white my-2 mx-6 sm:mx-14">
    <div className="flex justify-center items-center text-sm">
        <div>{user.posts.length}</div>
    </div>
    <span className="text-gray-500 text-md">post</span>
</div> */}

<div className="text-lg text-white my-2 mx-6 sm:mx-14">
    <div className="flex justify-center items-center text-sm">
        <div>{user.totalLikes}</div>
    </div>
    <span className="text-gray-500 text-md">likes</span>
</div>

        </div>

        <Box sx={{ width: '100%', typography: 'body1'  }}>
            <TabContext value={value}>
              <Box sx={{
        borderBottom: 0.5,
       
        borderColor: 'white',
        color: 'white',
        padding: 0,
        margin: 0,
      }}>
                <TabList 
                  onChange={handleTabChange} 
                  aria-label="lab API tabs example"
                  sx={{
                    display: 'flex',
                    width: '100%',
                    padding: 0, 
                    margin: 0,
                  }}
                  TabIndicatorProps={{
                    style: { height: '3px', backgroundColor: 'white' ,  }
                  }}
                  variant="fullWidth"
                >
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <VidsIcon active={value === '1'} />
                      </Box>
                    } 
                    label="Vids" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3', 
                      fontSize: value === "1" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'left',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="1" 
                  />
                  <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <PostIcon active={value === '2'} />
                      </Box>
                    } 
                    label="Post" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "2" ? '1.4rem' : '1.0rem',
                      flexGrow: 1, 
                      textAlign: 'center',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="2" 
                  />
                  {/* <Tab 
                    icon={
                      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                        <AudioIcon active={value === '3'} />
                      </Box>
                    } 
                    label="Audio" 
                    iconPosition="start" 
                    sx={{ 
                      color: '#d3d3d3',
                      fontSize: value === "3" ? '1.4rem' : '1.0rem', 
                      flexGrow: 1, 
                      textAlign: 'right',
                      '&.Mui-selected': { 
                        color: 'white',
                      },
                    }} 
                    value="3" 
                  /> */}
                </TabList>
              </Box>
            </TabContext>
          </Box>


         <Routes>
   
          <Route path="vids" element={<SmVidsGrid vids={user.vids} />} />
          <Route path="post" element={<SmPostsGrid posts={user.posts} />} />
          <Route path="audio" element={<SmAudio />} />
          <Route path="/" element={<Navigate to="vids" replace />} />
        </Routes>
        </>
         )}
         {selectedUserFollower && (
        <Userfollowersfollowing activeTab={activeModalTab} onClose={closeModal}  userName={`${user.firstName} ${user.lastName}`}  /> 
      )}
      </div>
   
  );
};

export default Smuser;
