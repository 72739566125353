import React, { useState, useEffect } from 'react';
import { PauseIcon } from './smicon';
import { API_URL,PROFILEPHOTO_URL } from '../../../../Config/config';
import VidsModal from './vidsmodal/VidsModal';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material'; 
import { toast, ToastContainer, Slide } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const VideosList = () => {
  const [selectedVids, setSelectedVids] = useState(null);
  const { userid } = useParams();
  const [vids, setVids] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [loadingMore, setLoadingMore] = useState(false); 
  const [page, setPage] = useState(0); 
  const [hasMore, setHasMore] = useState(true); 
  const [hasShownToast, setHasShownToast] = useState(false); 

  const fetchVids = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found in localStorage');
      }

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        // url: `${API_URL}/profile/v2/view?userId=${userid}&page_number=${page}`,
        url: `${API_URL}/profile/v2/${userid}/vids?page_number=${page}`,

        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      setLoadingMore(true); 
      const response = await axios.request(config);
      const newVids = response.data.data;

      setVids(prevVids => [...prevVids, ...newVids]);
      setHasMore(newVids.length > 0);

      // if (newVids.length === 0 || newVids.length < 10) { 
      //   setHasMore(false);
      // }

      if (newVids.length > 0) {
        setHasShownToast(false);
      }

    } catch (error) {
      console.error('Error fetching vids:', error);
    } finally {
      setLoading(false); 
      setLoadingMore(false); 
    }
  };

  useEffect(() => {
    fetchVids();
  }, [page]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 
  //         && hasMore && !loading && !loadingMore) {
  //       setPage(prevPage => prevPage + 1);
  //     }
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, loading, loadingMore]);
  

  // useEffect(() => {
  //   const handleScroll = () => {
   
  //     if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 && !loadingMore) {
  //       if (hasMore) {
  //         setPage(prevPage => prevPage + 1);
  //       } else if (!hasShownToast && vids.length > 0) {
         
  //         toast.info('No More Vids to load.', {
  //           position: 'top-right',
  //           autoClose: 2000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           closeButton: false,
  //           transition: Slide, 
  //           theme: "colored",
  //         });

  //         setHasShownToast(true); 
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [hasMore, loadingMore, vids.length, hasShownToast]);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 2;

    
      if (isAtBottom) {
      
        if (hasMore && !loadingMore) {
          setPage(prevPage => prevPage + 1);
        } 
       
        else if (!hasMore && vids.length > 0 && !hasShownToast) {
          toast.info('No More Vids to load.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            closeButton: false,
            transition: Slide, 
            theme: "colored",
          });
          setHasShownToast(true); 
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loadingMore, vids.length, hasShownToast]); 



  const openModal = (video) => {
    setSelectedVids(video);
    localStorage.setItem('vidsId',video.id);
  };

  const closeModal = () => {
    setSelectedVids(null);
  };

  return (
    <>
     <div className="relative mt-2">
        {loading && vids.length === 0 ? (
          <div className="mt-24 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
                 {vids && vids.length > 0 ? (

    <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-4 ">
      {vids.map((video) => (
       <div key={video.id} 
       className=' relative flex bg-black/20 w-[115px] h-40 sm:w-48 sm:h-60 rounded-2xl cursor-pointer '
     onClick={() => openModal(video)}>

         <video 
              poster={`${PROFILEPHOTO_URL}/${video.thumbnail}`} 
              className="object-cover w-full h-full rounded-lg" 
            />

     <div className="text-white absolute bottom-0 left-0 mb-4 ml-2 flex flex-col items-center">
        <div className="relative flex items-center justify-center">
       <PauseIcon />  <span className="text-white text-xs ml-2">{video.viewCount}</span>
     </div>
     </div>
     </div>
      ))}
      </div>
      ) : (
        !loading && (
          <div className="flex justify-center items-center h-96 w-full">
            <div className="text-gray-400 text-center">No Vids yet uploaded.</div>
          </div>
        )
      )}

    {loadingMore && (
      <div className="mt-8 mb-12 flex justify-center">
        <CircularProgress />
      </div>
    )}
    {/* {!loadingMore && !hasMore && vids.length > 0 && (
      <div className="mt-4 text-gray-400 text-center">No more Vids to load.</div>
    )} */}
  </>
)}
</div>
     {selectedVids && (
      <VidsModal video={selectedVids} onClose={closeModal} />
  )}
       <ToastContainer />

  </>
  );
};

export default VideosList;
