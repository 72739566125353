import axios from 'axios';
import { API_URL } from '../../Config/config';

export const FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE';
export const UPDATE_PAGE_NUMBER       = 'UPDATE_PAGE_NUMBER';
export const UPDATE_TOTAL_PROPERTIES  = 'UPDATE_TOTAL_PROPERTIES';
export const FETCH_COUNT_PROPERTIES   = 'FETCH_COUNT_PROPERTIES';
export const FETCH_STATE_SUCCESS      = 'FETCH_STATE_SUCCESS'
export const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS';
export const TOGGLE_LIKE_PROPERTY = 'TOGGLE_LIKE_PROPERTY';

export const fetchPropertiesRequest = () => ({
  type: FETCH_PROPERTIES_REQUEST,
});
export const fetchPropertiesSuccess = (properties) => ({
  type: FETCH_PROPERTIES_SUCCESS,
  payload: { properties },
});
export const fetchPropertiesFailure = (error) => ({
  type: FETCH_PROPERTIES_FAILURE,
  payload: error,
});
export const updatePageNumber = (pageNumber) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: pageNumber,
});
export const updateTotalProperties = (totalProperties) => ({
  type: UPDATE_TOTAL_PROPERTIES,
  payload: totalProperties,
});
export const fetchCountProperties = (countProperties) => ({
  type: FETCH_COUNT_PROPERTIES,
  payload: countProperties
});
export const fetchStateSuccess = (state, state_code) => ({
  type: FETCH_STATE_SUCCESS,
  payload: { state, state_code },
});
export const fetchFavoritesSuccess = (favorites) => ({
  type: FETCH_FAVORITES_SUCCESS,
  payload: favorites,
});
export const toggleLikeProperty = (propertyId) => ({
  type: TOGGLE_LIKE_PROPERTY,
  payload: propertyId,
});


export const fetchProperties = (searchQuery, areaType, searchType,   pageNumber = 0, boundary) => {
  return async (dispatch, getState) => {

    pageNumber = pageNumber || 0; 

    const { pageNumber: currentPageNumber } = getState().properties;

    const currentData = localStorage.getItem(`properties_page_${pageNumber}`);
    if (currentData) {
      const parsedData = JSON.parse(currentData);
      dispatch(fetchPropertiesSuccess(parsedData.data));
      dispatch(updateTotalProperties(parsedData.totalProperties));
      dispatch(fetchCountProperties(parsedData.countProperties));
      dispatch(fetchStateSuccess(parsedData.state, parsedData.state_code));
      return;
    }

    const propStatus = localStorage.getItem('prop_status') || 'for_sale';
    const sortValue = localStorage.getItem('sort_by') || 'list_data_min'
    const filterByPrice = localStorage.getItem('filter_by_price');
    const filterByPriceObj = filterByPrice ? JSON.parse(filterByPrice) : null;
    const propertytype = localStorage.getItem('filter_proptype');
    const filterByBed = localStorage.getItem('filter_by_bedroom');
    const filterByBedroom = filterByBed ? JSON.parse(filterByBed) : null;
    const filterByBath = localStorage.getItem('filter_by_bathroom');
    const filterByBathroom = filterByBath ? JSON.parse(filterByBath) : null;
    const filterByLiving = localStorage.getItem('filter_by_livingarea');
    const filterByLivingarea = filterByLiving ? JSON.parse(filterByLiving) : null;
    const filterByLot = localStorage.getItem('filter_by_lotsize');
    const filterByLotsize = filterByLot ? JSON.parse(filterByLot) : null;

    dispatch(fetchPropertiesRequest());
    try {
      const token = localStorage.getItem('token');
      searchQuery = searchQuery || localStorage.getItem('search')
      areaType = areaType || localStorage.getItem('areaType')
      searchType = searchType || localStorage.getItem('searchType')
      // let boundary =  localStorage.getItem('boundary');
      boundary = boundary || localStorage.getItem('boundary');

      
      if (boundary) {
        boundary = JSON.parse(boundary);
      }


      let requestData = {};

      if (searchType === 'boundary') {
        console.log('er',boundary)
        requestData = {
          page_number: pageNumber,
          category: 'realtor',
          search_type: 'boundary',
          boundary: boundary,
          filter: true,
          sort: false,
          sort_by: [sortValue],
          prop_status: [propStatus]
        };
      }
      else if (searchType === 'address') {
      if (areaType === 'state') {
        requestData = {
          page_number: pageNumber,
          category: 'realtor',
          search_type: 'address',
          address_type: 'state',
          search: searchQuery,
          filter: true,
          sort: false,
          sort_by: [sortValue],
          prop_status: [propStatus],
        };
      } else if (areaType === 'city') {
        requestData = {
          page_number: pageNumber,
          category: 'realtor',
          search_type: 'address',
          address_type: 'city',
          search: searchQuery,
          filter: true,
          sort: false,
          sort_by: [sortValue],
          prop_status: [propStatus],
        };
      } else if (areaType === 'street') {
        requestData = {
          page_number: pageNumber,
          category: 'realtor',
          search_type: 'address',
          address_type: 'street',
          search: searchQuery,
          prop_status: [propStatus],
          filter: false,
          sort: false,
          sort_by: [sortValue],
        };
      } else if (areaType === 'postal_code') {
        requestData = {
          page_number: pageNumber,
          category: 'realtor',
          search_type: 'address',
          address_type: 'postal_code',
          search: searchQuery,
          prop_status: [propStatus],
          filter: false,
          sort: false,
          sort_by: [sortValue],
        };
      }
    }
          // if (filterByPriceObj && filterByPriceObj.min !== "" && filterByPriceObj.max !== "") {
      //   requestData.price_min = filterByPriceObj.min;
      //   requestData.price_max = filterByPriceObj.max;
      // }


       if (filterByPriceObj) {
        requestData.price_min = `${filterByPriceObj.min}`;
        requestData.price_max = `${filterByPriceObj.max}`;
      }
     
      if (propertytype && propertytype.trim() !== '') {
        const types = propertytype.split(", ").map(type => type.trim());
        requestData.prop_type = types;
      }

      
    
    if (filterByBedroom) {
      requestData.beds_min = `${filterByBedroom.bedmin}`;
      requestData.beds_max = `${filterByBedroom.bedmax}`;
    }

    if (filterByBathroom) {
      requestData.baths_min = `${filterByBathroom.bathmin}`;
      requestData.baths_max = `${filterByBathroom.bathmax}`;
    }

    if (filterByLivingarea) {
      requestData.sqft_min = `${filterByLivingarea.areamin}`;
      requestData.sqft_max = `${filterByLivingarea.areamax}`
    }

    if (filterByLotsize) {
      requestData.lot_sqft_min = `${filterByLotsize.lotmin}`;
      requestData.lot_sqft_max = `${filterByLotsize.lotmax}`
    }

    const response = await axios.post(`${API_URL}/property/v4/list`, requestData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    console.log('API Response:', response.data);
    const totalProperties = response.data.total;
    const countProperties = response.data.count;
    const { state, state_code } = response.data.data.length > 0 ? response.data.data[0].address : {};

    dispatch(fetchPropertiesSuccess(response.data.data));
    dispatch(updateTotalProperties(totalProperties));
    dispatch(fetchCountProperties(countProperties));
    dispatch(fetchStateSuccess(state, state_code));

     
      const pageData = {
        data: response.data.data,
        totalProperties,
        countProperties,
        state,
        state_code,
      };
      localStorage.setItem(`properties_page_${pageNumber}`, JSON.stringify(pageData));

      return response;
    } catch (error) {
      dispatch(fetchPropertiesFailure(error));
    }
  };
};


// export const fetchFavorites = (pageNumber) => {
//   return async (dispatch) => {
//     try {

//       if (pageNumber === undefined || pageNumber === null) {
//         pageNumber = localStorage.getItem('currentPageNumber') || 0;
//       }

//       console.log("Fetching liked properties for page:", pageNumber);
      
//       const token = localStorage.getItem('token');
//       let config = {
//         method: 'get',
//         maxBodyLength: Infinity,
//         url: `${API_URL}/property/v4/favourites?page_number=${pageNumber}`,
//         headers: { 
//           'Authorization': `Bearer ${token}`
//         }
//       };

//       const response = await axios.request(config);
//       const favoriteProperties = response.data.data.map(property => property.property_id);
//       console.log('liked',favoriteProperties)
//       dispatch(fetchFavoritesSuccess(favoriteProperties));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };



export const likeProperty = (propertyId, category) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/property/v4/like/${propertyId}/${category}`,
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      };

      const response = await axios.request(config);
      dispatch(toggleLikeProperty(propertyId));
    } catch (error) {
    }
  };
};


