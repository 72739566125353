import {
    FETCH_USERPROFILE_REQUEST,
    FETCH_USERPROFILE_SUCCESS,
    FETCH_USERPROFILE_FAILURE,
  } from '../../Action/smartsocial/userprofile';
  
  const initialState = {
    loading: false,
    userProfile: null,
    error: null,
  };
  
  const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USERPROFILE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USERPROFILE_SUCCESS:
        return {
          ...state,
          loading: false,
          userProfile: action.payload,
          error: null,
        };
      case FETCH_USERPROFILE_FAILURE:
        return {
          ...state,
          loading: false,
          userProfile: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userProfileReducer;
  