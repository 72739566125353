import  {CHANGE_VIEW} from "../Action/viewaction";
  
  const initialState = {
    real_estate_view: 'list'
  };
  
  const viewReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_VIEW:
        return {
          ...state,
          real_estate_view: action.payload
        };
      default:
        return state;
    }
  };
  
  export default viewReducer;
  