import React from "react";

    export const Threedot = () => (
        <svg width="24" height="6" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 1.25C10 0.918479 10.1317 0.600537 10.3661 0.366117C10.6005 0.131696 10.9185 0 11.25 0C11.5815 0 11.8995 0.131696 12.1339 0.366117C12.3683 0.600537 12.5 0.918479 12.5 1.25C12.5 1.58152 12.3683 1.89946 12.1339 2.13388C11.8995 2.3683 11.5815 2.5 11.25 2.5C10.9185 2.5 10.6005 2.3683 10.3661 2.13388C10.1317 1.89946 10 1.58152 10 1.25ZM5 1.25C5 0.918479 5.1317 0.600537 5.36612 0.366117C5.60054 0.131696 5.91848 0 6.25 0C6.58152 0 6.89946 0.131696 7.13388 0.366117C7.3683 0.600537 7.5 0.918479 7.5 1.25C7.5 1.58152 7.3683 1.89946 7.13388 2.13388C6.89946 2.3683 6.58152 2.5 6.25 2.5C5.91848 2.5 5.60054 2.3683 5.36612 2.13388C5.1317 1.89946 5 1.58152 5 1.25ZM0 1.25C0 0.918479 0.131696 0.600537 0.366117 0.366117C0.600537 0.131696 0.918479 0 1.25 0C1.58152 0 1.89946 0.131696 2.13388 0.366117C2.3683 0.600537 2.5 0.918479 2.5 1.25C2.5 1.58152 2.3683 1.89946 2.13388 2.13388C1.89946 2.3683 1.58152 2.5 1.25 2.5C0.918479 2.5 0.600537 2.3683 0.366117 2.13388C0.131696 1.89946 0 1.58152 0 1.25Z" fill="gray"/>
           </svg>
    )
    export const LikeIcon = () => (
        <svg width="16" height="14" viewBox="0 0 671 825" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M644.9 433.801C661.7 411.601 671 384.401 671 356.101C671 311.201 645.9 268.701 605.5 245.001C595.099 238.9 583.258 235.69 571.2 235.701H331.4L337.4 112.801C338.8 83.1007 328.3 54.9007 307.9 33.4007C297.889 22.8035 285.81 14.372 272.412 8.62726C259.013 2.88249 244.578 -0.0535013 230 0.000738016C178 0.000738016 132 35.0007 118.2 85.1007L32.3 396.101H-97C-114.7 396.101 -129 410.401 -129 428.101V792.101C-129 809.801 -114.7 824.101 -97 824.101H504.3C513.5 824.101 522.5 822.301 530.8 818.701C578.4 798.401 609.1 751.901 609.1 700.301C609.1 687.701 607.3 675.301 603.7 663.301C620.5 641.101 629.8 613.901 629.8 585.601C629.8 573.001 628 560.601 624.4 548.601C641.2 526.401 650.5 499.201 650.5 470.901C650.3 458.301 648.5 445.801 644.9 433.801ZM-57 752.101V468.101H24V752.101H-57ZM579.4 399.101L557.5 418.101L571.4 443.501C575.979 451.868 578.354 461.263 578.3 470.801C578.3 487.301 571.1 503.001 558.7 513.801L536.8 532.801L550.7 558.201C555.279 566.568 557.654 575.963 557.6 585.501C557.6 602.001 550.4 617.701 538 628.501L516.1 647.501L530 672.901C534.579 681.268 536.954 690.663 536.9 700.201C536.9 722.601 523.7 742.801 503.3 752.001H88V464.901L187.5 104.401C190.066 95.1608 195.573 87.0089 203.188 81.1804C210.803 75.3518 220.111 72.1643 229.7 72.1007C237.3 72.1007 244.8 74.3007 250.8 78.8007C260.7 86.2007 266 97.4007 265.4 109.301L255.8 307.701H570.2C588 318.601 599 337.001 599 356.101C599 372.601 591.8 388.201 579.4 399.101Z" fill="white" fillOpacity="0.90"/>
</svg>

    
    );
    
    export const LikedIcon = () => (
        <svg width="18" height="18" viewBox="0 0 875 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M736.9 533.7C753.7 511.5 763 484.3 763 456C763 411.1 737.9 368.6 697.5 344.9C687.099 338.8 675.258 335.589 663.2 335.6H423.4L429.4 212.7C430.8 183 420.3 154.8 399.9 133.3C389.889 122.703 377.81 114.271 364.412 108.527C351.013 102.782 336.578 99.8458 322 99.9C270 99.9 224 134.9 210.2 185L124.3 496H124V924H596.3C605.5 924 614.5 922.2 622.8 918.6C670.4 898.3 701.1 851.8 701.1 800.2C701.1 787.6 699.3 775.2 695.7 763.2C712.5 741 721.8 713.8 721.8 685.5C721.8 672.9 720 660.5 716.4 648.5C733.2 626.3 742.5 599.1 742.5 570.8C742.3 558.2 740.5 545.7 736.9 533.7ZM-37 528V892C-37 909.7 -22.7 924 -5 924H60V496H-5C-22.7 496 -37 510.3 -37 528Z" fill="white"/>
        </svg>
        
    )

    export const DislikeIcon = () => (
        <svg width="14" height="14" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.307 0.935994C15.703 1.30299 15.962 2.06899 16.013 2.53099C16.603 2.89699 17.301 3.63499 17.362 5.02499C18.415 5.75599 19.215 7.10799 18.216 9.08499C18.796 9.62499 19.443 11.273 18.611 12.601C17.642 14.153 15.536 14.261 13.437 13.984C13.997 15.549 14.207 16.993 13.321 18.472C12.44 19.784 11.224 19.997 10.808 19.997C9.67 19.997 8.89 19.018 8.574 17.714C8.459 17.35 8.328 16.49 8.277 16.264C8.01167 14.824 7.12167 13.673 5.607 12.811C4.93341 12.4242 4.22205 12.1072 3.484 11.865H1.144C0.623 11.865 0 11.338 0 10.7V3.06399C0.074 2.34199 0.474667 1.98133 1.202 1.98199H4.312C5.67533 1.67199 7.03467 1.34033 8.39 0.986994C9.7 0.633994 9.987 0.516994 10.903 0.264994C12.956 -0.267006 14.381 0.0259936 15.307 0.935994ZM12.925 1.35899C12.106 1.35899 11.069 1.61099 10.609 1.75799C10.447 1.80899 10.163 1.89299 9.864 1.97899L9.564 2.06599L9.276 2.14799L8.716 2.30599C8.716 2.30599 7.306 2.68399 4.543 3.32599C4.44033 3.33799 4.385 3.34533 4.377 3.34799V10.728C5.88833 11.31 7.065 12.016 7.907 12.846C9.171 14.09 9.522 15.214 9.729 16.653C9.847 17.376 10.038 17.959 10.326 18.358C10.4087 18.4776 10.5291 18.5659 10.668 18.609C10.815 18.656 11.018 18.659 11.451 18.425C11.884 18.189 12.441 17.572 12.546 16.653C12.616 15.76 12.376 14.986 12.054 14.172C11.9427 13.8947 11.8237 13.6206 11.697 13.35C11.479 12.937 11.807 12.251 12.483 12.4C13.389 12.655 15.637 13 16.905 12.4C17.6423 11.9733 17.8247 11.2847 17.452 10.334C17.3297 10.1155 17.1607 9.92662 16.957 9.78099C16.787 9.67899 16.455 9.23699 16.854 8.73599C17.25 8.10099 17.829 6.80799 16.364 6.00199C16.262 5.94548 16.1769 5.86296 16.1171 5.76284C16.0574 5.66272 16.0253 5.54857 16.024 5.43199C16.004 5.15799 16.048 4.14199 15.294 3.68799C15.114 3.59099 14.897 3.51099 14.774 3.27799C14.696 3.12399 14.671 2.74999 14.671 2.74999C14.568 2.11799 14.426 1.52799 12.925 1.35899ZM3.019 3.34499H1.361V10.502H3.019V3.34499Z" fill="white" fillOpacity="0.89"/>
       </svg>
        )

        export const DislikedIcon = () => (
            <svg width="16" height="16" viewBox="0 0 107 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M103.509 41.6825C103.991 40.401 104.231 39.0767 104.231 37.7311C104.231 34.7087 102.988 31.8039 100.741 29.433C101.222 28.1515 101.463 26.8272 101.463 25.4816C101.463 22.4592 100.219 19.5544 97.9719 17.1835C98.4534 15.9019 98.6941 14.5777 98.6941 13.232C98.6941 7.72136 94.588 2.75534 88.2215 0.587379C87.1024 0.20215 85.8957 0.00580801 84.6771 0.0106794H21.5338V45.7194H21.5739L33.0496 78.9117C34.8954 84.2621 41.0479 88 48.0029 88C51.9753 88 55.6801 86.7398 58.422 84.433C61.1639 82.1369 62.5683 79.1252 62.3676 75.9534L61.5651 62.8282H93.6518C95.2701 62.8282 96.8484 62.4864 98.2394 61.835C103.643 59.3252 107 54.7757 107 49.9806C107 46.9583 105.756 44.0534 103.509 41.6825ZM0 3.41748V42.2913C0 44.1816 1.91262 45.7087 4.28 45.7087H12.9738V0H4.28C1.91262 0 0 1.52718 0 3.41748Z" fill="white"/>
</svg>
            )

 