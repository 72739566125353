import React, { useEffect, useState } from 'react';
import MapView from './MapView';
import CustomMap from './Map';
import MapviewSkeleton from './Mapviewskeleton';
import Sort from '../../Subheader/Sort';
import Footer from '../../../Layout/Footer/Footer';
import Pagination from '../Pagination';


const Mapviews = ({ properties, loading , address }) => {
  const [hoveredPropertyId, setHoveredPropertyId] = useState(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const handleMouseEnter = (propertyId) => {
    setHoveredPropertyId(propertyId);
  };

  const handleMouseLeave = () => {
    setHoveredPropertyId(null);
  };

  
  return (
    <div className="flex-grow flex h-screen fixed w-full  mt-40">
      <div className="lg:w-3/5 md:w-2/4 sm:w-full w-full overflow-y-scroll relative mb-40 "> 
        <div className="flex flex-col">
          <Sort />
          <div className='min-h-screen'>
          {loading ? (
            <MapviewSkeleton />
          ) : (
            <MapView
              properties={properties}
              hoveredPropertyId={hoveredPropertyId}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
        </div>
          <Pagination />
          <Footer />
        </div>
      </div>
      <div className="hidden fixed top-28 right-0 sm:hidden lg:w-2/5 md:w-2/4 md:block">
        <CustomMap propertie={properties} address={address} hoveredPropertyId={hoveredPropertyId} />
      </div>
    </div>
  );
}

export default Mapviews;


