import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { API_URL } from '../../../../../Config/config';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';


const Submitecomment = ({ addNewComment, commentId, onSubmit, isReplying, onCancelReply, username, editingCommentId, editingContent, originalCommentContent, onEditSubmit, isEditing, onCancelEdit,editingReplyId,
  editingReplyContent, onCancelEditReply, onReplyEditSubmit }) => {

  const [comment, setComment] = useState('');
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setComment(editingContent || editingReplyContent || '');
  }, [editingContent, editingReplyContent]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (emojiObject) => {
    const newComment = (comment || '') + emojiObject.emoji;
    setComment(newComment);
    setChosenEmoji(emojiObject);
  };

  const postComment = async () => {
    const token = localStorage.getItem('token');
    const postId = localStorage.getItem('postId');
    if (!token || !postId) {
      return;
    }

    const data = JSON.stringify({ comment });

    const config = {
       method: editingCommentId || editingReplyId ? 'put' : 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/posts/v3/comment/${editingCommentId ? editingCommentId : editingReplyId ? `reply/${editingReplyId}` : isReplying ? `reply/${commentId}` : postId}`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data
    };
    setLoading(true);

    try {
      const response = await axios.request(config);
      if (editingCommentId) {
        onEditSubmit(response.data.data);
      } else if (editingReplyId) {
        onReplyEditSubmit(response.data.data);
      } else if (isReplying) {
        onSubmit(commentId, response.data.data);
      } else {
        addNewComment(response.data.data);
      }
      setComment('');
      setChosenEmoji(null);
      setLoading(false);
      setShowEmojiPicker(false);

    } catch (error) {
      setLoading(false);
    }
  };
  
  const handleCancelReply = () => {
    setComment(''); 
    onCancelReply();
  };
  
  const handleCancelEdit = () => {
    setComment('');
    onCancelEdit();
  };

  const handleCancelEditReply = () => {
    setComment('');
    onCancelEditReply();
  };
  const isUpdateDisabled = comment === originalCommentContent || comment === editingReplyContent;
  
  return (
    <div className='  mt-1 flex items-center'>
      {chosenEmoji ? (
        <span className="ml-2 cursor-pointer" onClick={toggleEmojiPicker}>{chosenEmoji.emoji}</span>
      ) : (
        <span className="ml-2 cursor-pointer" onClick={toggleEmojiPicker}>😊</span>
      )}
      {showEmojiPicker && (
        <div className="absolute bottom-[118px] ml-0 mb-10 z-10">
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      <input
        className="bg-black/80 text-white w-full p-4 focus:outline-none"
        style={{ caretColor: 'gray' }}
        placeholder={isReplying ? `Reply to ${username}` : isEditing ? 'Edit your comment' : editingReplyId ? 'Edit your reply' : 'Add a comment...'}
        value={comment}
        onChange={handleCommentChange}
        onFocus={() => setShowEmojiPicker(false)}
      />

<div className="ml-2">
  {isReplying ? (
    <button
    className={` text-white py-1 px-3 rounded-xl ${!comment.trim() && 'opacity-50 cursor-not-allowed'}`}
    onClick={postComment}
      disabled={!comment.trim()}
    >
      {loading ? (
        <CircularProgress size={20} style={{color:"white"}} className='text-white' />
      ) : 'Reply'}
    </button>
  ) : isEditing || editingReplyId ? (
    <button
      className={` text-white py-1 px-3 rounded-full ${isUpdateDisabled && 'opacity-50 cursor-not-allowed'}`}
      onClick={postComment}
      disabled={isUpdateDisabled || !comment.trim()}
    >
      {loading ? (
        <CircularProgress size={20} style={{color:"white"}} className='text-white' />
      ) : 'Update'}
    </button>
  ) : (
    <button
    className={` text-white py-1 px-3 mr-2 rounded-xl ${!comment.trim() && 'opacity-50 cursor-not-allowed'}`}
    onClick={postComment}
      disabled={!comment.trim()}
    >
      {loading ? (
        <CircularProgress size={20} style={{color:"white"}} className='text-white' />
      ) : 'Post'}
    </button>
  )}
</div>


{(isReplying || isEditing || editingReplyId) && (
        <button
          className="ml-2 text-gray-400 pr-2 py-2 rounded"
          onClick={isReplying ? handleCancelReply : isEditing ? handleCancelEdit : handleCancelEditReply}
        >
          <CloseIcon />
        </button>
      )}

    </div>
  );
};

export default Submitecomment;