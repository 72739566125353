import React, { useState, useRef, useEffect } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { PROFILEPHOTO_URL } from '../../../../../Config/config';
import CircularProgress from '@mui/material/CircularProgress'; 


const Vids = ({ video }) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [overlayTime, setOverlayTime] = useState(null);
    const [overlayPosition, setOverlayPosition] = useState({ left: 0 });
    const [showSlider, setShowSlider] = useState(false); 
    const videoRef = useRef(null);
    const previewVideoRef = useRef(null);
    const sliderRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true); 

    const toggleMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = !isMuted;
            setIsMuted(!isMuted);
        }
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleVideoClick = () => {
        togglePlayPause();
        setShowSlider(true); 
    };

    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            setProgress((currentTime / duration) * 100);
        }
    };

    const handleSliderChange = (event) => {
        event.stopPropagation();
        if (videoRef.current) {
            const newTime = (event.target.value / 100) * videoRef.current.duration;
            videoRef.current.currentTime = newTime;
            setProgress(event.target.value);
        }
    };

    const handleMouseMove = (event) => {
        event.stopPropagation();
        const sliderRect = sliderRef.current.getBoundingClientRect();
        const clientX = event.clientX || event.touches[0].clientX; 
        const offsetX = clientX - sliderRect.left;
        const newProgress = (offsetX / sliderRect.width) * 100;
        const newTime = (newProgress / 100) * videoRef.current.duration;
        setOverlayTime(newTime);
        setOverlayPosition({ left: offsetX });

        if (previewVideoRef.current) {
            previewVideoRef.current.currentTime = newTime;
        }
    };

    const handleMouseLeave = () => {
        setOverlayTime(null);
       
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
            videoRef.current.addEventListener('loadeddata', () => {
                setIsLoading(false); 
            });
            return () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                    videoRef.current.removeEventListener('loadeddata', () => {
                        setIsLoading(false);
                    });
                }
            };
        }
    }, []);

    const handleLoadedData = () => {
        setIsLoading(false);
    };

    return (
        <div>
            <div className="bg-black/70 w-full h-[600px] mt-4 m-auto relative group"
                onTouchStart={() => setIsHovered(true)} 
                onTouchEnd={() => setIsHovered(false)} 
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleVideoClick}
            >
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center mt-4 ">
                        <CircularProgress  />
                    </div>
                )}
                {video && video.thumbnail && (
                    <video
                        ref={videoRef}
                        src={`${PROFILEPHOTO_URL}/${video.url}`}
                        className={`object-cover w-full h-full ${isLoading ? 'hidden' : ''}`}
                        autoPlay
                        muted={isMuted}
                        onEnded={handleVideoEnd}
                        onLoadedData={handleLoadedData}

                    />
                )}
                <button
                    className="absolute top-4 right-4 bg-gray-800/70 hover:bg-gray-700/70 text-white rounded-full w-10 h-10 flex items-center justify-center shadow-lg"
                    onClick={(event) => { event.stopPropagation(); toggleMute(); }}
                >
                    {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
                {!isPlaying && (
                    <button
                        className="absolute inset-0 m-auto w-16 h-16 bg-gray-800/60 hover:bg-gray-700/50 text-white rounded-full flex items-center justify-center shadow-lg"
                        onClick={(event) => { event.stopPropagation(); togglePlayPause(); }}
                    >
                        <PlayArrowIcon style={{ fontSize: 40 }} />
                    </button>
                )}
                {showSlider && ( 
                    <input
                        ref={sliderRef}
                        type="range"
                        className="absolute bottom-4 left-4 right-4"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleSliderChange}
                        onMouseMove={handleMouseMove}
                        onTouchMove={handleMouseMove}
                        onTouchEnd={handleMouseLeave}
                        onClick={(event) => event.stopPropagation()}
                    />
                )}
                {overlayTime !== null && (
                    <div
                        className="absolute text-white p-2 rounded shadow-lg"
                        style={{
                            bottom: '60px',
                            left: `${overlayPosition.left}px`,
                            transform: 'translateX(-50%)',
                        }}
                    >
                        <video
                            ref={previewVideoRef}
                            src={`${PROFILEPHOTO_URL}/${video.url}`}
                            className="object-cover mb-2 rounded-lg"
                            style={{
                                width: '95px',
                                height: '128px',
                                objectFit: 'cover',
                            }}
                            muted
                        />
                        <div className="text-xs mt-2 text-center">
                            {new Date(overlayTime * 1000).toISOString().substr(11, 8)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Vids;
