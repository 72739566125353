// import React from "react";
// import LgVids  from "./largescreenvids/index";
// import SmVids from "./smallscreenvids/index";
// import { useMediaQuery } from 'react-responsive';

// const Vidslayout = () => {

//   const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
//   const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
//   const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
//   const isMD = useMediaQuery({ query: '(min-width: 768px)' });
//   const isSM = useMediaQuery({ query: '(min-width: 640px)' });

//   return (
   
//       <div className="flex-1 mt-20 mb-28 sm:mt-20 md:mt-6 lg:mt-6 xl:mt-6 2xl:mt-6 items-center justify-center flex  " >
//     {is2XL ?     <LgVids /> :
//         isXL ?    <LgVids/> :
//           isLG ?   <LgVids/> :
//             isMD ?   <LgVids /> :
//               isSM ? <SmVids /> :
//               <SmVids />}
//     </div>
//   );
// };

// export default Vidslayout;

import React from "react";
import LgVids from "./largescreenvids/index";
// import LgVids from "./largescreenvids/Second_layout/index";
import SmVids from "./smallscreenvids/index";
import { useMediaQuery } from 'react-responsive';

const Vidslayout = () => {
  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  // const isSM = useMediaQuery({ query: '(min-width: 640px)' });

  return (
    <div className="flex flex-1 items-center justify-center  ">
      {is2XL || isXL || isLG || isMD ? <LgVids /> : <SmVids />}
    </div>
  );
};

export default Vidslayout;



