import React, { useState, useEffect } from 'react';
import { AudioIcon, CreateIcon, HomeIcon, PostIcon, SearchIcon, VidsIcon } from "./icon";
import { useNavigate } from "react-router-dom";
import Smart from '../../../assets/images/SMART-GEAR.png'
import smartIcon from '../../../assets/images/SMART-registered-blue.png'
import CircularProgress from '@mui/material/CircularProgress';
import { PROFILEPHOTO_URL } from '../../../Config/config';

const MdxlSidebar = ({user, loading, error}) => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
      };

      const handleprofile =  (userId) => {
        navigate(`/smartsocial/${userId}/`);
    };

  return (
    <div 
      className="flex flex-col justify-between bg-white/95 w-16 sm:w-20 md:w-20 lg:w-48 xl:w-80 mt-8 rounded-tr-xl border-2 border-black" 
      style={{ height: 'calc(100vh - 1.5rem)' }}
    >
      <ul className="text-black text-xl  cursor-pointer ">
      <li className="flex items-center  px-2 md:px-0 py-1.5 justify-center xl:justify-start lg:justify-start   ">
      <a href="/" className="flex items-center justify-between rtl:space-x-reverse">

          <img src={Smart} className='w-16 h-16 md:w-14 md:h-14 lg:h-16 lg:w-16 xl:h-16 xl:w-16 2xl:h-16 2xl:w-16 ' /> 
          <img  src={smartIcon} className='w-44 h-12 hidden md:hidden lg:block md:w-16 lg:w-28 lg:h-6 xl:w-44 xl:h-8 2xl:w-44 2xl:h-10' />
          </a>
        </li>
        <a href='/' className="flex items-center hover:bg-gray-400  border-t border-b border-black/40 px-4 py-4 justify-center xl:justify-start lg:justify-start   ">
          <HomeIcon />
          <span href='/' className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Home</span> 
        </a>
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-black ">
          <SearchIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Search</div>
        </li> */}
        <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-b border-black/40" onClick={() => handleNavigation("/smartsocial/vids")}>
          <VidsIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Vids</div>
        </li>
        <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-b border-black/40" onClick={() => handleNavigation("/smartsocial/post")}>
          <PostIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Post</div>
        </li>
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-black">
          <AudioIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Audio</div>
        </li> */}
        {/* <li className="flex items-center hover:bg-gray-400 px-4 py-4 justify-center xl:justify-start lg:justify-start border-t border-b border-black">
          <CreateIcon />
          <div className="px-4 hidden md:hidden lg:block text-company-color font-semibold">Create</div>
        </li> */}
      </ul>
      <div className="flex pb-4 items-center border-t px-3 border-black/40 hover:bg-gray-400 cursor-pointer" onClick={() => handleprofile(user && user.id)}>
    <div className="flex w-10 h-10 bg-white rounded-full overflow-hidden mt-2 items-center justify-center">
    {loading ? (
    <div role="status">
      <CircularProgress />
      </div>
  ) : error ? (
    <div className="error">Error loading image</div>
  ) : (
    user && <img src={`${PROFILEPHOTO_URL}/${user.profilePhoto}`} alt="Profile" className="object-cover w-full h-full" />
  )}    </div>
    <div className="text-company-color text-2xl px-4  font-semibold mt-2 items-center justify-center hidden md:hidden lg:block" >
        Profile
    </div>
</div>


      </div>
    
  );
};

export default MdxlSidebar;
