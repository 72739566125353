import React, { useState, useRef, useEffect } from 'react';
import '../Largescreenpost/style.css';
import CircularProgress from '@mui/material/CircularProgress';
import { PROFILEPHOTO_URL } from '../../../../Config/config';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { RxDotFilled } from 'react-icons/rx';
import { useSwipeable } from 'react-swipeable';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const Postimage = ({ post, isActive }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [imageCache, setImageCache] = useState({});
  const [isMuted, setIsMuted] = useState(false);
  // const [isMuted, setIsMuted] = useState(true);
  const [userInteracted, setUserInteracted] = useState(false); 
  const audioRef = useRef(null);
  const slides = post && post.contents ? post.contents : [];
  const isSinglePost = slides.length === 1;

  useEffect(() => {
    const preloadImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = `${PROFILEPHOTO_URL}/${url}`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };

    const loadImage = async () => {
      setLoading(true);
      const url = slides[currentIndex]?.url;
      if (imageCache[url]) {
        setLoading(false);
      } else {
        const isLoaded = await preloadImage(url);
        setImageCache((prevCache) => ({ ...prevCache, [url]: isLoaded }));
        setLoading(false);
      }
    };

    if (slides.length > 0) {
      loadImage();
    }
  }, [currentIndex, slides, imageCache]);

  // useEffect(() => {
  //   if (audioRef.current && userInteracted) { 
  //     if (isActive) {
  //       audioRef.current.play().catch(error => {
  //         console.error('Audio play failed:', error);
  //       });
  //     } else {
  //       audioRef.current.pause();
  //       audioRef.current.currentTime = 0;
  //     }
  //   }
  // }, [isActive, userInteracted]);

  useEffect(() => {
    if (audioRef.current && isActive) {
      audioRef.current.muted = isMuted; 
      audioRef.current.play().catch(error => {
        console.error('Audio play failed:', error);
      });
    } else if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isActive, isMuted]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  return (
    <div className="bg-gray-200 w-full h-full" {...handlers} onClick={handleUserInteraction}>
      {isSinglePost ? (
        <div className="bg-black/90 mt-1 mb-0.1 w-full  sm:h-[500px] h-[500px] flex justify-center relative">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <CircularProgress />
            </div>
          )}
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${PROFILEPHOTO_URL}/${slides[0].url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <img
              src={`${PROFILEPHOTO_URL}/${slides[0].url}`}
              alt="Slide"
              className="hidden"
            />
          </div>
          {post.hasBlurt && post.blurt && (
            <div className="absolute bottom-2 right-3 flex flex-col items-center space-y-2">
              <audio ref={audioRef} src={`${PROFILEPHOTO_URL}/${post.blurt.url}`} loop muted />
              <button onClick={toggleMute} className="bg-black/50 text-white rounded-full p-2">
                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-black/90 mt-2 mb-0.1 w-full 2xl:h-96 xl:h-96 lg:h-96 md:h-96 sm:h-96 h-72 flex justify-center m-auto relative group">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center z-10">
              <CircularProgress />
            </div>
          )}

          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${PROFILEPHOTO_URL}/${slides[currentIndex].url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <img
              src={`${PROFILEPHOTO_URL}/${slides[currentIndex].url}`}
              alt="Slide"
              className="hidden"
            />
          </div>

          <div
            className="hidden group-hover:block absolute top-[50%] -translate-x-1 translate-y-[-50%] left-5 rounded-full p-2 bg-black/50 text-white cursor-pointer"
            onClick={prevSlide}
          >
            <ChevronLeftIcon />
          </div>
          <div
            className="hidden group-hover:block absolute top-[50%] -translate-x-1 translate-y-[-50%] right-5 rounded-full p-2 bg-black/50 text-white cursor-pointer"
            onClick={nextSlide}
          >
            <ChevronRightIcon />
          </div>
          <div className="absolute bottom-0 w-full flex justify-center">
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className={`text-2xl cursor-pointer ${slideIndex === currentIndex ? 'text-blue-500' : 'text-white'}`}
              >
                <RxDotFilled />
              </div>
            ))}
          </div>
          {post.hasBlurt && post.blurt && (
            <div className="absolute bottom-2 right-3 flex flex-col items-center space-y-2">
              <audio ref={audioRef} src={`${PROFILEPHOTO_URL}/${post.blurt.url}`} loop muted />
              <button onClick={toggleMute} className="bg-black/50 text-white rounded-full p-2">
                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Postimage;
