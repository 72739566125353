import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Listview from './Listview';
import Mapviews from './Mapviews';
import { fetchProperties } from '../../Action/propertyAction';
import ListviewSkeleton from './Listviewskeleton';
import Mapviewskeleton from './Mapviews/Mapviewskeleton'

const Middlecontent = ({ real_estate_view ,properties, loading, fetchProperties, }) => {


  // useEffect(() => {
   
  //   if (!properties.length) {
  //     const searchQuery = localStorage.getItem('search');
  //     const areaType = localStorage.getItem('areaType');
  //     if (searchQuery && areaType) {
  //       fetchProperties(searchQuery, areaType, ''); 
  //     }
  //   }
  // }, [fetchProperties, properties]); 

  

  return (
    <div className="bg-white flex flex-col h-full overflow-hidden">
      <div className="flex-grow flex">
        {real_estate_view === 'list' && (
          <div className="w-full lg:w-full">
            {loading ? <ListviewSkeleton /> : <Listview properties={properties}  />}
            </div>
        )}
        {real_estate_view === 'map' && (
         <div className="w-full "> 
            <Mapviews  properties={properties}  loading={loading} />
         </div>

        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  real_estate_view: state.view.real_estate_view,
  properties: state.properties.properties,
  loading: state.properties.loading,
});
const mapDispatchToProps = {
  fetchProperties, 
};
export default connect(mapStateToProps, mapDispatchToProps)(Middlecontent);
