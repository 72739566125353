import {
    SET_COMMENT,
    RESET_COMMENT,
    SET_EMOJI,
    TOGGLE_EMOJI_PICKER,
    POST_COMMENT_REQUEST,
    POST_COMMENT_SUCCESS,
    POST_COMMENT_FAILURE
  } from '../../Action/smartsocial/submitcomment';
  
  const initialState = {
    comment: '',
    chosenEmoji: null,
    showEmojiPicker: false,
    loading: false,
    error: null,
  };
  
  const commentReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_COMMENT:
        return {
          ...state,
          comment: action.payload,
        };
      case RESET_COMMENT:
        return {
          ...state,
          comment: '',
          chosenEmoji: null,
        };
      case SET_EMOJI:
        return {
          ...state,
          chosenEmoji: action.payload,
          comment: state.comment + action.payload.emoji,
        };
      case TOGGLE_EMOJI_PICKER:
        return {
          ...state,
          showEmojiPicker: !state.showEmojiPicker,
        };
      case POST_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case POST_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case POST_COMMENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default commentReducer;
  